import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface RecentlyUsedDevicesState {
  lastSelectedSecondScreenId?: string;
  lastSelectedTerminalId?: string;
}

const initialState: RecentlyUsedDevicesState = {
  lastSelectedSecondScreenId: undefined,
  lastSelectedTerminalId: undefined
};
export const recentlyUsedDevicesSlice = createSlice({
  name: "recentlyUsedDevices",
  initialState,
  reducers: {
    setLastSelectedSecondScreenId: (state, action: PayloadAction<string>) => {
      state.lastSelectedSecondScreenId = action.payload;
    },
    setLastSelectedTerminalId: (state, action: PayloadAction<string>) => {
      state.lastSelectedTerminalId = action.payload;
    }
  }
});

export const { setLastSelectedSecondScreenId, setLastSelectedTerminalId } =
  recentlyUsedDevicesSlice.actions;

const selectSelf: Selector<RootState, RecentlyUsedDevicesState> = (state: RootState) =>
  state[recentlyUsedDevicesSlice.name];

export const selectLastSelectedSecondScreenId = createSelector(
  selectSelf,
  (res) => res.lastSelectedSecondScreenId
);

export const selectLastSelectedTerminalId = createSelector(
  selectSelf,
  (res) => res.lastSelectedTerminalId
);
