import { ManualCheckinStepsEnum } from "../features/reservations/domain/enums";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Params } from "./use-select-id";

export const useManualCheckinSteps = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentStep = useMemo(() => {
    return searchParams.get(Params.MANUAL_CHECKIN_STEP);
  }, [searchParams]);

  const goToStep = (step: ManualCheckinStepsEnum) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(Params.MANUAL_CHECKIN_STEP, step);

    setSearchParams(newSearchParams);
  };
  const nextStep = () => {
    const currentStepIndex = Object.values(ManualCheckinStepsEnum).findIndex(
      (step) => step === currentStep
    );
    const nextStep = Object.values(ManualCheckinStepsEnum)[currentStepIndex + 1];

    goToStep(nextStep);
  };

  const steps = Object.values(ManualCheckinStepsEnum);

  const previousStep = () => {
    const currentStepIndex = Object.values(ManualCheckinStepsEnum).findIndex(
      (step) => step === currentStep
    );
    const previousStep = Object.values(ManualCheckinStepsEnum)[currentStepIndex - 1];

    goToStep(previousStep);
  };

  return {
    steps,
    currentStep,
    nextStep,
    previousStep,
    goToStep
  };
};
