import {
  ApiError,
  BaseApiClient,
  JSONApiResponse,
  VoidApiResponse
} from "@likemagic-tech/sv-magic-library";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { convertBlobToBase64 } from "../api/guest-journey.api";

const CONTENT_TYPE_JSON = "application/json";
export const CONTENT_TYPE_MULTIPART = "multipart/form-data;";

const prepareBody = (
  headers: { [key: string]: string },
  body: { request: any; files: File[]; file: File }
) => {
  if (headers["Content-Type"] === CONTENT_TYPE_MULTIPART) {
    const formData = new FormData();

    if (body?.request) {
      formData.append(
        "request",
        new Blob([JSON.stringify(body.request)], {
          type: CONTENT_TYPE_JSON
        })
      );
    }
    if (body?.files?.length) {
      body.files.forEach((file) => {
        formData.append("files", new Blob([file]), file.name);
      });
    }

    if (body?.file) {
      formData.append("file", new Blob([body?.file]), body?.file?.name);
    }

    return formData;
  }
  return JSON.stringify(body);
};

class MagicApiClient extends BaseApiClient {
  async fetchMagicApi(url: string, init?: RequestInit, isVoid?: boolean, isBlob?: boolean) {
    const headers = {
      ...init?.headers,
      // @ts-ignore
      "Content-Type": init?.headers["Content-Type"]
    };

    if (headers["Content-Type"] === CONTENT_TYPE_MULTIPART) {
      delete headers["Content-Type"];
    } else {
      headers["Content-Type"] = CONTENT_TYPE_JSON;
    }
    const response = await this.fetch(url, {
      ...init,
      headers
    });

    if (isBlob) {
      const blob = await response.blob();
      return convertBlobToBase64(blob);
    }
    return isVoid ? new VoidApiResponse(response).value() : new JSONApiResponse(response).value();
  }
}

const MagicApi = new MagicApiClient();

export const magicBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<{
    url: string;
    isVoid?: boolean;
    isBlob?: boolean;
    body?: any;
    headers?: { [key: string]: string };
  }> =>
  async ({ url, body, isVoid, isBlob, headers }, api) => {
    try {
      const keycloak: any = (api.extra as any).keycloak;

      const result = await MagicApi.fetchMagicApi(
        baseUrl + url,
        {
          method: headers?.method || "GET",
          body: prepareBody(headers || {}, body),
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            ...headers
          }
        },
        isVoid,
        isBlob
      );
      return { data: result || {} }; //handle void with empty response
    } catch (e) {
      console.error(e);
      if (e instanceof ApiError) {
        // @ts-ignore Handle old way of sending list of messages TODO delete once all api errors are migrated to the new way
        const transformOldMessages = e?.raw?.messages?.[0] ?? "";
        return {
          error: new ApiError({ ...e.raw, message: transformOldMessages }),
          data: undefined
        };
      }
      return {
        error: e,
        data: undefined
      };
    }
  };
