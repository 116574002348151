import { Box, Grid, Paper } from "@mui/material";
import { ParagraphSmall, ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";
import { Service } from "../../../../../../domain/service";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { formatPriceToString } from "src/utils/price";
import { FC, PropsWithChildren } from "react";
import { DisplayServiceIcon } from "./display-service-icon";
import RemoveCircle from "../../../../../../icons/RemoveCircle";

/**
 * Returns a card for each service booked on the reservation.
 */

interface ServicesInterface {
  services: Service[];
  serviceOnRemove?: (service: Service) => void;
}

export const Services: FC<PropsWithChildren<ServicesInterface>> = ({
  services,
  serviceOnRemove
}) => {
  const { t } = useTranslationWrapper();

  return (
    <>
      <Grid container alignItems="center" pb={0.5} pr={1}>
        <Grid item xs={6} md={7} pr={1} pl={1}>
          <ParagraphSmall color="text.primary">
            {t("labels__reservation__details__overview__services__service")}
          </ParagraphSmall>
        </Grid>
        <Grid item xs={2} textAlign="center">
          <ParagraphSmall color="text.primary">
            {t("labels__reservation__details__overview__services__quantity")}
          </ParagraphSmall>
        </Grid>
        <Grid item xs={4} md={3} textAlign="right">
          <ParagraphSmall color="text.primary">
            {t("labels__reservation__details__overview__services__price")}
          </ParagraphSmall>
        </Grid>
      </Grid>
      <Grid container>
        {services.map((service: Service) => (
          <Grid item xs={12} key={service.name} paddingBottom={1.5}>
            <Paper
              elevation={0}
              sx={{
                background: grey[100],
                borderColor: grey[100],
                borderRadius: "5px"
              }}
              variant="outlined"
            >
              <Grid container direction="row" p={1} alignItems="center" position="relative">
                <Grid item xs={6} md={7} textAlign="center" flexDirection="column">
                  <Box display="flex" justifyContent="left" alignItems="center">
                    <DisplayServiceIcon code={service?.code} fontSize={"medium"} />
                    <ParagraphSmall color="text.primary" textAlign="left" pl={1}>
                      {service?.name}
                    </ParagraphSmall>
                  </Box>
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <ParagraphSmall color="text.primary">{service?.quantity}</ParagraphSmall>
                </Grid>
                <Grid item xs={4} md={3}>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <ParagraphSmallBold color="text.primary" textAlign="right">
                      {formatPriceToString(service?.price)}
                    </ParagraphSmallBold>
                    {serviceOnRemove && (
                      <RemoveCircle
                        onClick={() => serviceOnRemove(service)}
                        sx={{
                          position: "absolute",
                          cursor: "pointer",
                          right: -8,
                          top: -8,
                          width: 16,
                          height: 16
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
