import * as React from "react";
import { FC } from "react";
import { LastConversionMessage } from "../../domain/message";
import { Grid, IconButton, Paper } from "@mui/material";
import {
  Chip,
  formatDateTime,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { channelIconMap, getMessageText, mapMessageConditionColors } from "../../utils/utils";
import { ConversationStatus } from "../../../graphql-messaging/generated/graphql";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { useProperty } from "../../../hooks/use-property";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Params, useSelectId } from "../../../hooks/use-select-id";

export const ChatLastConversationPreview: FC<{
  lastMessage?: LastConversionMessage;
  status: ConversationStatus;
  id: string;
}> = ({ lastMessage, status, id }) => {
  const ChannelIcon = channelIconMap(lastMessage?.channel?.platform);
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { selectedId: selectedConversationId, setSelectId } = useSelectId({
    fieldName: Params.SELECTED_CONVERSATION_ID
  });

  const isSelected = selectedConversationId === lastMessage?.conversationId;
  const { spacing, palette } = useTheme();
  return lastMessage ? (
    <Paper
      elevation={0}
      sx={{
        background: grey[100],
        borderColor: isSelected ? palette.common.black : grey[100],
        borderRadius: spacing(2),
        p: 2
      }}
      variant="outlined"
    >
      <Grid container justifyContent="space-between" alignItems="start">
        <Grid item xs={10}>
          <ParagraphBold
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%"
            }}
          >
            {getMessageText(lastMessage)}
          </ParagraphBold>
          <ParagraphSmall>
            {formatDateTime(lastMessage?.createdAt, selectedProperty?.details.timeZone)}
          </ParagraphSmall>
        </Grid>

        <Grid item xs={2} textAlign="right">
          <IconButton disabled={isSelected} onClick={() => setSelectId(id)} sx={{ mt: -0.5 }}>
            <OpenInNewIcon sx={{ color: isSelected ? "" : "common.black" }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" alignItems="center" mt={1}>
        <Grid item>
          <Chip
            color={mapMessageConditionColors(status)}
            label={t(`labels__conversation_status_${status}`)}
            size="small"
          />
        </Grid>

        <Grid item px={1}>
          <ChannelIcon />
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};
