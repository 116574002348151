import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import {
  MaintenanceSlotArgs,
  useAddMaintenanceMutationV1,
  useDeleteMaintenanceMutationv1,
  useUpdateMaintenanceMutationv1
} from "src/store/endpoints/unit.endpoints";

export const useAddMaintenanceSlot = () => {
  const { isRESTVersion } = useApiVersion();
  const [addMaintenanceSlotV1, resultAddV1] = useAddMaintenanceMutationV1();
  const addMaintenanceV1 = ({ unitIds, id, from, to, type, description }: MaintenanceSlotArgs) =>
    addMaintenanceSlotV1({
      unitIds,
      from,
      to,
      type,
      description
    });

  const functionCall = isRESTVersion ? addMaintenanceV1 : () => null;
  const result = isRESTVersion ? resultAddV1 : [];

  return [functionCall, result] as [typeof addMaintenanceV1, typeof resultAddV1];
};

export const useUpdateMaintenanceSlot = () => {
  const { isRESTVersion } = useApiVersion();
  const [updateMaintenanceSlotV1, resultUpdateV1] = useUpdateMaintenanceMutationv1();
  const updateMaintenanceV1 = ({ unitIds, id, from, to, type, description }: MaintenanceSlotArgs) =>
    updateMaintenanceSlotV1({
      unitIds,
      from,
      to,
      type,
      description,
      id
    });

  const functionCall = isRESTVersion ? updateMaintenanceV1 : () => null;
  const result = isRESTVersion ? resultUpdateV1 : [];

  return [functionCall, result] as [typeof updateMaintenanceV1, typeof resultUpdateV1];
};

export const useDeleteMaintenanceSlot = () => {
  const { isRESTVersion } = useApiVersion();
  const [deleteMaintenanceSlotV1, resultDeleteV1] = useDeleteMaintenanceMutationv1();

  const deleteMaintenanceV1 = (maintenanceId: string) => deleteMaintenanceSlotV1(maintenanceId);

  const functionCall = isRESTVersion ? deleteMaintenanceV1 : () => null;
  const result = isRESTVersion ? resultDeleteV1 : [];

  return [functionCall, result] as [typeof deleteMaintenanceV1, typeof resultDeleteV1];
};
