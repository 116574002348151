import React, { FC, useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import { ManualCheckinStepRegistrationCardForm } from "./components";
import { useFormValidations } from "../../../../../hooks/use-form-validation";
import { RegistrationCardFormDataType } from "../../../../../domain/registration-card";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { Grid, IconButton, useTheme } from "@mui/material";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import {
  ShareToDevice,
  ShareToDeviceType
} from "../../../../../components/share-to-device/share-to-device";
import { openBase64InNewTab } from "../../../../../utils/file-utils";
import Download from "../../../../../icons/Download";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useLazyGetFileQuery } from "../../../../../store/guest-service-files.api";
import { useProperty } from "../../../../../hooks/use-property";

export const ManualCheckinStepRegistrationCard: FC<ManualCheckinStepProps> = ({
  reservationDetails
}) => {
  const { manualCheckinValidation } = useFormValidations();
  const { t } = useTranslationWrapper();
  const [getFile] = useLazyGetFileQuery();
  const { selectedProperty } = useProperty();
  const [isConfirmedIndicatorShown, setIsConfirmedIndicatorShown] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const theme = useTheme();

  const initData: RegistrationCardFormDataType = useMemo(
    () => ({
      mainGuest: {
        gender: reservationDetails?.primaryGuest.gender || null,
        firstName: reservationDetails?.primaryGuest.firstName || "",
        lastName: reservationDetails?.primaryGuest.lastName || "",
        phone: reservationDetails?.primaryGuest.phone || "",
        email: reservationDetails?.primaryGuest.email || "",
        addressLine1: reservationDetails?.primaryGuest.address.addressLine1 || "",
        addressLine2: reservationDetails?.primaryGuest.address.addressLine2 || "",
        city: reservationDetails?.primaryGuest.address.city || "",
        region: reservationDetails?.primaryGuest.address.region || "",
        postcode: reservationDetails?.primaryGuest.address.postalCode || "",
        country: reservationDetails?.primaryGuest.address.countryCode || "",
        isSameAddress: true,
        frequentFlyerNumber: reservationDetails?.primaryGuest.frequentFlyerNumber || "",
        eClubMembershipNumber: reservationDetails?.primaryGuest.eClubMembershipNumber || "",
        id: reservationDetails?.primaryGuest?.id ?? "",
        emergencyEvacuationHelpNeeded:
          reservationDetails?.primaryGuest.emergencyEvacuationHelpNeeded || false,
        enrolledInLoyaltyProgram:
          reservationDetails?.primaryGuest.enrolledInLoyaltyProgram || false,
        pmsProfileId: reservationDetails?.primaryGuest.id || ""
      },
      travelBuddies:
        reservationDetails?.travelBuddies.map((buddy) => ({
          gender: buddy.gender || null,
          firstName: buddy.firstName || "",
          lastName: buddy.lastName || "",
          phone: buddy.phone || "",
          email: buddy.email || "",
          id: buddy.id || ""
        })) || [],
      maxCompanions: reservationDetails?.maxCompanions
    }),
    [
      reservationDetails?.primaryGuest.gender,
      reservationDetails?.primaryGuest.firstName,
      reservationDetails?.primaryGuest.lastName,
      reservationDetails?.primaryGuest.phone,
      reservationDetails?.primaryGuest.email,
      reservationDetails?.primaryGuest.address.addressLine1,
      reservationDetails?.primaryGuest.address.addressLine2,
      reservationDetails?.primaryGuest.address.city,
      reservationDetails?.primaryGuest.address.region,
      reservationDetails?.primaryGuest.address.postalCode,
      reservationDetails?.primaryGuest.address.countryCode,
      reservationDetails?.primaryGuest.frequentFlyerNumber,
      reservationDetails?.primaryGuest.eClubMembershipNumber,
      reservationDetails?.primaryGuest.id,
      reservationDetails?.primaryGuest.emergencyEvacuationHelpNeeded,
      reservationDetails?.primaryGuest.enrolledInLoyaltyProgram,
      reservationDetails?.travelBuddies,
      reservationDetails?.maxCompanions
    ]
  );

  const onSelectDevice = useCallback(() => {
    setIsFormDisabled(true);
  }, []);

  const onStopSharing = useCallback(() => {
    setIsFormDisabled(false);
  }, []);

  const onConfirm = useCallback(() => {
    setIsFormDisabled(false);
    setIsConfirmedIndicatorShown(true);
  }, []);

  const resId = useMemo(() => reservationDetails?.id ?? "", [reservationDetails?.id]);

  if (!initData) {
    return <></>;
  }

  return (
    <>
      <Grid container alignItems="center">
        <Heading4 m={2}>{t("labels__wizard_guests")}</Heading4>
        <ShareToDevice
          reservationId={resId}
          type={ShareToDeviceType.REGISTRATION_CARD}
          onSelectDevice={onSelectDevice}
          onStopSharing={onStopSharing}
          onConfirm={onConfirm}
        />
        <IconButton
          disableRipple
          sx={{ px: 0, width: 24, height: 24, ml: "auto", mr: 2 }}
          onClick={async (e) => {
            e.stopPropagation();
            const data = await getFile({
              propertyId: selectedProperty?.propertyId ?? "",
              fileName: "REGISTRATION_CARD"
            }).unwrap();

            openBase64InNewTab(data ?? "");
          }}
        >
          <Download sx={{ color: theme.palette.accent.main }} />
        </IconButton>
      </Grid>
      <Formik
        initialValues={initData}
        onSubmit={() => {}}
        validationSchema={manualCheckinValidation}
        validateOnMount
        enableReinitialize
      >
        <ManualCheckinStepRegistrationCardForm
          disabled={isFormDisabled}
          isConfirmedIndicatorShown={isConfirmedIndicatorShown}
          setIsConfirmedIndicatorShown={setIsConfirmedIndicatorShown}
        />
      </Formik>
    </>
  );
};
