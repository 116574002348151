import { IdCheckStatus } from "./reservation";
import { ReservationStatus } from "./reservation-status";
import { UnitCondition } from "./Unit";
import { ChipStatus } from "../components/chip/ChipStatus";
import { Person } from "./person";
import { MagicFile } from "./magic-file";
import { Price } from "./price";

export interface AccessibleDoor {
  id: string;
  title: string;
  is_general: boolean;
  pin: string;
}

export interface ReservationTableDto {
  id: string;
  magicToken: string;
  magicId: string;
  magicLink: string;
  bookingMagicLink: string;
  createdAt: string;
  checkoutOnAr: boolean;
  files: MagicFile[];
  booker: Person;
  primaryGuest: Person;
  // @deprecated, use primaryGuest.firstName
  firstName: string;
  // @deprecated, use primaryGuest.lastName
  lastName: string;
  adultsCount: number;
  allowedActions: AllowedActionEnum[];
  childrenCount: number;
  childrenAges: number[];
  reservationStatus: ReservationStatus;
  notes: Notes;
  arrival: string;
  departure: string;
  estimatedArrivalTime?: string;
  estimatedDepartureTime?: string;
  unitCleanOnCheckin: boolean;
  idCheckStatus: IdCheckStatus;
  unit?: Unit;
  bookedUnitGroupId: string;
  reservationCondition: ReservationConditionEnum;
  reservationConditionReasons: {
    [key: string]: ChipStatus;
  };
  reservationIsPaid: boolean;
  bookingChannel: string;
  propertyId: string;
  lastConfirmedPage: string;
  lastConfirmedCheckoutPage: string;
  completed: boolean;
  checkInTime?: string;
  checkOutTime?: string;
  guestPreferences: GuestPreferences;
  primaryGuestSameAsBooker: boolean;
  balance: Price;
  canCheckout: boolean;
}

export type GuestPreferences = { [key: string]: string | any };

export enum ReservationConditionEnum {
  CRITICAL = "CRITICAL",
  MINOR = "MINOR",
  OK = "OK",
  UNKNOWN = "UNKNOWN"
}

export enum AllowedActionEnum {
  ASSIGN_UNIT = "ASSIGN_UNIT",
  RESET_GUEST_FLOW = "RESET_GUEST_FLOW",
  RESEND_GUESTFLOW_LINK = "RESEND_GUESTFLOW_LINK",
  RESEND_NOTIFICATION = "RESEND_NOTIFICATION",
  ENCODE_KEY = "ENCODE_KEY",
  CHANGE_STATUS_TO_IN_HOUSE = "CHANGE_STATUS_TO_IN_HOUSE",
  CHANGE_STATUS_TO_CHECKED_OUT = "CHANGE_STATUS_TO_CHECKED_OUT",
  CHANGE_STATUS_TO_CANCELED = "CHANGE_STATUS_TO_CANCELED",
  CHANGE_RESERVATION_COMMENT = "CHANGE_RESERVATION_COMMENT",
  CHANGE_STATUS_TO_NO_SHOW = "CHANGE_STATUS_TO_NO_SHOW",
  CHANGE_ID_CHECK_STATUS = "CHANGE_ID_CHECK_STATUS",
  CHANGE_UNIT_CONDITION_TO_DIRTY = "CHANGE_UNIT_CONDITION_TO_DIRTY",
  CHANGE_UNIT_CONDITION_TO_CLEAN = "CHANGE_UNIT_CONDITION_TO_CLEAN",
  CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED = "CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED",
  JUMP_TO_RESERVATION = "JUMP_TO_RESERVATION",
  JUMP_TO_BOOKING = "JUMP_TO_BOOKING",
  EDIT_PREFERENCES = "EDIT_PREFERENCES",
  EDIT_PREFERENCES_TEXT = "EDIT_PREFERENCES_TEXT",
  VALIDATE_RESERVATION = "VALIDATE_RESERVATION",
  CHANGE_PIN_CONFIGURATION = "CHANGE_PIN_CONFIGURATION",
  COPY_GUESTFLOW_LINK = "COPY_GUESTFLOW_LINK",
  UNKNOWN = "UNKNOWN",
  MANUAL_CHECKIN = "MANUAL_CHECKIN",
  MANUAL_CHECKOUT = "MANUAL_CHECKOUT",
  SEND_CUSTOM_NOTIFICATION = "SEND_CUSTOM_NOTIFICATION"
}
interface Unit {
  unitId: string;
  unitGroupId: string;
  name: string;
  condition: UnitCondition;
  privacyMode: boolean;
  privacyModeLastChangeTimestamp: string;
}

export interface Notes {
  previousVisits: number;
  guestComment: Array<string>;
  bookerComment: Array<string>;
  comment: Array<string>;
  bookingComment: Array<string>;
  rateBreakdownComment?: Array<string>;
  beddingComment?: Array<string>;
}
export enum ReservationPreferencesCategory {
  GUEST_NOTE = "GUEST_NOTE",
  CONSUMPTION = "CONSUMPTION",
  PAYMENT = "PAYMENT",
  SERVICE = "SERVICE",
  UNIT = "UNIT"
}

export interface SentNotifications {
  channel: string;
  contentLink: string;
  conversationId: string;
  id: string;
  language: string;
  recipient: string;
  status: string;
  template: string;
  timestamp: string;
  magicId: string;
}

export enum NotificationTemplates {
  // PRIMARY_GUEST
  RESERVATION_CREATED = "RESERVATION_CREATED",
  CHECKIN_INTRODUCTION = "CHECKIN_INTRODUCTION",
  PRE_CHECKIN_REMINDER = "PRE_CHECKIN_REMINDER",
  CHECKIN = "CHECKIN",
  CHECKIN_REMINDER = "CHECKIN_REMINDER",
  CHECKED_IN = "CHECKED_IN",
  RESERVATION_CANCELED = "RESERVATION_CANCELED",
  CHECKOUT_INTRODUCTION = "CHECKOUT_INTRODUCTION",
  CHECKOUT_FOLLOW_UP = "CHECKOUT_FOLLOW_UP",
  INVOICE = "INVOICE",
  CHECKOUT_ALERT = "CHECKOUT_ALERT",
  RESEND_GUESTFLOW_LINK = "RESEND_GUESTFLOW_LINK",

  // BOOKER
  BOOKING_REMINDER = "BOOKING_REMINDER",
  BOOKING_CREATED = "BOOKING_CREATED",

  // // FOLIO DEBITOR
  // INVOICE_BILLING = "INVOICE_BILLING",

  // TRAVEL_BUDDY
  COMPANION_ADDED = "COMPANION_ADDED"
}
