import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { AllowedActionEnum } from "../domain/reservation-table-dto";
import { useUnitGroups } from "../hooks/use-unit-group-by-id";
import { UnitGroup } from "../domain/unit-group";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import { Unit } from "../domain/Unit";
import { useUnitsByUnitGroupId } from "../hooks/use-unit-by-id";
import { Heading2, Paragraph, Select, Subtitle } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { openBanner } from "../slices/banner.slice";
import { useDispatch } from "../store";
import ErrorIcon from "@mui/icons-material/Error";
import { AutocompleteComponent, AutocompleteOption } from "./autocomplete-component";
import { ReservationActionsModal } from "../features/reservations/reservation-details/details-tabs/reservation-details-tabs/reservation-actions-modal";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";
import {
  BoxLabelForMaintenanceAndStatus,
  mapLabelsToUnitsForAssignRoom
} from "src/utils/unit-utils";

const ErrorNotification = ({ text }: { text: string }) => {
  const { palette, spacing } = useTheme();
  return (
    <Box
      marginTop={2}
      bgcolor={palette.warning.light}
      sx={{ alignItems: "center", borderRadius: spacing(1) }}
    >
      <Grid container p={2}>
        <Grid item xs={1} display="flex" alignItems="center">
          <ErrorIcon sx={{ color: palette.warning.dark }} />
        </Grid>
        <Grid item xs={11}>
          <Paragraph sx={{ color: palette.warning.dark }}>{text}</Paragraph>
        </Grid>
      </Grid>
    </Box>
  );
};

interface AssignUnitModalProps extends ReservationActionsModal {}
export const AssignUnitModal: React.FC<React.PropsWithChildren<AssignUnitModalProps>> = ({
  reservation,
  isOpen,
  onClose
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const dispatch = useDispatch();
  const unitGroups: UnitGroup[] = useUnitGroups(reservation.propertyId);
  const [unitOption, setUnitOption] = useState<AutocompleteOption | null>(null);
  const [attributesOption, setAttributesOption] = useState<string[]>([]);
  const { spacing } = useTheme();

  const [unitGroupOption, setUnitGroupOption] = useState<AutocompleteOption | undefined>();

  const computedUnitGroupOption = useMemo(
    () =>
      unitGroupOption || {
        id: reservation?.bookedUnitGroupId,
        label:
          unitGroups?.find((unitGroup) => unitGroup.unitGroupId === reservation?.bookedUnitGroupId)
            ?.name ?? ""
      },
    [reservation?.bookedUnitGroupId, unitGroupOption, unitGroups]
  );

  // clear component state when closing the dialog
  const handleClose = useCallback(() => {
    onClose();
    setUnitOption(null);
    setUnitGroupOption(undefined);
  }, [onClose]);

  const units: Unit[] = useUnitsByUnitGroupId({
    unitGroupId: computedUnitGroupOption.id,
    propertyId: reservation.propertyId
  });

  const attributesList = useMemo(
    () =>
      (units || []).reduce(
        (acc, cur) => {
          const result = cur?.attributes?.reduce(
            (accumulator, current) => {
              if (!accumulator.find(({ value }) => value === current.id)) {
                accumulator.push({ value: current.id, label: current.name });
              }
              return accumulator;
            },
            [] as { value: string; label: string }[]
          );

          return result?.length ? [...result, ...acc] : acc;
        },
        [] as { value: string; label: string }[]
      ),
    [units]
  );

  const [performAction, result] = usePerformReservationAction();
  const action = AllowedActionEnum.ASSIGN_UNIT;

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();
      handleClose();
    }
  }, [result, dispatch, t, handleClose]);

  const handleSubmit = useCallback(() => {
    performAction({
      reservationId: reservation.id,
      action,
      payload: { unitId: unitOption?.id, unitGroupId: computedUnitGroupOption?.id }
    });
  }, [performAction, reservation.id, action, unitOption?.id, computedUnitGroupOption?.id]);

  const headerText = useMemo(() => {
    if (reservation.unit) {
      return `${t(
        "labels__reservation_action_modal_currently_assigned_text_ASSIGN_UNIT"
      )}: ${reservation.unit?.name}`;
    }
    return t("labels__reservation_action_modal_currently_no_assigned_unit_text_ASSIGN_UNIT");
  }, [reservation, t]);

  const unitGroupOptions = useMemo(
    () =>
      unitGroups
        ? unitGroups?.map((unitGroup) => ({
            label: unitGroup.name,
            id: unitGroup.unitGroupId
          }))
        : [],
    [unitGroups]
  );

  const unitOptions = useMemo(
    () => (units ? mapLabelsToUnitsForAssignRoom(units, t, attributesOption) : []),
    [units, attributesOption, t]
  );

  const displayAttributeNames = useMemo(() => {
    return attributesOption.reduce<string[]>((acc, cur) => {
      const label = attributesList.find((item) => item.value === cur)?.label;
      if (label) {
        acc.push(label);
      }
      return acc;
    }, []);
  }, [attributesList, attributesOption]);

  const getUnitOptionItem = useCallback(
    (id: string) => unitOptions.find((item) => item.id === id),
    [unitOptions]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }
      }}
    >
      <Grid container p={3} direction="column">
        <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
          <Grid container direction="row">
            <Grid item xs={11}>
              <Heading2 sx={{ color: theme.palette.text.primary }}>
                {t("labels__reservation_action_modal_title_ASSIGN_UNIT")}
              </Heading2>
            </Grid>
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="end">
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={handleClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Subtitle>{headerText}</Subtitle>
          <Box sx={{ marginTop: spacing(2) }}>
            <AutocompleteComponent
              options={unitGroupOptions}
              onSelected={(inputValue) => {
                setUnitGroupOption(inputValue);
                setUnitOption(null);
                setAttributesOption([]);
              }}
              value={computedUnitGroupOption}
              label={t("labels__unit_group")}
            />
          </Box>
          <Box sx={{ marginTop: spacing(2), width: "100%" }}>
            <Select
              multiple
              fullWidth
              sx={{ borderRadius: spacing(2) }}
              variant={"outlined"}
              disabled={!attributesList.length}
              label={t("labels__unit_attribute")}
              //@ts-ignore
              onChange={(e) => setAttributesOption(e?.target?.value)}
              //@ts-ignore
              value={attributesOption}
              renderValue={() => displayAttributeNames.join(", ")}
              options={attributesList}
              selected={!!attributesList.length}
            />
          </Box>
          <Box sx={{ marginTop: spacing(2) }}>
            <AutocompleteComponent
              options={unitOptions}
              onSelected={(inputValue) => {
                setUnitOption(inputValue);
              }}
              value={unitOption}
              label={t("labels__unit")}
              displayItem={(_, option) => {
                const unitOptionItem = getUnitOptionItem(option.id);
                return (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Paragraph>{unitOptionItem?.label}</Paragraph>
                    </Grid>
                    <Grid item>
                      <BoxLabelForMaintenanceAndStatus
                        maintenanceType={unitOptionItem?.maintenance}
                        status={unitOptionItem?.status}
                        t={t}
                      />
                    </Grid>
                  </Grid>
                );
              }}
              inputEndEndorsement={
                unitOption ? (
                  <Grid item>
                    <BoxLabelForMaintenanceAndStatus
                      maintenanceType={getUnitOptionItem(unitOption.id)?.maintenance}
                      status={getUnitOptionItem(unitOption.id)?.status}
                      t={t}
                    />
                  </Grid>
                ) : undefined
              }
            />
          </Box>
          {!unitOptions.length && (
            <ErrorNotification
              text={t("labels__reservation_action_no_units_with_given_attributes_ASSIGN_UNIT")}
            />
          )}
          {computedUnitGroupOption.id !== reservation.bookedUnitGroupId && (
            <ErrorNotification
              text={t("labels__reservation_action_modal_different_unit_group_text_ASSIGN_UNIT")}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            fullWidth
            disabled={!unitOption?.id}
            sx={{ marginTop: theme.spacing(5), cursor: "pointer" }}
          >
            <Paragraph
              sx={{
                color: theme.palette.background.paper,
                textTransform: "none"
              }}
            >
              {t("labels__reservation_action_modal_assign_button_ASSIGN_UNIT")}
            </Paragraph>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
