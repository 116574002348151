import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "../store";
import {
  fetchProperties,
  selectPropertiesByLanguage,
  selectPropertyStatus,
  selectSelectedProperty
} from "../slices/properties";
import { Property } from "../domain/property";
import { useGetAllPropertiesQuery } from "../graphql/queries/GetProperty.generated";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { transformPropertyV2ToCommonProperty } from "../graphql/transform/transform-property";
import { Params, useSelectId } from "./use-select-id";
import { mapQueryStatusToEntityStateStatus } from "../graphql/transform/transform-utils";

export const useProperty = () => {
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();

  const { data, status } = useGetAllPropertiesQuery(
    {},
    { skip: isRESTVersion, refetchOnMountOrArgChange: false }
  );

  const allPropertiesV1 = useSelector(selectPropertiesByLanguage);
  const propertyStatus = useSelector(selectPropertyStatus);

  const allProperties = useMemo(
    () => (isRESTVersion ? allPropertiesV1 : transformPropertyV2ToCommonProperty(data) ?? []),
    [isRESTVersion, allPropertiesV1, data]
  );
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.SELECTED_PROPERTY_ID
  });
  const selectedPropertyInState = useSelector(selectSelectedProperty);

  const getProperty = useCallback(
    (propertyId: string) => allProperties.find((property) => property.propertyId === propertyId),
    [allProperties]
  );

  const selectedPropertyIds = useMemo(() => selectedId?.split(",") ?? [], [selectedId]);

  // @deprecated Should be removed after all pages are multi property
  const selectedProperty: Property | undefined = useMemo(() => {
    const propertyId = selectedPropertyIds[0];

    if (propertyId) {
      return getProperty(propertyId);
    }

    console.warn("selectedPropertyIds is not defined, please fix it.");
    return getProperty(selectedPropertyInState) || allProperties[0];
  }, [allProperties, selectedPropertyInState, getProperty, selectedPropertyIds]);

  useEffect(() => {
    if (isRESTVersion) {
      dispatch(fetchProperties());
    }
  }, [dispatch, isRESTVersion]);

  const selectedPropertiesPreview = useMemo(
    () => selectedPropertyIds.map((id) => getProperty(id)?.details?.name ?? id).join(", "),
    [selectedPropertyIds, getProperty]
  );

  const computedStatus = isRESTVersion ? propertyStatus : mapQueryStatusToEntityStateStatus(status);

  return {
    allProperties,
    getProperty,
    selectedProperty,
    setSelectedPropertyIds: setSelectId,
    selectedPropertyIds,
    selectedPropertiesPreview,
    status: computedStatus
  };
};
