import { FC } from "react";
import { Box, Grid } from "@mui/material";
import PageTitle from "../components/PageTitle";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { ReleaseNotes } from "src/features/release-notes/release-notes";

const ReleaseNotesPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();

  return (
    <>
      <Box px={isMobile ? 0 : 3} py={isMobile ? 0 : 4}>
        {!isMobile && (
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}
          >
            <Grid item mb={2.5}>
              <PageTitle
                title={t("labels__release_notes")}
                subTitle={`${t("release__what__new")}?`}
              />
            </Grid>
          </Grid>
        )}
        <ReleaseNotes />
      </Box>
    </>
  );
};

export default ReleaseNotesPage;
