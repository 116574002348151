import { AssignKeyHandlerProps } from "./assign-key-handler";
import React, { FC, useCallback } from "react";
import { isStatusFailed, isStatusLoading, isStatusSuccess } from "src/domain/EntityStateStatus";
import { Button, Heading3, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { Box, Grid } from "@mui/material";
import { AssignKeyAnimationRunning } from "../animations/assign-key-animations";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useAssignApiKey } from "./use-assign-api-key";
import { openBanner } from "../../slices/banner.slice";
import { useDispatch } from "../../store";

export const KeyAssignApi: FC<React.PropsWithChildren<AssignKeyHandlerProps>> = ({
  reservation,
  tagReaderId,
  onClose,
  onSuccess
}) => {
  const config = useDoorProviderConfig(reservation.propertyId);
  const { status: keyStatus, action } = useAssignApiKey({
    reservationId: reservation.id,
    tagReaderId
  });
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    action()
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        dispatch(
          openBanner({
            type: "error",
            title: t("labels__action_failed_to_perform")
          })
        );
      });
  }, [action, dispatch, onSuccess, t]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" direction="column" p={2}>
        <Box maxWidth={400}>
          {isStatusSuccess(keyStatus) ? (
            <Heading3 color="text.secondary">
              {t("labels__reservation_action_modal_success_ENCODE_KEY")}
            </Heading3>
          ) : (
            <>
              <Box textAlign="center" pt={7} mx={6}>
                <Heading3 color="text.secondary">
                  {t("labels__reservation_action_modal_info_text_ENCODE_KEY")}
                </Heading3>
              </Box>
              <Box pt={2.5}>
                <AssignKeyAnimationRunning doorProvider={config?.doorProvider} />
              </Box>
            </>
          )}
        </Box>
      </Grid>

      <Box textAlign="center">
        <Button onClick={onClick} fullWidth disabled={isStatusLoading(keyStatus)}>
          {isStatusFailed(keyStatus)
            ? t("labels__reservation_action_modal_try_again_button_ENCODE_KEY")
            : t("labels__reservation_action_modal_assign_key_button_ENCODE_KEY")}
        </Button>
      </Box>
    </>
  );
};
