import React, { FC, useContext, useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useSelector } from "react-redux";
import { selectKeycloakSliceUserInfo } from "../../slices/keycloak.slice";
import { useDispatch } from "../../store";
import { updateUserInfoLanguage } from "../../slices/user-info.slice";
import { LanguageSelector, TenantContext } from "@likemagic-tech/sv-magic-library";

export const DEFAULT_LANGUAGE = "EN";

export function twoLetterLanguageCode(language: string): string {
  return language.substring(0, 2);
}

export function fiveLetterLanguageCode(language: string): string {
  return language.substring(0, 5);
}

export const getI18nLanguage = () =>
  i18n.language ? twoLetterLanguageCode(i18n.language) : DEFAULT_LANGUAGE;

export const LanguageSelectorComponent: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslationWrapper();
  const userInfo = useSelector(selectKeycloakSliceUserInfo);
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const { allowedBackofficeLanguages } = useContext(TenantContext);

  const languageOptions =
    allowedBackofficeLanguages?.map((lang) => ({
      value: lang,
      label: t(`labels__language_${lang}`)
    })) ?? [];

  useEffect(() => {
    if (userInfo?.locale) {
      setLang(twoLetterLanguageCode(userInfo.locale));
    }
  }, [userInfo?.locale]);

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
      dispatch(updateUserInfoLanguage({ lang }));
    }
  }, [lang, dispatch]);

  return (
    <LanguageSelector
      langOptions={languageOptions}
      tooltipLabel={t("labels__change_language")}
      onLangChange={(e) => setLang(twoLetterLanguageCode(e))}
      color="white"
    />
  );
};
