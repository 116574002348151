import { useMemo } from "react";
import { useTranslationWrapper } from "./use-translation-wrapper";
import yup from "../config/yup.config";
import { isAfter } from "date-fns";
import { PriceAdjustmentType } from "../graphql/generated/graphql";

export const useFormValidations = () => {
  const { t } = useTranslationWrapper();
  const taskFormValidation = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .required(t("labels__task_title_not_provided"))
          .max(100, t("labels__max_length_100")),
        due: yup.date().nullable().min(new Date(), t("labels__task_due_date_past_error"))
      }),
    [t]
  );

  const subtasksFormValidation = useMemo(
    () =>
      yup.object().shape({
        subtasks: yup.array().of(taskFormValidation)
      }),
    [taskFormValidation]
  );

  const notificationPersonalFieldsValidations = useMemo(
    () =>
      yup.object().shape(
        {
          email: yup
            .string()
            .email()
            .when("phone", {
              is: (phone: string) => !phone || phone.length === 0,
              then: yup.string().nullable().email().required(),
              otherwise: yup.string().nullable().email()
            }),
          phone: yup
            .string()
            .phone()
            .when("email", {
              is: (email: string) => !email || email.length === 0,
              then: yup.string().nullable().phone().required(),
              otherwise: yup.string().nullable().phone()
            })
        },
        [["email", "phone"]]
      ),
    []
  );

  const personValidationFields = useMemo(() => {
    return {
      firstName: yup.string().required(t("validation__wizard_first_name_required") || undefined),
      lastName: yup.string().required(t("validation__wizard_last_name_required") || undefined),
      email: yup
        .string()
        .required(t("validation__email_required") || undefined)
        .email(t("validation__wizard_email_format") || undefined),
      phone: yup
        .string()
        .nullable()
        .phone(t("validation__wizard_phone_format") || undefined)
    };
  }, [t]);

  const travelBuddyValidationFields = useMemo(() => {
    return {
      firstName: yup.string().required(t("validation__wizard_first_name_required") || undefined),
      lastName: yup.string().required(t("validation__wizard_last_name_required") || undefined),
      email: yup
        .string()
        .email(t("validation__wizard_email_format"))
        .when("phone", {
          is: (phone: string) => !phone || phone.length === 0,
          then: yup.string().nullable().email(t("validation__wizard_email_format")),
          otherwise: yup.string().nullable().email(t("validation__wizard_email_format"))
        }),
      phone: yup
        .string()
        .phone()
        .when("email", {
          is: (email: string) => !email || email.length === 0,
          then: yup.string().nullable().phone(t("validation__wizard_phone_format")),
          otherwise: yup.string().nullable().phone(t("validation__wizard_phone_format"))
        })
    };
  }, [t]);

  const buddyValidation = useMemo(
    () => yup.object().shape(travelBuddyValidationFields, [["email", "phone"]]),
    [travelBuddyValidationFields]
  );

  const manualCheckinValidation = useMemo(
    () =>
      yup.object().shape({
        mainGuest: yup
          .object()
          .shape(personValidationFields, [["email", "phone"]])
          .required(),
        travelBuddies: yup.array().of(buddyValidation)
      }),
    [personValidationFields, buddyValidation]
  );

  const maintenanceFormValidation = useMemo(
    () =>
      yup.object().shape({
        type: yup.string().required(),
        from: yup.date().min(new Date(), t("labels__maintenance_from_date_past_error")).required(),
        to: yup
          .date()
          .min(new Date(), t("labels__maintenance_to_date_past_error"))
          .test(
            "is-after-from",
            t("labels__maintenance_to_date_before_from_error"),
            function (toDate) {
              const fromDate = this.parent.from;

              if (fromDate && toDate) {
                return isAfter(toDate, fromDate);
              }

              return true;
            }
          )
          .required(),
        unitIds: yup.array().required().min(1)
      }),
    [t]
  );

  const addChargeModalValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          orderItems: yup.array().of(
            yup.object().shape({
              quantity: yup
                .number()
                .min(1, t("labels__greater_then_zero"))
                .required(t("labels__required")),
              orderItem: yup
                .object()
                .shape({
                  id: yup.string().required(t("labels__required"))
                })
                .required(),
              amount: yup
                .number()
                .test("is-not-zero", t("labels__not_zero"), (value) => value !== 0)
                .required(t("labels__required")),
              note: yup.string().when("amount", {
                is: (value: number) => value < 0,
                then: yup.string().required(t("labels__required")),
                otherwise: yup.string().notRequired()
              })
            })
          )
        })
        .required(),
    [t]
  );

  const terminalPaymentValidations = useMemo(
    () =>
      yup.object().shape({
        paymentTerminalId: yup.string().required()
      }),
    []
  );

  const paymentLinkValidation = useMemo(
    () =>
      yup.object().shape({
        expiryDate: yup
          .mixed()
          .nullable()
          .test("is-valid-date", "Expiry date must be a valid date", (value) => {
            return Object.prototype.toString.call(value) === "[object Date]" && !isNaN(value);
          })
          .test("is-within-range", t("labels__expiry_date_max_70_days"), (value) => {
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + 70);
            return value <= maxDate;
          })
          .test("not-in-past", t("labels__expiry_date_past_error"), (value) => {
            return value >= new Date();
          })
          .required(t("labels__required")),
        price: yup.object().shape({
          amount: yup.number().required(t("labels__required"))
        })
      }),
    [t]
  );

  const sendInvoiceFormValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          message: yup.string().required(t("labels__required")),
          sendTo: yup
            .array()
            .of(yup.string().email(t("validation__email_invalid")))
            .required(t("validation__email_required"))
            .min(1, t("validation__email_required")),
          folios: yup.array().of(yup.string()).required().min(1),
          copySendTo: yup
            .array()
            .optional()
            .of(yup.string().nullable().email(t("validation__email_invalid")))
        })
        .required(),
    [t]
  );

  const sendPaymentLinkValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          message: yup.string().required(t("labels__required")),
          sendTo: yup
            .array()
            .of(yup.string().email(t("validation__email_invalid")))
            .required(t("validation__email_required"))
            .min(1, t("validation__email_required")),
          copySendTo: yup
            .array()
            .optional()
            .of(yup.string().nullable().email(t("validation__email_invalid")))
        })
        .required(),
    [t]
  );

  const adjustChargeModalValidation = useMemo(
    () =>
      yup
        .object()
        .shape({
          reason: yup.string().required(t("labels__required")),
          reasonTitle: yup.string().required(t("labels__required")),
          price: yup.object().when("priceAdjustmentType", {
            is: (value: string) => value === PriceAdjustmentType.FlatAmount,
            then: yup.object().shape({
              amount: yup
                .number()
                .min(1, t("labels__greater_then_zero"))
                .required(t("labels__required"))
            })
          }),
          percentage: yup.number().when("priceAdjustmentType", {
            is: (value: string) => value === PriceAdjustmentType.Percentage,
            then: yup
              .number()
              .min(1, t("labels__greater_then_zero"))
              .max(100, t("labels__less_then_hundred"))
              .required(t("labels__required"))
          })
        })
        .required(),
    [t]
  );
  return {
    taskFormValidation,
    notificationPersonalFieldsValidations,
    maintenanceFormValidation,
    manualCheckinValidation,
    addChargeModalValidation,
    terminalPaymentValidations,
    paymentLinkValidation,
    sendInvoiceFormValidation,
    sendPaymentLinkValidation,
    adjustChargeModalValidation,
    subtasksFormValidation
  };
};
