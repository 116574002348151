import React, { FC, useMemo } from "react";
import { Box, Divider, Tooltip } from "@mui/material";
import { ArrowDown } from "../../icons/ArrowDown";
import { useTheme } from "@mui/material/styles";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { ReservationConditionEnum } from "../../domain/reservation-table-dto";
import { ReservationConditionsResponse } from "../../store/endpoints/reservation-table.endpoints";
import {
  BaseTableFiltersProps,
  FilterMapInterface,
  TableFilter
} from "../../components/table/filters/table-filters";
import { QuickFilterCheckbox } from "src/components/quick-filter-checkbox/quick-filter-checkbox";
import { ReservationAdditionalFilters } from "./reservation-additional-filters";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { Button, ChipProps } from "@likemagic-tech/sv-magic-library";
import { DateFilters } from "../../components/table/filters/date-filters";
import { AdditionalFilters } from "../../components/table/filters/additional-filters";
import {
  AdditionalFiltersEnum,
  BalanceStatusEnum,
  ChannelCodeEnum,
  GuaranteeType,
  GuestFlowStatusEnum,
  PaymentTypeEnum,
  RecurringGuestEnum
} from "./domain/enums";
import { ReservationStatus } from "../../domain/reservation-status";
import { AddedFilters } from "../../components/table/filters/added-filters";

export const mapReservationConditionLabels = {
  [ReservationConditionEnum.OK]: "labels__condition_OK",
  [ReservationConditionEnum.MINOR]: "labels__condition_MINOR",
  [ReservationConditionEnum.CRITICAL]: "labels__condition_CRITICAL"
};

const additionalReservationFilterNames: {
  [key: string]: Array<string>;
} = {
  [AdditionalFiltersEnum.RESERVATION_CONDITION]: Object.keys(ReservationConditionEnum)
    .filter((item) => item !== ReservationConditionEnum.UNKNOWN)
    .map((item) => ReservationConditionEnum[item as keyof typeof ReservationConditionEnum]),
  [AdditionalFiltersEnum.RECURRING_GUEST]: Object.keys(RecurringGuestEnum).map(
    (item) => RecurringGuestEnum[item as keyof typeof RecurringGuestEnum]
  ),
  [AdditionalFiltersEnum.BALANCE_STATUS]: Object.keys(BalanceStatusEnum).map(
    (item) => BalanceStatusEnum[item as keyof typeof BalanceStatusEnum]
  ),
  [AdditionalFiltersEnum.FLOW_STATE]: Object.keys(GuestFlowStatusEnum).map(
    (item) => GuestFlowStatusEnum[item as keyof typeof GuestFlowStatusEnum]
  ),
  [AdditionalFiltersEnum.RESERVATION_STATUS]: Object.keys(ReservationStatus).map(
    (item) => ReservationStatus[item as keyof typeof ReservationStatus]
  ),
  [AdditionalFiltersEnum.PAYMENT_TYPE]: Object.keys(PaymentTypeEnum).map(
    (item) => PaymentTypeEnum[item as keyof typeof PaymentTypeEnum]
  ),
  [AdditionalFiltersEnum.CHANNEL_CODE]: Object.keys(ChannelCodeEnum).map(
    (item) => ChannelCodeEnum[item as keyof typeof ChannelCodeEnum]
  ),
  [AdditionalFiltersEnum.GUARANTEE_TYPE]: Object.keys(GuaranteeType).map(
    (item) => GuaranteeType[item as keyof typeof GuaranteeType]
  )
};

const mapReservationConditionColors = (condition: string): ChipProps["color"] => {
  if (condition === "OK") {
    return "success";
  } else if (condition === "MINOR") {
    return "warning";
  } else if (condition === "CRITICAL") {
    return "error";
  }
  return undefined;
};

interface ReservationToolbarProps extends BaseTableFiltersProps {
  onExport: () => void;
  reservationConditionNumbers?: ReservationConditionsResponse;
  disabled: boolean;
  tooltipText: string;
  filterMap: FilterMapInterface;
  onFilterMapChange: (filterMap: FilterMapInterface) => void;
  removeFilter: (val: Array<string>, isReset?: boolean) => void;
}

export const ReservationToolbar: FC<ReservationToolbarProps> = ({
  onExport,
  reservationConditionNumbers,
  disabled,
  tooltipText,
  filterMap,
  onFilterMapChange,
  removeFilter
}) => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();
  const isMobile = useIsMobile();

  const filteredConditions = useMemo(() => {
    return Object.keys(reservationConditionNumbers ?? {}).filter(
      (condition) => condition !== ReservationConditionEnum.UNKNOWN
    );
  }, [reservationConditionNumbers]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: spacing(2, 2.5),
        paddingTop: isMobile ? 0 : 2,
        gap: spacing(1.25)
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flex: 1, gap: spacing(1.25), flexWrap: "wrap" }}>
          <TableFilter filterMap={filterMap} onFilterMapChange={onFilterMapChange}>
            <DateFilters
              filterMap={filterMap}
              setFilterMap={onFilterMapChange}
              onRemoveFilter={removeFilter}
            />
            <Divider />
            <AdditionalFilters
              filterMap={filterMap}
              onFilterMapChange={onFilterMapChange}
              additionalFilterNames={additionalReservationFilterNames}
            />
          </TableFilter>
          {reservationConditionNumbers && (
            <Box sx={{ display: "flex", gap: spacing(1.25) }}>
              {filteredConditions.map((condition) => (
                <div key={`condition-${condition}`}>
                  <QuickFilterCheckbox
                    mapColors={mapReservationConditionColors}
                    condition={condition}
                    count={
                      reservationConditionNumbers[
                        condition as keyof typeof reservationConditionNumbers
                      ]
                    }
                    filterMap={filterMap}
                    filter={{ name: "reservationCondition" }}
                    label={t(
                      mapReservationConditionLabels[
                        condition as keyof typeof mapReservationConditionLabels
                      ]
                    )}
                    onFilterMapChange={onFilterMapChange}
                  />
                </div>
              ))}
            </Box>
          )}
          <ReservationAdditionalFilters
            onFilterMapChange={onFilterMapChange}
            filterMap={filterMap}
          />
        </Box>
        {!isMobile && (
          <Box>
            <Tooltip title={tooltipText} arrow={true} disableHoverListener={!disabled}>
              <div>
                {/* div used so we can have a tooltip when the button is disabled */}
                <Button
                  variant="ghost"
                  size="medium"
                  startIcon={<ArrowDown color={disabled ? "disabled" : "primary"} />}
                  onClick={onExport}
                  disabled={disabled}
                >
                  {t("labels__export")}
                </Button>
              </div>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box>
        <AddedFilters filterMap={filterMap} removeFilter={removeFilter} />
      </Box>
    </Box>
  );
};
