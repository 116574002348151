import { useGetActorsQuery } from "../../graphql-tasks/query/GetActors.generated";
import { useMemo } from "react";
import { ActorType } from "../../graphql-tasks/generated/graphql";

export const useGetActors = () => {
  const { data } = useGetActorsQuery();
  const actors = data?.GetActors;
  const actorOptions = useMemo(() => {
    return (actors || [])
      .filter((item) => item.assignable)
      .filter((item) => item.actorType === ActorType.Employee)
      .map((item) => ({
        id: `${item.actorId}`,
        label: item.displayName ?? ""
      }));
  }, [actors]);

  const actorGroupOptions = useMemo(() => {
    return (actors || [])
      .filter((item) => item.assignable)
      .filter((item) => item.actorType === ActorType.ActorGroup)
      .map((item) => ({
        id: `${item.actorId}`,
        label: item.displayName ?? ""
      }));
  }, [actors]);

  return { data, actorOptions, actorGroupOptions };
};
