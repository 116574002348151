import { UnitCondition } from "../../domain/Unit";
import { Priority, UnitToBeCleaned } from "../../domain/UnitToBeCleaned";

export const filterableStatuses = [
  UnitCondition.DIRTY,
  UnitCondition.CLEAN_TO_BE_INSPECTED,
  UnitCondition.CLEAN
];

export const displayLabels = (unitType: string, unit: UnitToBeCleaned) => {
  const listOfLabels = [];
  if (unit.departure) {
    listOfLabels.push("labels__unit_empty_since");
  }
  if (unit.expectedDeparture) {
    listOfLabels.push("labels__unit_empty_at");
  }

  if (unit.arrival) {
    listOfLabels.push("labels__unit_occupied_since");
  }
  if (unit.contractualCheckInTime) {
    listOfLabels.push("labels__unit_should_be_ready_by");
  }

  if (unit.priority === Priority.MEDIUM) {
    listOfLabels.push("labels__unit_scheduled_cleaning");
  }
  if (
    !listOfLabels.length &&
    unit.status &&
    unit.status.condition !== UnitCondition.CLEAN &&
    !unit.status.occupied
  ) {
    listOfLabels.push("labels__unit_empty_more_than_one_day");
  }

  return listOfLabels;
};
