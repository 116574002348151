import { FC } from "react";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

interface CheckboxFilterProps {
  values: Array<string>;
  options: Array<string>;
  onChange: (values: Array<string>) => void;
}
const useStyles = makeStyles()(({ palette, spacing }: Theme) => ({
  selected: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    marginBottom: spacing(1),
    borderRadius: spacing(1),
    padding: spacing(1),
    "& .MuiFormControlLabel-label": {
      color: palette.common.white
    }
  },
  notSelected: {
    backgroundColor: palette.grey.A100,
    color: palette.common.black,
    marginBottom: spacing(1),
    borderRadius: spacing(1),
    padding: spacing(1)
  },
  rootCheckbox: {
    padding: spacing(0.5)
  }
}));
export const CheckboxFilter: FC<React.PropsWithChildren<CheckboxFilterProps>> = ({
  values,
  options,
  onChange
}) => {
  const isOptionSelected = (option: string) => values.indexOf(option) !== -1;
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const toggleCheckbox = (option: string) => () => {
    const index = values.indexOf(option);
    if (index === -1) {
      onChange(values.concat(option));
    } else {
      onChange(
        values.reduce((acc, next) => {
          if (next !== option) {
            return acc.concat(next);
          }
          return acc;
        }, [] as string[])
      );
    }
  };

  return (
    <Box>
      <Grid container direction="column" pl={1} alignItems="flex-start">
        {options.map((option) => (
          <FormControlLabel
            className={isOptionSelected(option) ? classes.selected : classes.notSelected}
            key={`checkbox-${option}`}
            control={
              <Checkbox
                value={isOptionSelected(option)}
                onChange={toggleCheckbox(option)}
                checked={isOptionSelected(option)}
                checkedIcon={<></>}
                icon={<></>}
                classes={{
                  root: classes.rootCheckbox
                }}
              />
            }
            label={<Box pr={1}>{t(`labels__additional_filter_${option}`)}</Box>}
          />
        ))}
      </Grid>
    </Box>
  );
};
