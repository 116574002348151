import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  deserializeIdOfFilter,
  FilterMapInterface,
  FilterObjectType,
  serializeIdOfFilter
} from "../filters/table-filters";
import { FilterOperator } from "../../../types/filters/filters";
import { FREE_TEXT_SEARCH } from "../filters/free-text-filter";
import { AdditionalDateFiltersEnum } from "src/features/reservations/reservation-additional-filters";
import { Params } from "../../../hooks/use-select-id";

const arrivalEnumData = {
  type: FilterObjectType.DATE_RANGE,
  name: "arrivalEnum"
};

const depatureEnumData = {
  type: FilterObjectType.DATE_RANGE,
  name: "departureEnum"
};

const reservationStatusData = {
  name: "reservationStatus",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In
};

export const ARRIVAL_RESERVATIONS_SEARCH_FILTERS = {
  [serializeIdOfFilter({
    ...arrivalEnumData,
    operator: FilterOperator.In
  })]: {
    ...arrivalEnumData,
    operator: FilterOperator.In,
    value: AdditionalDateFiltersEnum.TODAY
  },
  [serializeIdOfFilter(reservationStatusData)]: {
    ...reservationStatusData,
    value: "InHouse,Confirmed"
  }
};

const ARRIVAL_WITHOUT_IN_HOUSE_RESERVATIONS_SEARCH_FILTERS = {
  [serializeIdOfFilter({
    ...arrivalEnumData,
    operator: FilterOperator.In
  })]: {
    ...arrivalEnumData,
    operator: FilterOperator.In,
    value: AdditionalDateFiltersEnum.TODAY
  },
  [serializeIdOfFilter(reservationStatusData)]: {
    ...reservationStatusData,
    value: "Confirmed"
  }
};

export const DEPARTURE_RESERVATIONS_SEARCH_FILTERS = {
  [serializeIdOfFilter({
    ...depatureEnumData,
    operator: FilterOperator.In
  })]: {
    ...depatureEnumData,
    operator: FilterOperator.In,
    value: AdditionalDateFiltersEnum.TODAY
  },
  [serializeIdOfFilter(reservationStatusData)]: {
    ...reservationStatusData,
    value: "InHouse,CheckedOut"
  }
};

export const IN_HOTEL_RESERVATIONS_SEARCH_FILTERS = {
  [serializeIdOfFilter(reservationStatusData)]: {
    ...reservationStatusData,
    value: "InHouse"
  }
};

const NO_SHOW_RESERVATIONS_SEARCH_FILTERS = {
  [serializeIdOfFilter({
    ...arrivalEnumData,
    operator: FilterOperator.In
  })]: {
    ...arrivalEnumData,
    operator: FilterOperator.In,
    value: AdditionalDateFiltersEnum.TODAY
  },
  [serializeIdOfFilter(reservationStatusData)]: {
    ...reservationStatusData,
    value: "NoShow"
  }
};

export const breakfastStatusFilter = {
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In,
  name: "breakfastStatus"
};

export const freeTextSearchFilter = {
  type: FilterObjectType.FREE_TEXT_SEARCH,
  operator: FilterOperator.Equality,
  name: FREE_TEXT_SEARCH
};

export const DEFAULT_RESERVATIONS_SEARCH_FILTERS = {
  [serializeIdOfFilter(reservationStatusData)]: {
    ...reservationStatusData,
    value: "InHouse,Confirmed"
  }
};

export const generateDefaultReservationTableURLSearchParams = () =>
  generateURLSearchParams(DEFAULT_RESERVATIONS_SEARCH_FILTERS);

export const generateArrivalURLSearchParams = (arrivalsWithoutInHouseEnabled: Boolean) =>
  arrivalsWithoutInHouseEnabled
    ? generateURLSearchParams(ARRIVAL_WITHOUT_IN_HOUSE_RESERVATIONS_SEARCH_FILTERS)
    : generateURLSearchParams(ARRIVAL_RESERVATIONS_SEARCH_FILTERS);

export const generateDepartureURLSearchParams = () =>
  generateURLSearchParams(DEPARTURE_RESERVATIONS_SEARCH_FILTERS);

export const generateInHouseURLSearchParams = () =>
  generateURLSearchParams(IN_HOTEL_RESERVATIONS_SEARCH_FILTERS);

export const generateNoShowURLSearchParams = () =>
  generateURLSearchParams(NO_SHOW_RESERVATIONS_SEARCH_FILTERS);

export const generateURLSearchParams = (newSearchOfferParamsDTO: FilterMapInterface) => {
  const newParam = new URLSearchParams();

  Object.keys(newSearchOfferParamsDTO).forEach((key: string) => {
    //Falsy values save as empty string to avoid "null" | "undefined" values
    newParam.set(key, newSearchOfferParamsDTO[key].value ?? "");
  });

  return newParam;
};

export const useSearchFilterParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const filterMap: FilterMapInterface = useMemo(() => {
    const searchParamState = new URLSearchParams(search);
    const filters: Partial<Record<string, any>> = {};
    searchParamState.forEach((value, key) => {
      filters[key] = {
        ...deserializeIdOfFilter(key),
        value: value
      };
    });

    return filters as FilterMapInterface;
  }, [search]);

  const setFilterMap = useCallback(
    (newSearchOfferParamsDTO: FilterMapInterface) => {
      const newSearchParams = generateURLSearchParams(newSearchOfferParamsDTO);
      if (search !== "?".concat(newSearchParams.toString())) {
        const propertyIdParam = new URLSearchParams(search).get(Params.SELECTED_PROPERTY_ID);
        //We should not remove
        if (propertyIdParam) {
          newSearchParams.set(Params.SELECTED_PROPERTY_ID, propertyIdParam);
        }
        const newSearch = {
          search: "?".concat(newSearchParams.toString())
        };
        navigate(newSearch);
      }
    },
    [navigate, search]
  );

  return {
    filterMap,
    setFilterMap
  };
};
