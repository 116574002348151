import React, { ReactElement } from "react";
import { useTheme } from "@mui/material";
import { MagicFileData } from "../../../../types/magic-file";
import { Attachments } from "../../domain/task";
import { useLazyGetFileQuery } from "../../../../store/task-management-files.api";
import { MagicFileType } from "../../../../domain/magic-file-type";
import { Chip } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

const downloadDocument = (url: string, fileName: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  xhr.send();
};

export const AttachmentsPreview = ({
  attachment,
  removeFileWithPreview
}: {
  attachment: Attachments[number];
  removeFileWithPreview: (fileName: string) => void;
}) => {
  const theme = useTheme();

  const [getFile] = useLazyGetFileQuery();

  const file: MagicFileData = {
    fileName: attachment.originalFileName,
    magicFileType: MagicFileType.ATTACHMENTS,
    fileSrc: attachment.fileName,
    contentType: attachment.contentType
  };

  const getFileIcon = (contentType: string | undefined): ReactElement => {
    switch (contentType) {
      case "application/pdf":
        return <PictureAsPdfOutlinedIcon />;
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
      case "image/gif":
      case "image/svg+xml":
        return <ImageOutlinedIcon />;
      default:
        return <></>;
    }
  };

  const onChipClick = async () => {
    const response = await getFile({ uuid: attachment.uuid });
    downloadDocument(response.data ?? "", file.fileName ?? "");
  };

  return (
    <Chip
      sx={{ maxWidth: theme.spacing(15) }}
      variant="outlined"
      color="default"
      onClick={onChipClick}
      rightIcon={
        <CloseIcon
          sx={{ fontSize: "14px !important", color: `${theme.palette.grey[500]} !important` }}
        />
      }
      onRightIconClick={() => removeFileWithPreview(attachment?.uuid)}
      leftIcon={getFileIcon(file.contentType)}
      label={file?.fileName}
    />
  );
};
