import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { HousekeepingTableType, HousekeepingTaskTable } from "./housekeeping-task-table";
import { HousekeepingTaskType } from "../../features/tasks/domain/task";
import { useGetLeftoverTasksQueryEnhanced } from "../../graphql-tasks/query/enhanced-queries/get-leftover-tasks-enhanced";
import { useUpdateLeftoverTaskMutationEnhanced } from "../../graphql-tasks/mutations/enhanced-mutations/update-leftover-tasks-enhanced";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { HousekeepingTaskCard } from "./housekeeping-task-card";
import { Card } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { HousekeepingConfirmation } from "./housekeeping-confirmation";
import { generateUpdateTaskRequest } from "../../utils/houskeeping";

interface LeftoverTasksProps {
  propertyId?: string;
}

export const HousekeepingLeftoverTasks: FC<LeftoverTasksProps> = ({ propertyId }) => {
  const isMobile = useIsMobile();
  const { data, isLoading } = useGetLeftoverTasksQueryEnhanced({
    pmsPropertyId: propertyId ?? ""
  });
  const [updateAction] = useUpdateLeftoverTaskMutationEnhanced();
  const { t } = useTranslationWrapper();

  const tasks = useMemo(() => {
    return data?.GetLeftoverTasks ?? [];
  }, [data]);

  const handleUpdateLeftoverTask = useCallback(
    async (values: HousekeepingTaskType) => {
      updateAction({
        pmsPropertyId: propertyId ?? "",
        request: generateUpdateTaskRequest(values),
        taskId: values.id
      });
    },
    [propertyId, updateAction]
  );
  if (tasks.length === 0 && !isLoading) {
    return <HousekeepingConfirmation label={t("labels__all_leftover_tasks_resolved")} />;
  }
  return isMobile ? (
    <Card>
      {tasks?.map((task) => (
        <HousekeepingTaskCard
          housekeepingTableType={HousekeepingTableType.LEFTOVER}
          task={task}
          handleUpdate={handleUpdateLeftoverTask}
        />
      ))}
    </Card>
  ) : (
    <HousekeepingTaskTable
      housekeepingTableType={HousekeepingTableType.LEFTOVER}
      tasks={data?.GetLeftoverTasks ?? []}
      handleUpdate={handleUpdateLeftoverTask}
    />
  );
};
