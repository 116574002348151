import React, { FC, useEffect, useRef, useState } from "react";
import { Button } from "@likemagic-tech/sv-magic-library";
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AutocompleteOption } from "../autocomplete-component";
import { ArrowDropUp } from "@mui/icons-material";

interface SplitButtonProps {
  options: Array<AutocompleteOption>;
  onClick: (selectedId: string) => void;
}

export const SplitButton: FC<SplitButtonProps> = ({ options, onClick }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<AutocompleteOption | undefined>();
  const theme = useTheme();

  useEffect(() => {
    if (options[0]) {
      setSelectedItem(options[0]);
    }
  }, [options]);

  const handleClick = () => {
    if (selectedItem?.id) {
      onClick(selectedItem?.id);
    }
  };

  const handleMenuItemClick = (item: AutocompleteOption) => {
    setSelectedItem(item);
    setOpen(false);
    onClick(item.id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        sx={{ boxShadow: "none" }}
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          variant="secondary"
          size="medium"
          onClick={handleClick}
          sx={{
            borderWidth: "1px",
            height: theme.spacing(4.5),
            backgroundColor: "unset",
            fontWeight: 400
          }}
        >
          {selectedItem?.label}
        </Button>
        <Button
          sx={{
            px: 0,
            borderLeft: "none",
            "&:hover": { borderLeft: "none" },
            borderWidth: "1px",
            height: theme.spacing(4.5),
            backgroundColor: "unset"
          }}
          variant="secondary"
          size="medium"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          pt: 0.5,
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.id}
                      selected={option.id === selectedItem?.id}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
