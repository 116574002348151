import { FC } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import useDynamicLocale from "src/hooks/use-dynamic-locale";

export const LocalizationDatePicker: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const locale = useDynamicLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      {children}
    </LocalizationProvider>
  );
};
