import { GetAdditionalServicesAvailabilityQuery } from "../queries/GetAdditionalServicesAvailability.generated";
import { AdditionalServicesAvailabilityResponse } from "../../domain/additional-services-availability";
import { Granularity as GranularityV2 } from "../generated/graphql";
import { AdditionalServiceWithPrice, Granularity, Service } from "../../domain/service";
import { transformFullPrice } from "./transform-utils";

type AdditionalServiceInputNotNullable = Exclude<
  GetAdditionalServicesAvailabilityQuery["BackofficeGetAdditionalServicesAvailability"],
  null
>;
type AdditionalServiceInput = Exclude<AdditionalServiceInputNotNullable, undefined>;
type AdditionalServiceItem = AdditionalServiceInput[number];

export const transformAdditionalServicesAvailability = (
  param: AdditionalServiceItem
): AdditionalServicesAvailabilityResponse => {
  return {
    available: param?.available ?? false,
    serviceId: param?.pmsServiceId ?? "",
    name: param?.name ?? "",
    granularity: mapGranularityV2toGranularityV1(param?.granularity),
    bookableDates:
      param?.bookableDates?.map((dateString: any) => ({
        date: dateString,
        count: 1
      })) ?? [],
    canBookMultiple: param?.canBookMultiple ?? false,
    bookedDates:
      param?.bookedDates?.map((dateString: any) => ({
        date: dateString,
        count: 1
      })) ?? [],
    maximumHours: 0, //TODO mocked data
    code: param?.magicServiceCodeEnum ?? "",
    price: transformFullPrice(param?.basePrice)
  };
};

const mapGranularityV2toGranularityV1 = (granularity?: GranularityV2 | null): Granularity => {
  switch (granularity) {
    case GranularityV2.AnyDay:
      return Granularity.ANY_DAY;
    case GranularityV2.AnyDayExceptArrivalDay:
      return Granularity.ANY_DAY_EXCEPT_ARRIVAL_DAY;
    case GranularityV2.WholeStay:
      return Granularity.WHOLE_STAY;
    case GranularityV2.WholeStayPayOnce:
      return Granularity.WHOLE_STAY_PAY_ONCE;
    case GranularityV2.ArrivalDay:
      return Granularity.ARRIVAL_DAY;
    case GranularityV2.DepartureDay:
      return Granularity.DEPARTURE_DAY;
    case GranularityV2.EarlyCheckin:
      return Granularity.EARLY_CHECKIN;
    case GranularityV2.LateCheckout:
      return Granularity.LATE_CHECKOUT;
    default:
      return Granularity.UNKNOWN;
  }
};

export const transformAdditionalServiceToService = (
  service: AdditionalServiceWithPrice
): Service => ({
  price: service.price,
  code: service.code,
  name: service.name,
  quantity: service.quantity,
  serviceId: service.serviceId,
  createdAt: "",
  updatedAt: ""
});
