import React, { FC, ReactNode, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useProperty } from "../../hooks/use-property";
import { KeysSpeedDial } from "../KeysSpeedDial";
import { Box, Collapse as MuiCollapse, CSSObject, styled, Theme } from "@mui/material";
import { useBanner } from "../../slices/use-banner";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useSelector } from "react-redux";
import { selectIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { ActionNotification } from "../actionNotification";
import { TaskModal } from "src/features/tasks/task-modal/task-modal";
import { ReservationDetails } from "src/features/reservations/reservation-details/reservation-details";
import { GlobalSubscribe } from "../../features/global-subscribe/global-subscribe";
import { ManualCheckin } from "../../features/reservations/manual-checkin/manual-checkin";
import { ConversationDetails } from "../../messaging/conversation-details";
import { ProfileDrawer } from "../../features/profiles/profile-drawer/profile-drawer";
import { HOST_ROLE, HOUSEKEEPER_ROLE, useAuth } from "@likemagic-tech/sv-magic-library";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const Collapse = styled(MuiCollapse)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  maxWidth: "500px",
  position: "absolute",
  bottom: "16px",
  right: "16px"
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: theme.spacing(8)
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch"
});

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}));

export const drawerWidth = 305;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(12)} + 1px)`
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: `${theme.palette.primary.main} !important`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const notAllowedRoutesForSpeedDial = ["/messaging", "/tasks", "/profiles"];

export const DashboardLayoutTemplate: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { selectedPropertyIds } = useProperty();
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { bannerState, onClose } = useBanner();
  const { pathname } = useLocation();
  const { hasRole, hasAdminOrOperatorRole } = useAuth();

  const isAllowedRoute = useMemo(
    () =>
      !notAllowedRoutesForSpeedDial.includes(pathname) &&
      (hasAdminOrOperatorRole() || hasRole(HOST_ROLE) || hasRole(HOUSEKEEPER_ROLE)),
    [pathname, hasAdminOrOperatorRole, hasRole]
  );
  return (
    <DashboardLayoutRoot>
      <AppBar open={openSideNavigation}>
        <DashboardNavbar />
      </AppBar>
      <Collapse in={bannerState.isOpen} sx={{ zIndex: 1301 }}>
        <Box>
          <ActionNotification
            onClick={onClose}
            type={bannerState.type}
            title={bannerState.title}
            errorId={bannerState.errorId}
          />
        </Box>
      </Collapse>
      <DashboardSidebar />
      <TaskModal />
      <ReservationDetails />
      <ProfileDrawer />
      <ManualCheckin />
      <ConversationDetails />
      <GlobalSubscribe />
      {selectedPropertyIds.length === 1 && isAllowedRoute && (
        <KeysSpeedDial propertyId={selectedPropertyIds[0] ?? ""} />
      )}
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{children}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};
export const DashboardLayout: FC<React.PropsWithChildren<DashboardLayoutProps>> = () => {
  return (
    <DashboardLayoutTemplate>
      <Outlet />
    </DashboardLayoutTemplate>
  );
};
