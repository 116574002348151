import { FC, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, ButtonBase, Tooltip, useTheme } from "@mui/material";
import { Caption } from "@likemagic-tech/sv-magic-library";
import { responsiveVariables } from "../../../../hooks/use-is-mobile";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  AuthorPreview,
  mapReservationCheckInOutAuthorToAuthorPreview
} from "./monitoring-step.util";
import { ReservationCheckInOutAuthor } from "../../../../graphql/generated/graphql";

const useStyles = makeStyles<{ author?: AuthorPreview; isParent?: boolean; isLast?: boolean }>()(
  (theme, { author, isParent, isLast }) => ({
    stepWrapper: {
      display: "flex",
      height: theme.spacing(5),
      marginLeft: "auto",
      marginRight: theme.spacing(isLast ? 7 : 2)
    },
    passedStep: {
      backgroundColor: theme.palette.grey[100]
    },
    wrapper: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      position: "relative"
    },
    parentButton: {
      width: theme.spacing(1),
      height: theme.spacing(5),
      backgroundColor: theme.palette.grey[300],
      zIndex: 1
    },
    childButton: {
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
      backgroundColor: theme.palette.grey[300],
      borderRadius: "50%",
      zIndex: 1
    },
    emptySpace: {
      [theme.breakpoints.up(responsiveVariables.firstDesktopSize)]: {
        marginRight: theme.spacing(20)
      },
      marginRight: theme.spacing(8)
    },
    parentLabel: {
      position: "absolute",
      bottom: "-60%",
      left: 0,
      textWrap: "nowrap"
    },
    currentChildWrapper: {
      height: "100%",
      width: "100%",
      position: "absolute",
      left: isParent ? theme.spacing(0.375) : theme.spacing(0.75),
      display: "flex",
      alignItems: "flex-end"
    },
    currentChild: {
      width: theme.spacing(0.25),
      height: "120%",
      backgroundColor: theme.palette.info.main
    },
    currentLabel: {
      position: "absolute",
      top: "-50%",
      left: theme.spacing(-1),
      textWrap: "nowrap",
      color: theme.palette.info.main,
      fontSize: "8px"
    },
    currentWithLabelWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      pointerEvents: "none"
    },
    childBackgroundHelperWithBg: {
      width: "8px",
      height: "100%",
      background: theme.palette.grey[100],
      paddingTop: theme.spacing(0.75)
    },
    colouredItem: {
      backgroundColor: (() => {
        switch (author) {
          case AuthorPreview.PROPERTY:
            return theme.palette.warning.main;
          case AuthorPreview.EXTERNAL:
            return theme.palette.grey[600];
          default:
            return theme.palette.info.main;
        }
      })()
    }
  })
);

export interface MonitoringStepProps {
  id: string;
  isParent?: boolean;
  author?: ReservationCheckInOutAuthor;
  hasEmptySpaceAfter?: boolean;
  tooltip: {
    text: string;
    dateTime?: string;
  };
  isPreStepDone?: boolean;
  isCurrent?: boolean;
  isPassed: boolean;
  isLast?: boolean;
}

export const MonitoringStep: FC<MonitoringStepProps> = ({
  id,
  tooltip,
  isParent,
  hasEmptySpaceAfter,
  author,
  isPreStepDone,
  isCurrent,
  isPassed,
  isLast
}) => {
  const { classes } = useStyles({
    author: mapReservationCheckInOutAuthorToAuthorPreview(author),
    isParent,
    isLast
  });
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const tooltipContent = useMemo(() => {
    return (
      <Box gap={2}>
        <Box display="flex" justifyContent="space-between">
          <Caption>{t(`labels__step_data_${id}`)}</Caption>
          {tooltip.dateTime && (isPreStepDone || isCurrent || (isParent && isPassed)) && (
            <Caption sx={{ color: theme.palette.grey[500], marginLeft: theme.spacing(2) }}>
              {t(tooltip.dateTime)}
            </Caption>
          )}
        </Box>
        {isPreStepDone || isCurrent || (isParent && isPassed) ? (
          <Caption sx={{ color: theme.palette.grey[500], lineHeight: "16px" }}>
            {tooltip.text}
          </Caption>
        ) : (
          <Caption sx={{ color: theme.palette.grey[500], lineHeight: "16px" }}>
            {t("labels__step_is_not_done_yet")}
          </Caption>
        )}
      </Box>
    );
  }, [theme, t, id, tooltip.dateTime, tooltip.text, isPreStepDone, isCurrent, isParent, isPassed]);

  return (
    <Box className={`${isPassed ? classes.passedStep : ""}`} sx={{ width: "100%" }}>
      <Box className={`${classes.stepWrapper} ${hasEmptySpaceAfter ? classes.emptySpace : ""}`}>
        <Box className={`${classes.wrapper}`}>
          <Tooltip
            title={tooltipContent}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: theme.palette.common.white,
                  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.07)",
                  "& .MuiTooltip-arrow": {
                    color: theme.palette.common.white
                  },
                  lineHeight: "16px"
                }
              }
            }}
          >
            <div className={isCurrent && !isParent ? classes.childBackgroundHelperWithBg : ""}>
              <ButtonBase
                className={`${isParent ? classes.parentButton : classes.childButton} ${
                  isCurrent || isPreStepDone || (isParent && isPassed) ? classes.colouredItem : ""
                }`}
                sx={{ height: "100%" }}
              />
            </div>
          </Tooltip>
          {isCurrent && (
            <Box className={classes.currentWithLabelWrapper}>
              <Caption className={classes.currentLabel}>{t("labels__current")}</Caption>
              <Box className={classes.currentChildWrapper}>
                <ButtonBase className={classes.currentChild} />
              </Box>
            </Box>
          )}
          {isParent && (
            <Caption className={classes.parentLabel}>{t(`labels__step_data_${id}`)}</Caption>
          )}
        </Box>
      </Box>
    </Box>
  );
};
