import React, { FC, ReactElement, useRef, useState } from "react";
import {
  ClickAwayListener,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTheme } from "@mui/material/styles";
import { generateUUID } from "../../utils/data.utils";

interface ActionMenuProps {
  items: Array<{ id: string; label: string; icon?: ReactElement }>;
  disabled?: boolean;
  handleAction: (itemId: string) => void;
  iconVertical?: boolean;
}
export const ActionMenu: FC<ActionMenuProps> = ({
  items,
  disabled,
  handleAction,
  iconVertical
}) => {
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const chargesActionsAnchorRef = useRef<SVGSVGElement>(null);
  const { height } = useWindowDimensions();
  const { spacing } = useTheme();

  return (
    <span>
      <IconButton
        data-stop-propagation
        sx={{ px: 0, ml: 1, transform: iconVertical ? "" : "rotate(90deg)" }}
        disableRipple
        disabled={disabled}
        onClick={() => {
          setActionsMenuOpen(true);
        }}
      >
        <MoreVert data-stop-propagation ref={chargesActionsAnchorRef} />
      </IconButton>
      <Popper
        open={actionsMenuOpen}
        anchorEl={chargesActionsAnchorRef?.current}
        style={{ zIndex: 9999 }}
        placement="bottom"
      >
        <Paper
          sx={{
            maxHeight: `calc(${height}px - ${spacing(20)})`,
            overflowY: "auto",
            minWidth: 120
          }}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => setActionsMenuOpen(false)}
          >
            <MenuList id={`menu--button-menu-${generateUUID()}`}>
              {items?.map((menuItem, index) => (
                <div key={menuItem.id}>
                  {index && menuItem.icon ? <Divider /> : null}
                  <MuiMenuItem
                    onClick={() => {
                      handleAction(menuItem.id);
                      setActionsMenuOpen(false);
                    }}
                    disabled={!!menuItem.icon}
                  >
                    {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                    <ListItemText data-stop-propagation> {menuItem.label}</ListItemText>
                  </MuiMenuItem>
                </div>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </span>
  );
};
