import { Box, Grid } from "@mui/material";
import { Heading2, ParagraphSmall, TenantContext } from "@likemagic-tech/sv-magic-library";
import { FC, useCallback, useContext, useMemo } from "react";
import { getUnitType } from "../../../utils/tenant-unit-type";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { AssignmentIndOutlined, EscalatorWarningOutlined, Man } from "@mui/icons-material";
import { HousekeepingCardUnitStatus } from "./housekeeping-card-unit-status";
import { TaskDto, TaskType } from "../../../graphql-tasks/generated/graphql";
import { HousekeepingCardsTypeEnum } from "../housekeeping-cards-floor";
import { useUnitGroupById } from "../../../hooks/use-unit-group-by-id";
import { HousekeepingCardType } from "./housekeeping-card";

interface HousekeepingCardDetailsProps {
  housekeepingCard: HousekeepingCardType;
  propertyId: string;
  housekeepingCardsType: HousekeepingCardsTypeEnum;
}

export const HousekeepingCardDetails: FC<HousekeepingCardDetailsProps> = ({
  housekeepingCard,
  propertyId,
  housekeepingCardsType
}) => {
  const { t } = useTranslationWrapper();
  const { theme: selectedTheme } = useContext(TenantContext);
  const displayUnitName = useMemo(
    () =>
      housekeepingCard?.unit
        ? `${getUnitType(selectedTheme)} ${housekeepingCard?.unit?.name || ""}`
        : t("labels__unit_not_assigned"),
    [selectedTheme, housekeepingCard, t]
  );
  const unitGroup = useUnitGroupById(
    propertyId,
    housekeepingCard?.unit?.pmsUnitGroupId ??
      housekeepingCard?.reservation?.pmsUnitGroupId ??
      undefined
  );

  const task = useMemo(() => {
    switch (housekeepingCardsType) {
      case HousekeepingCardsTypeEnum.UNITS:
        return housekeepingCard?.unit?.housekeepingTasks?.find(
          (task) =>
            task.type === TaskType.DepartureCleaning || task.type === TaskType.StayoverCleaning
        );
      case HousekeepingCardsTypeEnum.RESERVATIONS:
        return housekeepingCard?.reservation?.housekeepingTasks?.find(
          (task) =>
            task.type === TaskType.DepartureCleaning || task.type === TaskType.StayoverCleaning
        );
      default:
        return undefined;
    }
  }, [housekeepingCard?.unit, housekeepingCard?.reservation, housekeepingCardsType]);

  const guestAmountInformation = useCallback((task: TaskDto | undefined) => {
    switch (task?.type) {
      case TaskType.StayoverCleaning:
        return {
          adultsAmount: task?.links?.reservations?.[0].adultsAmount,
          childrenAmount: task?.links?.reservations?.[0].childrenAmount
        };
      case TaskType.DepartureCleaning:
        return {
          adultsAmount: task?.links?.arrivingReservation?.adultsAmount,
          childrenAmount: task?.links?.arrivingReservation?.childrenAmount
        };
      default:
    }
  }, []);

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>{housekeepingCard && <ParagraphSmall>{unitGroup.name}</ParagraphSmall>}</Grid>
        <Grid item display="flex">
          <AssignmentIndOutlined fontSize="small" />
          <ParagraphSmall pl={1}>{t("labels__housekeeping")}</ParagraphSmall>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Heading2 color="textPrimary">{displayUnitName}</Heading2>
        <HousekeepingCardUnitStatus housekeepingCard={housekeepingCard} />
      </Grid>
      <Grid container justifyContent="space-between">
        <>
          <Grid item xs={6}>
            {housekeepingCard?.unit?.floor && (
              <ParagraphSmall>
                {t("labels__floor")} {housekeepingCard?.unit?.floor}
              </ParagraphSmall>
            )}
          </Grid>
          {guestAmountInformation(task)?.adultsAmount && (
            <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
              {guestAmountInformation(task)?.childrenAmount ? (
                <EscalatorWarningOutlined />
              ) : (
                <Man />
              )}
              <ParagraphSmall>
                {guestAmountInformation(task)?.adultsAmount}
                <span />
                {!!guestAmountInformation(task)?.childrenAmount &&
                  `+ ${guestAmountInformation(task)?.adultsAmount}`}
              </ParagraphSmall>
            </Grid>
          )}
        </>
      </Grid>
    </Box>
  );
};
