import { magicApi } from "../magic.api";
import { Unit } from "../../domain/Unit";
import { MaintenanceSlotType } from "src/domain/maintenance-slot-type";

export interface MaintenanceSlotArgs {
  id?: string;
  unitIds?: string[];
  from: Date | string;
  to: Date | string;
  type: MaintenanceSlotType;
  description?: string;
}

export const unitEndpoints = magicApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query<Array<Unit>, { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: "/units/" + propertyId,
        headers: { method: "GET" }
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Units" as const,
                id
              }))
            ]
          : ["Units"]
    }),
    addMaintenance: builder.mutation({
      query: (arg: MaintenanceSlotArgs) => ({
        url: "/maintenance-slots/add",
        headers: { method: "POST" },
        body: arg,
        isVoid: true
      }),
      invalidatesTags: (result, error, arg) =>
        arg?.unitIds ? arg?.unitIds?.map((unitId) => ({ id: unitId, type: "Units" })) : ["Units"]
    }),
    updateMaintenance: builder.mutation({
      query: (arg: MaintenanceSlotArgs) => ({
        url: "/maintenance-slots/",
        headers: { method: "PATCH" },
        body: arg,
        isVoid: true
      }),
      invalidatesTags: (result, error, arg) =>
        arg?.unitIds ? arg?.unitIds?.map((unitId) => ({ id: unitId, type: "Units" })) : ["Units"]
    }),
    deleteMaintenance: builder.mutation({
      query: (maincenanceSlotId: string) => ({
        url: "/maintenance-slots/" + maincenanceSlotId,
        headers: { method: "DELETE" },
        isVoid: true
      }),
      invalidatesTags: () => ["Units"]
    })
  }),
  overrideExisting: false
});
export const {
  useGetUnitsQuery: useGetUnitsQueryV1,
  useAddMaintenanceMutation: useAddMaintenanceMutationV1,
  useUpdateMaintenanceMutation: useUpdateMaintenanceMutationv1,
  useDeleteMaintenanceMutation: useDeleteMaintenanceMutationv1
} = unitEndpoints;
