import React, { FC } from "react";
import { AvailableDoor } from "../../domain/available-door";
import { Button, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

export const OfflinePinButton: FC<React.PropsWithChildren<{ doorData: AvailableDoor }>> = ({
  doorData
}) => {
  const { t } = useTranslationWrapper();

  return (
    <Button fullWidth disabled>
      <Paragraph color="white">{`${t("labels__keys_pin")}: ${doorData.pin ?? ""}`}</Paragraph>
    </Button>
  );
};
