import * as Types from "../generated/graphql";

import { HousekeepingTaskFragmentDoc } from "../fragment/HousekeepingTaskFragment.generated";
import { TaskFragmentDoc } from "../fragment/TaskFragment.generated";
import { ActorFragmentDoc } from "../fragment/Actor.generated";
import { tasksApi } from "../tasks.api";
export type GetLeftoverTasksQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
}>;

export type GetLeftoverTasksQuery = {
  __typename?: "Query";
  GetLeftoverTasks: Array<{
    __typename?: "TaskDto";
    type: Types.TaskType;
    id: number;
    title: string;
    description?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    due?: any | null;
    status: Types.Status;
    priority: Types.Priority;
    parentId?: number | null;
    attachments?: Array<{
      __typename?: "Attachment";
      uuid: string;
      contentType: string;
      fileName: string;
      originalFileName: string;
    }> | null;
    assignee?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: Types.ActorType;
      displayName?: string | null;
    } | null;
    assigneeGroup?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: Types.ActorType;
      displayName?: string | null;
    } | null;
    reporter: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: Types.ActorType;
      displayName?: string | null;
    };
    links?: {
      __typename?: "Links";
      pmsReservationIds?: Array<string> | null;
      pmsDepartingReservationId?: string | null;
      pmsArrivingReservationId?: string | null;
      pmsUnitIds?: Array<string> | null;
      reservations?: Array<{
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
      }> | null;
      departingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
      } | null;
      arrivingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
      } | null;
      units?: Array<{
        __typename?: "Unit";
        pmsUnitId: string;
        name: string;
        floor?: string | null;
      }> | null;
    } | null;
    subtasks?: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      parentId?: number | null;
    }> | null;
    parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
  }>;
};

export const GetLeftoverTasksDocument = `
    query GetLeftoverTasks($pmsPropertyId: String!) {
  GetLeftoverTasks(pmsPropertyId: $pmsPropertyId) {
    ...HousekeepingTask
  }
}
    ${HousekeepingTaskFragmentDoc}
${TaskFragmentDoc}
${ActorFragmentDoc}`;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    GetLeftoverTasks: build.query<GetLeftoverTasksQuery, GetLeftoverTasksQueryVariables>({
      query: (variables) => ({ document: GetLeftoverTasksDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetLeftoverTasksQuery, useLazyGetLeftoverTasksQuery } = injectedRtkApi;
