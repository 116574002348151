import * as React from "react";
import { FC } from "react";
import { InputAdornment, OutlinedInput, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";

interface InputWithIconProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}

export const InputWithIcon: FC<React.PropsWithChildren<InputWithIconProps>> = ({
  value,
  onChange,
  placeholder,
  disabled
}) => {
  const theme = useTheme();

  return (
    <OutlinedInput
      sx={{ height: "32px", borderRadius: theme.spacing(0.75) }}
      disabled={disabled}
      autoFocus
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
  );
};
