import { UnitCondition } from "../../domain/Unit";
import type { FC } from "react";
import { Grid } from "@mui/material";
import { filterableStatuses } from "./utils";
import { ChipButton } from "../../components/chip/ChipButton";
import { unitConditionVariantMap } from "./UnitCard";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useMemo } from "react";

interface UnitConditionFilterProps {
  value?: UnitCondition;
  onConditionSelected: (condition: UnitCondition) => void;
}

export const UnitConditionFilter: FC<React.PropsWithChildren<UnitConditionFilterProps>> = ({
  value,
  onConditionSelected
}) => {
  const { t } = useTranslationWrapper();

  const labelMap = useMemo(() => {
    return {
      [UnitCondition.CLEAN]: t("labels__unit_condition_Clean"),
      [UnitCondition.CLEAN_TO_BE_INSPECTED]: t("labels__unit_condition_CleanToBeInspected"),
      [UnitCondition.DIRTY]: t("labels__unit_condition_Dirty")
    };
  }, [t]);

  const isSelected = (unitCondition: UnitCondition) => value === unitCondition;
  return (
    <Grid container spacing={1}>
      {filterableStatuses.map((unitCondition) => (
        <Grid item key={unitCondition}>
          {unitConditionVariantMap[unitCondition as keyof typeof unitConditionVariantMap] ? (
            <ChipButton
              onClick={() => onConditionSelected(unitCondition)}
              status={
                unitConditionVariantMap[unitCondition as keyof typeof unitConditionVariantMap]
              }
              outlined={isSelected(unitCondition) !== undefined && !isSelected(unitCondition)}
              label={labelMap[unitCondition as keyof typeof labelMap]}
              fullWidth
            />
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
};
