import { FC } from "react";
import { TransactionsForProfile } from "../../types/profile";
import { TableCell, TableRow } from "@mui/material";
import { formatDateTime, Paragraph } from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "src/components/price-preview/price-preview";

interface ProfileDrawerTransactionTableRowProps {
  item: TransactionsForProfile;
}

export const ProfileDrawerTransactionTableRow: FC<ProfileDrawerTransactionTableRowProps> = ({
  item
}) => {
  return (
    <TableRow hover key={item.pmsId}>
      <TableCell />
      <TableCell>
        <Paragraph>{item.pmsReservationId}</Paragraph>
      </TableCell>
      <TableCell>
        <Paragraph>{item.name}</Paragraph>
      </TableCell>
      <TableCell>
        <Paragraph>{formatDateTime(item.start)}</Paragraph>
      </TableCell>
      <TableCell>
        <PricePreview
          price={{ amount: item.price.grossPriceInCents ?? 0, currency: item.price.currency ?? "" }}
        />
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
