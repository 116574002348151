import { AppBar, AppBarProps, Box, IconButton, styled, Toolbar, Tooltip } from "@mui/material";
import { FC, useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../logo/Logo";
import AccountPopover from "./AccountPopover";
import { ReleaseNotePopover } from "./ReleaseNotePopover";
import { SupportPopover } from "./SupportPopover";
import MenuIcon from "@mui/icons-material/Menu";
import { selectIsOpenSideNavigation, setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useDispatch, useSelector } from "src/store";
import CloseIcon from "@mui/icons-material/Close";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useUserInfo } from "src/hooks/use-user-info";
import { TenantContext, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { LanguageSelectorComponent } from "../language-selector/language-selector";
import { TfeLogo } from "../logo/tfe-logo";

interface DashboardNavbarProps extends AppBarProps {}

export const minimumNavbarHeight = 64;

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none"
  }),
  zIndex: theme.zIndex.drawer + 100,
  backgroundColor: `${theme.palette.primary.main} !important`
}));

const DashboardNavbar: FC<React.PropsWithChildren<DashboardNavbarProps>> = (props) => {
  const { t } = useTranslationWrapper();
  const { ...other } = props;
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const setOpenSideNavigation = (openSidebar: boolean) => {
    dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
  };
  const { userInfo } = useUserInfo();
  const { tenantId } = useContext(TenantContext);
  const { features } = usePropertyConfig({});
  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: minimumNavbarHeight }}>
        {!isMobile ? (
          <RouterLink to="/">
            {!features?.tfeFeatureEnabled ? <Logo isWhite /> : <TfeLogo />}
          </RouterLink>
        ) : openSideNavigation ? (
          <CloseIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={() => setOpenSideNavigation(false)}
          />
        ) : (
          <MenuIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={() => setOpenSideNavigation(true)}
          />
        )}
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box>
          <LanguageSelectorComponent />
        </Box>
        {/* TODO SHORTCUT https://likemagic.atlassian.net/browse/LM-762 */}
        {(("schani" === tenantId &&
          (userInfo?.email === "vk.weikartschlaeger@schanihotels.com" ||
            userInfo?.email === "c.mueller@schanihotels.com")) ||
          "schani" !== tenantId) && (
          <Box sx={{ ml: 1 }}>
            <Tooltip
              title={t("labels__create_support_ticket")}
              arrow
              open={openSupport}
              disableHoverListener
              onMouseEnter={() => setOpenSupport(true)}
              onMouseLeave={() => setOpenSupport(false)}
            >
              <IconButton color="inherit" onClick={() => setOpenSupport(false)}>
                <SupportPopover />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box sx={{ ml: 2 }}>
          <Tooltip
            title={openTooltip ? t("labels__release_notes") : ""}
            arrow
            open={openTooltip}
            disableHoverListener={openTooltip}
            onMouseEnter={() => setOpenTooltip(true)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <IconButton color="inherit" onClick={() => setOpenTooltip(false)}>
              <ReleaseNotePopover />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
