import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { EnhancedTableHead, HeadCell } from "../../components/table/enhanced-table-head";
import {
  appliableFilters,
  FilterMapInterface,
  FilterObjectType
} from "../../components/table/filters/table-filters";
import { ReservationTableRow } from "./reservation-table-row";
import { useProperty } from "../../hooks/use-property";
import { Order } from "../../types/pageable";
import { ReservationTableDto } from "../../domain/reservation-table-dto";
import { ReservationToolbar } from "./reservation-toolbar";
import { useDispatch } from "../../store";
import { downloadReservationXLSX } from "../../slices/download.slice";
import { useDebounce } from "../../hooks/use-debounce";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Params, useSelectId } from "../../hooks/use-select-id";
import { Grid } from "@mui/material";
import { ReservationEvents } from "./reservation-events";
import { useIsMobile } from "src/hooks/use-is-mobile";
import PageTitle from "src/components/PageTitle";
import { useReservationTableOverview } from "src/hooks/use-reservation-table-overview";
import { FilterObject, FilterOperator } from "src/types/filters/filters";
import { useReservationConditions } from "src/hooks/use-reservation-conditions";
import { useSearchFilterParams } from "src/components/table/hooks/use-search-filter-params";

// Max value to disable the export feature
const MAX_EXPORT = 500;

const headCells: HeadCell<ReservationTableDto>[] = [
  {
    id: "firstName",
    label: "labels__fullName"
  },
  {
    id: "notes",
    label: "labels__notes",
    disableSort: true
  },
  {
    id: "lastConfirmedPage",
    label: "labels__gj_progress",
    disableSort: true
  },
  {
    id: "arrival",
    label: "labels__arrival"
  },
  {
    id: "departure",
    label: "labels__departure"
  },
  {
    id: "unit",
    label: "labels__unit"
  },
  {
    id: "reservationCondition",
    label: "labels__condition"
  }
];

interface ReservationTableProps {
  defaultFilter: {
    [x: string]: {
      value: string;
      name: string;
      type: FilterObjectType;
      operator: FilterOperator;
    };
  };
}

export const ReservationTable: FC<ReservationTableProps> = ({ defaultFilter }) => {
  const { t } = useTranslationWrapper();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof ReservationTableDto>("reservationCondition");
  const isMobile = useIsMobile();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const { selectedProperty, selectedPropertiesPreview } = useProperty();

  const { filterMap, setFilterMap } = useSearchFilterParams();

  const filterParams: FilterObject[] = useMemo(() => {
    const filtersToBeApplied = appliableFilters(filterMap);
    return Object.keys(filtersToBeApplied).map((key) => filtersToBeApplied[key]);
  }, [filterMap]);

  const debouncedFilterParams = useDebounce(filterParams, 800);
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.SELECTED_RESERVATION_ID
  });

  const data = useReservationTableOverview(
    selectedProperty?.propertyId ?? "",
    orderBy,
    order,
    page,
    rowsPerPage,
    debouncedFilterParams
  );

  const dataConditions = useReservationConditions({
    propertyId: selectedProperty?.propertyId,
    filterParams: debouncedFilterParams
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    orderBy: keyof ReservationTableDto
  ) => {
    setOrder(order === "desc" ? "asc" : "desc");
    setOrderBy(orderBy);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFiltersChange = useCallback(
    (filters: FilterMapInterface) => {
      //Always show first page after new filters are applied
      setPage(0);
      setFilterMap(filters);
    },
    [setFilterMap, setPage]
  );
  const rows = useMemo(() => (data?.content ? data?.content : []), [data?.content]);
  const dispatch = useDispatch();
  const onExport = () => {
    dispatch(
      downloadReservationXLSX({
        propertyId: selectedProperty?.propertyId ?? "",
        sortingRules: [{ id: orderBy, order }],
        filterParams
      })
    );
  };

  const isExportDisabled = React.useMemo(() => {
    const totalEntries = data?.totalElements ?? 0;
    return totalEntries > MAX_EXPORT;
  }, [data?.totalElements]);

  const onRowSelect = (reservationId: string) => {
    setSelectId(
      reservationId,
      new URLSearchParams({
        [Params.TASK_LINK_TYPE_URL_PARAM]: "",
        [Params.TASK_LINK_ID_URL_PARAM]: "",
        [Params.TASK_ID_SEARCH_PARAM_NAME]: "",
        [Params.CREATE_TASK_PARAM]: "",
        [Params.MANUAL_CHECKIN_STEP]: "",
        [Params.PROFILE_ID_SEARCH_PARAM_NAME]: "",
        [Params.SELECTED_MANUAL_CHECKIN_RESERVATION_ID]: ""
      })
    );
  };

  const removeFilter = useCallback(
    (filterKeys: Array<string>, isReset?: boolean) => {
      if (isReset) {
        onFiltersChange(defaultFilter);
      } else {
        const copyOfFilterMap = { ...filterMap };

        filterKeys.forEach((filterKey) => {
          if (filterMap[filterKey]) {
            delete copyOfFilterMap[filterKey];
          }
        });
        onFiltersChange(copyOfFilterMap);
      }
    },
    [filterMap, onFiltersChange, defaultFilter]
  );

  const reservationIds = useMemo(() => rows?.map((item) => item.id), [rows]);

  return (
    <Box sx={{ width: "100%" }} mb={8}>
      <ReservationEvents reservationIds={reservationIds} />

      <Paper sx={{ width: "100%", mb: 2, borderRadius: 2 }} elevation={0}>
        {isMobile && (
          <Grid item p={2.5} xs={6}>
            <PageTitle subTitle={selectedPropertiesPreview} title={t("title__reservations")} />
          </Grid>
        )}
        <Grid container direction={isMobile ? "column-reverse" : "column"}>
          <ReservationToolbar
            onExport={onExport}
            reservationConditionNumbers={dataConditions}
            disabled={isExportDisabled}
            tooltipText={t("labels__reservation_disabled_export", {
              MAX_EXPORT: MAX_EXPORT.toString()
            })}
            filterMap={filterMap}
            onFilterMapChange={onFiltersChange}
            removeFilter={removeFilter}
          />
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead<ReservationTableDto>
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row) => (
                <ReservationTableRow
                  key={`row-${row.magicId}`}
                  data={row}
                  onSelect={onRowSelect}
                  selectedReservationId={selectedId ?? ""}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data?.totalElements ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t("labels__rows_per_page")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("labels__of")} ${count}`}
        />
      </Paper>
    </Box>
  );
};
