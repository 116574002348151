export enum ReservationActionLabels {
  GUEST_FLOW_COMPLETED = "labels__card_guest_flow_completed",
  GUEST_FLOW_NOT_COMPLETED = "labels__card_guest_flow_not_completed",
  ID_CHECK_CONFIRMED = "labels__card_identification_confirmed",
  ID_CHECK_UNCONFIRMED = "labels__card_identification_not_confirmed",
  ID_CHECK_DECLINED = "labels__card_identification_declined",
  UNIT = "labels__card_unit",
  UNIT_READY = "labels__card_unit_ready",
  UNIT_TO_BE_INSPECTED = "labels__card_unit_to_be_inspected",
  UNIT_DIRTY = "labels__card_unit_dirty",
  UNIT_PICKUP = "labels__card_unit_pickup",
  UNIT_DIRTY_AND_OCCUPIED = "labels__card_unit_dirty_and_occupied",
  UNIT_NOT_ASSIGNED = "labels__card_unit_not_assigned",
  UNIT_DOOR_AVAILABLE = "labels__card_unit_door_available",
  UNIT_DOOR_NOT_AVAILABLE = "labels__card_unit_door_not_available",
  PUBLIC_DOOR_AVAILABLE = "labels__card_public_door_available",
  PUBLIC_DOOR_NOT_AVAILABLE = "labels__card_public_door_not_available"
}
