import React, { FC } from "react";
import ActionDropdown from "src/components/action-dropdown/action-dropdown";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { UnitActionType } from "../dirty-units/dirty-units-actions";

interface UnitsActionsProps {
  onClick: (actionType: UnitActionType) => void;
}

export const UnitsActions: FC<React.PropsWithChildren<UnitsActionsProps>> = ({ onClick }) => {
  const { t } = useTranslationWrapper();

  return (
    <ActionDropdown
      handleOption={() => onClick(UnitActionType.MAINTENANCE)}
      options={[
        {
          id: UnitActionType.MAINTENANCE,
          label: t("labels__add_maintenance")
        }
      ]}
    >
      {t("buttons__actions")}
    </ActionDropdown>
  );
};
