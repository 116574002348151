import React from "react";
import { formatPriceToString } from "../../utils/price";
import { Price } from "../../domain/price";

interface PricePreviewProps {
  price: Price;
  additionalInfo?: string;
}

export const PricePreview: React.FC<React.PropsWithChildren<PricePreviewProps>> = ({
  price,
  additionalInfo
}) => {
  const formattedPrice = formatPriceToString(price);
  return <span>{additionalInfo ? formattedPrice + ` / ${additionalInfo}` : formattedPrice}</span>;
};
