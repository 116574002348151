import { Button, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  OutlinedInput
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import { ReservationActionsModal } from "./reservation-actions-modal";
import * as React from "react";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";

interface ChangePinModalProps extends ReservationActionsModal {}

export const ChangePinModal: FC<React.PropsWithChildren<ChangePinModalProps>> = ({
  reservation,
  isOpen,
  onClose
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const [pin, setPin] = useState<string>();
  const action = AllowedActionEnum.CHANGE_PIN_CONFIGURATION;
  const dispatch = useDispatch();

  const [performAction, result] = usePerformReservationAction();

  const submit = useCallback(() => {
    performAction({
      reservationId: reservation.id,
      action,
      payload: { pin }
    });
  }, [performAction, reservation.id, action, pin]);

  const handleClose = useCallback(() => {
    setPin(undefined);
    onClose();
  }, [onClose, setPin]);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();
      handleClose();
    }
  }, [result, dispatch, t, handleClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }
      }}
    >
      <Grid container p={3} flexDirection="column">
        <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
          <Grid container direction="row">
            <Grid item xs={11}>
              <Heading2 sx={{ color: theme.palette.text.primary }}>
                {t("labels__reservation_action_modal_title_CHANGE_PIN_CONFIGURATION")}
              </Heading2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="end">
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Box pt={3}>
            <OutlinedInput
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder={t(
                "labels__reservation_action_modal_placeholder_CHANGE_PIN_CONFIGURATION"
              )}
              sx={{ fontSize: "16px" }}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
          <Button
            variant="primary"
            onClick={() => submit()}
            fullWidth
            sx={{ marginTop: theme.spacing(5) }}
          >
            <Paragraph
              sx={{
                color: theme.palette.background.paper,
                textTransform: "none"
              }}
            >
              {t("labels__reservation_action_modal_button_CHANGE_PIN_CONFIGURATION")}
            </Paragraph>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
