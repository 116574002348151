import { FC, useCallback, useEffect } from "react";
import { Box, Fade, Grid, useTheme } from "@mui/material";
import { useDispatch } from "../store";
import { useIsMobile } from "../hooks/use-is-mobile";
import { setIsOpenSideNavigation } from "../slices/dashboard-sidebar";
import { DrawerDetails } from "../components/drawer-details/drawer-details";
import { Chat } from "./components/chat";
import { ChatPreview } from "./components/chat-preview";
import { useSubscribeToMessages } from "./hooks/use-subscribe-to-messages";
import { useGetConversationDetailsQueryEnhanced } from "../graphql-messaging/queries/enhanced-queries/get-conversation-details-enhanced";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useConversationSelectId } from "./use-conversation-select-id";
import { useProperty } from "../hooks/use-property";

export const ConversationDetails: FC = () => {
  const { selectedConversationId, closeDrawer } = useConversationSelectId();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const showChatPreview = useMediaQuery(theme.breakpoints.down("lg"));
  const { selectedPropertyIds } = useProperty();

  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  const { data } = useGetConversationDetailsQueryEnhanced(
    {
      filter: {
        conversationId: selectedConversationId,
        pageable: { pageNumber: 0, pageSize: 1, sort: "createdAt,asc" }
      }
    },
    {
      skip: !selectedConversationId,
      refetchOnMountOrArgChange: true
    }
  );
  const conversation = data?.GetConversations?.content?.[0];

  useSubscribeToMessages({
    conversationId: selectedConversationId,
    pageable: { pageNumber: 0, pageSize: 1, sort: "createdAt,asc" }
  });

  useEffect(() => {
    if (selectedConversationId || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [selectedConversationId, setOpenSideNavigation, isMobile]);

  return (
    <DrawerDetails isOpen={!!selectedConversationId} onClose={closeDrawer}>
      {conversation && selectedPropertyIds?.length && (
        <Box>
          <Fade in={!!conversation.conversationId}>
            <Grid container direction="row">
              <Grid item lg={8} xs={12}>
                <Chat conversation={conversation} propertyIds={selectedPropertyIds} />
              </Grid>
              {!showChatPreview && (
                <Grid
                  item
                  lg={4}
                  sx={{
                    height: `calc(100vh - 64px)`
                  }}
                >
                  <ChatPreview conversation={conversation} />
                </Grid>
              )}
            </Grid>
          </Fade>
        </Box>
      )}
    </DrawerDetails>
  );
};
