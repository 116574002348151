import { ReservationDetailsDTO } from "../../../store/endpoints/reservation-table.endpoints";
import * as React from "react";
import { FC, useContext, useMemo } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import {
  Chip,
  Heading1,
  Heading2,
  isEndOfDayPms,
  ParagraphBold,
  ParagraphSmall,
  TenantContext
} from "@likemagic-tech/sv-magic-library";
import {
  ConfirmationNumber,
  FlightLand,
  FlightTakeoff,
  MeetingRoom,
  PeopleAlt,
  QuestionMark
} from "@mui/icons-material";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import { ReservationActionsMenu } from "../../../components/menu/reservation-actions-menu";
import { formatDate, formatTime } from "../../../utils/timezoned-date";
import { useProperty } from "../../../hooks/use-property";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { formatPriceToString, sumPrices } from "../../../utils/price";
import { grey } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "@mui/material/styles";
import { ReservationStatus } from "src/domain/reservation-status";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CancelIcon from "@mui/icons-material/Cancel";
import HomeIcon from "@mui/icons-material/Home";
import { useUnitGroupById } from "../../../hooks/use-unit-group-by-id";
import { responsiveVariables, useIsMobile } from "src/hooks/use-is-mobile";
import { useOpenReservationPMS } from "../../../hooks/useOpenReservationPMS";
import { CrownSimpleIcon } from "src/components/icons/CrownSimpleIcon";
import { emptyPrice } from "src/domain/price";

enum BalanceStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  OVERPAID = "OVERPAID",
  CHECKOUTONAR = "CHECKOUTONAR"
}

const getBalanceStatus = (amount: number, checkoutOnAr: boolean) => {
  if (checkoutOnAr) {
    return { status: BalanceStatus.CHECKOUTONAR, color: "info" };
  } else if (amount === 0) {
    return { status: BalanceStatus.PAID, color: "success" };
  } else if (amount > 0) {
    return { status: BalanceStatus.OVERPAID, color: "info" };
  } else {
    return { status: BalanceStatus.UNPAID, color: "error" };
  }
};

const getOHIPChargesBalance = (reservation: ReservationDetailsDTO) => {
  const charges = reservation?.rateBreakDown ?? [];
  const totalSelectedCharges =
    sumPrices(
      charges
        .flatMap((item) => item.breakDownItems)
        .flatMap((item) => item.items)
        .map((item) => item.price)
    ) ?? emptyPrice("AUD");

  const payments = reservation.folios ?? [];
  const totalSelectedPayments = () => {
    const sum = sumPrices(payments.flatMap((item) => item.payments).map((item) => item.price));
    return sum ?? emptyPrice("AUD");
  };

  return {
    amount: totalSelectedCharges.amount * -1 + totalSelectedPayments().amount,
    currency: totalSelectedCharges.currency
  };
};

export const ReservationDetailsHeader: FC<
  React.PropsWithChildren<{
    reservation: ReservationDetailsDTO;
  }>
> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const reservationActionsAnchorRef = React.useRef<SVGSVGElement>(null);
  const { selectedProperty } = useProperty();
  const openReservation = useOpenReservationPMS();
  const assignedUnitGroup = useUnitGroupById(
    selectedProperty?.propertyId ?? "",
    reservation.unit?.unitGroupId
  );
  const bookedUnitGroup = useUnitGroupById(
    selectedProperty?.propertyId ?? "",
    reservation?.bookedUnitGroupId
  );

  const { pms } = useContext(TenantContext);

  const balance = useMemo(
    () =>
      pms &&
      isEndOfDayPms(pms) &&
      reservation.folios?.length === 0 &&
      [ReservationStatus.CONFIRMED, ReservationStatus.IN_HOUSE].includes(
        reservation.reservationStatus
      )
        ? getOHIPChargesBalance(reservation)
        : reservation?.balance,
    [pms, reservation]
  );

  const balanceStatus = useMemo(
    () => getBalanceStatus(balance?.amount, reservation.checkoutOnAr),
    [balance?.amount, reservation.checkoutOnAr]
  );
  const { spacing, palette, breakpoints } = useTheme();
  const isMobile = useIsMobile();

  const displayReservationStatusIcon = (status: ReservationStatus) => {
    switch (status) {
      case ReservationStatus.CONFIRMED:
        return <CheckCircleIcon />;
      case ReservationStatus.IN_HOUSE:
        return <HomeIcon />;
      case ReservationStatus.NO_SHOW:
        return <VisibilityOffIcon />;
      case ReservationStatus.CHECKED_OUT:
        return <DirectionsRunIcon />;
      case ReservationStatus.DELETED:
        return <CancelIcon />;
      default:
        return <QuestionMark />;
    }
  };

  const reservationShorInfoList = useMemo(
    () =>
      [
        {
          icon: <FlightLand />,
          id: "flight-land",
          showcase: true,
          label: (
            <Grid container direction="row">
              <ParagraphSmall>
                {formatDate(
                  reservation.checkInTime ||
                    reservation.estimatedArrivalTime ||
                    reservation.arrival,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>

              <ParagraphSmall sx={{ color: palette.text.secondary, marginLeft: spacing(0.5) }}>
                {formatTime(
                  reservation.checkInTime ||
                    reservation.estimatedArrivalTime ||
                    reservation.arrival,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>
            </Grid>
          )
        },
        {
          icon: <FlightTakeoff />,
          showcase: true,
          id: "flight-takeoff",
          label: (
            <Grid container direction="row">
              <ParagraphSmall>
                {formatDate(
                  reservation.checkOutTime ||
                    reservation.estimatedDepartureTime ||
                    reservation.departure,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>
              <ParagraphSmall sx={{ color: palette.text.secondary, marginLeft: spacing(0.5) }}>
                {formatTime(
                  reservation.checkOutTime ||
                    reservation.estimatedDepartureTime ||
                    reservation.departure,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>
            </Grid>
          )
        },
        {
          icon: displayReservationStatusIcon(reservation.reservationStatus),
          showcase: true,
          id: "reservation-status",
          label: (
            <ParagraphSmall>
              {t(`labels__reservation_status_${reservation.reservationStatus}`)}
            </ParagraphSmall>
          )
        },
        {
          icon: <PeopleAlt />,
          id: "people-at",
          showcase: true,
          label: (
            <ParagraphSmall>
              {[
                reservation.adultsCount,
                /*@ts-ignore*/ t("labels__adult", {
                  count: reservation.adultsCount
                })
              ].join(" ")}
            </ParagraphSmall>
          )
        },
        {
          icon: <EscalatorWarningIcon />,
          id: "escalator-warning",
          showcase: reservation.childrenCount > 0,
          label: (
            <ParagraphSmall>
              {[
                reservation.childrenCount,
                t(
                  "labels__child",
                  /*@ts-ignore*/
                  { count: reservation.childrenCount }
                ),
                reservation.childrenAges
                  ?.map((ages) => {
                    return `(${ages})`;
                  })
                  .join(", ")
              ].join(" ")}
            </ParagraphSmall>
          )
        },
        {
          icon: <MeetingRoom />,
          id: "meeting-room",
          showcase: true,
          label: (
            <ParagraphSmall>
              {[assignedUnitGroup?.name || bookedUnitGroup?.name, reservation?.unit?.name]
                .filter((item) => !!item)
                .join(", ")}
            </ParagraphSmall>
          )
        },
        {
          icon: <ConfirmationNumber />,
          id: "confirmation-number",
          showcase: true,
          label: (
            <ParagraphSmall>
              {t(`labels__booking_channel_${reservation.bookingChannel}`)}
            </ParagraphSmall>
          )
        }
      ].filter((item) => !!item.label),
    [
      reservation,
      selectedProperty?.details?.timeZone,
      t,
      assignedUnitGroup,
      bookedUnitGroup,
      spacing,
      palette
    ]
  );

  //checkin code for TFE
  const checkinCode = useMemo(() => {
    return reservation.notes?.rateBreakdownComment?.[0];
  }, [reservation.notes?.rateBreakdownComment]);

  //bedding note for TFE
  const beddingNote = useMemo(() => {
    return reservation.notes?.beddingComment?.[0];
  }, [reservation.notes?.beddingComment]);

  return (
    <Grid container justifyContent="space-between" flexDirection={isMobile ? "column" : "row"}>
      <Box flex={3}>
        <Grid
          container
          alignItems="center"
          sx={{
            [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
              paddingRight: spacing(3)
            }
          }}
        >
          <Grid item>
            <Heading1 mr={1}>{`${reservation.firstName} ${reservation.lastName}`}</Heading1>
          </Grid>
          {reservation.primaryGuest.vipCode && (
            <Grid item>
              <Grid container alignItems="center">
                <Tooltip title={reservation.primaryGuest.vipCode} arrow placement="bottom">
                  <div>
                    <CrownSimpleIcon sx={{ position: "relative", top: spacing(0.8) }} />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Heading2 mr={1} color={grey[300]}>
              |
            </Heading2>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Tooltip title={t("labels__tooltip__pms__link")} arrow placement="bottom">
                  <Grid onClick={() => openReservation(reservation)} sx={{ cursor: "pointer" }}>
                    <ParagraphBold color={grey[500]}>
                      <OpenInNewIcon
                        fontSize="small"
                        sx={{ position: "relative", top: spacing(0.5) }}
                      />
                      {reservation.displayId}
                    </ParagraphBold>
                  </Grid>
                </Tooltip>
              </Grid>
              {checkinCode && (
                <>
                  <Grid item>
                    <Heading2 ml={1} pr={1} color={grey[300]}>
                      |
                    </Heading2>
                  </Grid>
                  <Grid item mr={spacing(1)}>
                    <Tooltip title={checkinCode} arrow placement="bottom">
                      <span>
                        <Chip
                          color="default"
                          size="small"
                          label={checkinCode}
                          sx={{ maxWidth: 120 }}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {beddingNote && (
                <>
                  <Grid item>
                    <Tooltip title={beddingNote} arrow placement="bottom">
                      <span>
                        <Chip
                          color="default"
                          size="small"
                          label={beddingNote}
                          sx={{ maxWidth: 120 }}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                </>
              )}
              <Grid item>
                <ReservationActionsMenu
                  reservation={reservation}
                  anchorRef={reservationActionsAnchorRef}
                  iconVertical
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" spacing={2} my={0.5}>
            {reservationShorInfoList.map((item) => (
              <React.Fragment key={`reservation-items-${item.id}`}>
                {item.showcase && (
                  <Grid item key={`reservation-items-${item.id}`}>
                    <Grid container flexWrap="nowrap" direction="row" alignItems="center">
                      {item.icon}
                      <Box ml={0.5}>{item.label}</Box>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box flex={1}>
        <Grid container direction={{ md: "column", xs: "row" }} alignItems="end">
          <Grid item lg={8} md={6}>
            <Heading1 sx={{ whiteSpace: "nowrap" }}>{formatPriceToString(balance)}</Heading1>
          </Grid>
          <Grid item lg={4} md={6} ml={1}>
            <Chip
              label={t(`labels__balance_status_${balanceStatus.status}`)}
              sx={{
                color: `${balanceStatus.color}.dark`,
                backgroundColor: `${balanceStatus.color}.light`
              }}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
