import { Grid } from "@mui/material";
import { LocalizationDatePicker } from "../../components/language-selector/localization-date-picker";
import { DatePicker } from "../../components/datepicker/DatePicker";
import * as React from "react";
import { FC } from "react";
import { Property } from "../../domain/property";
import { groupBy } from "lodash";
import { HousekeepingCardsFloor, HousekeepingCardsTypeEnum } from "./housekeeping-cards-floor";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { HousekeepingConfirmation } from "./housekeeping-confirmation";
import { useSelector } from "../../store";
import { pickerDateSelector } from "../../slices/date-picker";
import { useGetHousekeepingCardsQueryEnhanced } from "../../graphql-tasks/query/enhanced-queries/get-housekeeping-cards-enhanced";

interface UnitsToCleanProps {
  selectedProperty?: Property;
}

export const HousekeepingUnitsToClean: FC<UnitsToCleanProps> = ({ selectedProperty }) => {
  const date = useSelector(pickerDateSelector);
  const { data, isLoading } = useGetHousekeepingCardsQueryEnhanced({
    pmsPropertyId: selectedProperty?.propertyId ?? "",
    date
  });

  const housekeepingCards = data?.GetHousekeepingCards ?? { units: [], reservations: [] };
  const units = groupBy(housekeepingCards.units ?? [], "floor");
  const { t } = useTranslationWrapper();

  return (
    <Grid container>
      <Grid container spacing={1}>
        <Grid item>
          <LocalizationDatePicker>
            <DatePicker timeZone={selectedProperty?.details.timeZone ?? ""} disablePast={true} />
          </LocalizationDatePicker>
        </Grid>
      </Grid>
      {!isLoading && (
        <Grid container pt={4}>
          {!Object.keys(units).length && !housekeepingCards.reservations?.length && (
            <HousekeepingConfirmation label={t("labels__all_units_cleaned")} />
          )}
          {Object.keys(units).map((key) => (
            <HousekeepingCardsFloor
              key={`cards-flor-${key}`}
              floorKey={key}
              units={units[key]}
              housekeepingCardsType={HousekeepingCardsTypeEnum.UNITS}
              propertyId={selectedProperty?.propertyId ?? ""}
            />
          ))}
          {!!housekeepingCards?.reservations.length && (
            <HousekeepingCardsFloor
              reservations={housekeepingCards.reservations ?? []}
              housekeepingCardsType={HousekeepingCardsTypeEnum.RESERVATIONS}
              propertyId={selectedProperty?.propertyId ?? ""}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
