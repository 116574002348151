import { TerminalPaymentDocument, TerminalPaymentSubscription } from "./TerminalPayment.generated";
import { graphqlApi, GUEST_SERVICE_BASE_URL } from "../graphql.api";
import { createClient } from "../../graphql-messaging/subscribtion/subscribe.utils";
import { TerminalPaymentRequest } from "../generated/graphql";

interface TerminalPaymentSubscribeProps {
  apiKey?: string;
  tenantId: string;
  authorization?: string;
  paymentRequest: TerminalPaymentRequest;
  handleResponse: (response?: TerminalPaymentSubscription) => void;
}

export const terminalPaymentSubscribeApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SubscribeToTerminalPayment: build.query<any, TerminalPaymentSubscribeProps>({
      query: (arg) => ({
        document: TerminalPaymentDocument,
        variables: { paymentRequest: arg.paymentRequest }
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved }) {
        const client = createClient(GUEST_SERVICE_BASE_URL, arg);

        try {
          await cacheDataLoaded;
          const onNext = (value: { data: TerminalPaymentSubscription }) => {
            arg.handleResponse(value.data);
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: TerminalPaymentDocument,
                operationName: "TerminalPayment",
                variables: { paymentRequest: arg.paymentRequest }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    })
  }),

  overrideExisting: false
});
