import { Box, Button, DialogActions, DialogContent, Grid } from "@mui/material";
import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "../../../../store";
import { openBanner } from "../../../../slices/banner.slice";
import { DialogModal } from "../../../../components/submit-modal/dialog";
import { DialogTitleModal } from "../../../../components/submit-modal/dialog-title";
import { Input, RadioButton, RadioButtonGroup, Select } from "@likemagic-tech/sv-magic-library";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { Form, Formik } from "formik";
import { emptyPrice, Price } from "../../../../domain/price";
import { Notification } from "../../../../components/notification";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { FormikHelpers } from "formik/dist/types";
import { useGetOrderItemAdjustmentReasonCodesQuery } from "../../../../graphql/queries/GetOrderItemAdjustmentReasonCodes.generated";
import { useBackofficeAdjustOrderItemMutation } from "../../../../graphql/mutations/adjust-order-item.generated";
import { transformToGrossPrice } from "../../../../graphql/transform/transform-utils";
import { PriceAdjustmentType } from "../../../../graphql/generated/graphql";

interface AdjustOrderItemModalProps {
  propertyId: string;
  reservationId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  orderItemPmsIds: string[];
}

interface AdjustChargesForm {
  priceAdjustmentType: PriceAdjustmentType;
  price: Price;
  reason: string;
  reasonTitle: string;
  percentage: number;
}

export const AdjustChargesModal: FC<AdjustOrderItemModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  propertyId,
  reservationId,
  orderItemPmsIds
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { adjustChargeModalValidation } = useFormValidations();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const initialValues: AdjustChargesForm = {
    priceAdjustmentType: PriceAdjustmentType.Percentage,
    price: emptyPrice("AUD"),
    reason: "",
    reasonTitle: "",
    percentage: 100
  };

  const { data } = useGetOrderItemAdjustmentReasonCodesQuery({ pmsPropertyId: propertyId });

  const reasonOptions = useMemo(() => {
    return (
      data?.GetOrderItemAdjustmentReasonCodes.map((item) => ({
        label: item.name,
        value: item.pmsId
      })) ?? []
    );
  }, [data?.GetOrderItemAdjustmentReasonCodes]);

  const [adjustOrderItem] = useBackofficeAdjustOrderItemMutation();
  const handleSubmit = (
    values: AdjustChargesForm,
    { validateForm }: FormikHelpers<AdjustChargesForm>
  ) => {
    validateForm(values)
      .then(() => {
        return adjustOrderItem({
          pmsPropertyId: propertyId,
          adjustOrderItemInput: {
            pmsReservationId: reservationId,
            grossPrice: transformToGrossPrice({
              currency: values.price.currency,
              amount: 100 * values.price.amount
            }),
            percentage: values.percentage,
            pmsOrderItemIds: orderItemPmsIds,
            pmsReasonCodeId: values.reasonTitle,
            priceAdjustmentType: values.priceAdjustmentType,
            reasonText: values.reason
          }
        }).unwrap();
      })
      .then(() => {
        dispatch(
          openBanner({
            type: "success",
            title: t("labels__adjust_moved")
          })
        );
        onSuccess && onSuccess();
        onClose();
      })
      .catch(() => {
        dispatch(
          openBanner({
            type: "error",
            title: t("labels__error")
          })
        );
      });
  };
  return (
    <DialogModal isOpen={isOpen} handleClose={handleClose}>
      <Grid container p={3} flexDirection="column">
        <DialogTitleModal onClose={handleClose} title={t("labels__adjust_charge")} />
        <DialogContent sx={{ padding: "0" }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={adjustChargeModalValidation}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(formik) => (
              <Form id="adjust-charge-modal">
                <Box pt={3}>
                  <RadioButtonGroup
                    name="priceAdjustmentType"
                    value={formik.values.priceAdjustmentType}
                    onChange={formik.handleChange}
                  >
                    {Object.values(PriceAdjustmentType).map((item) => (
                      <RadioButton
                        label={t(`labels__price_adjust_${item}`)}
                        value={item}
                        key={`payment-adjust-type-item-${item}`}
                      />
                    ))}
                  </RadioButtonGroup>

                  <Box
                    mt={2}
                    hidden={formik.values.priceAdjustmentType !== PriceAdjustmentType.FlatAmount}
                  >
                    <PricePicker
                      prefix="price"
                      value={formik.values.price}
                      error={
                        formik.touched.price?.amount && formik.errors.price?.amount
                          ? formik.errors.price?.amount
                          : undefined
                      }
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box
                    mt={2}
                    hidden={formik.values.priceAdjustmentType !== PriceAdjustmentType.Percentage}
                  >
                    <Input
                      name="percentage"
                      variant="outlined"
                      label={t("labels__percentage")}
                      type="number"
                      error={formik.errors.percentage}
                      value={formik.values.percentage}
                      onChange={formik.handleChange}
                    />
                  </Box>

                  <Box mt={2}>
                    <Select
                      name="reasonTitle"
                      label={t("labels__reason")}
                      variant="outlined"
                      value={formik.values.reasonTitle}
                      error={!!formik.errors.reasonTitle}
                      onChange={formik.handleChange}
                      options={reasonOptions}
                    />
                  </Box>

                  <Box mt={2}>
                    <Input
                      name="reason"
                      value={formik.values.reason}
                      error={formik.errors.reason}
                      onChange={formik.handleChange}
                      multiline
                      variant="outlined"
                      label={t("labels__description")}
                      minRows={4}
                    />
                  </Box>

                  <Box mt={2}>
                    <Notification
                      type="info"
                      title={t(`labels__adjust_hint_${formik.values.priceAdjustmentType}`)}
                    />
                  </Box>

                  <DialogActions sx={{ flex: "1 1 0", px: "0", flexDirection: "column" }}>
                    <Button
                      type="submit"
                      variant="primary"
                      fullWidth
                      sx={{ marginTop: theme.spacing(2) }}
                    >
                      {t("buttons__adjust_charge")}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => onClose()}
                      fullWidth
                      sx={{ mt: theme.spacing(2), mr: 1 }}
                    >
                      {t("buttons__cancel")}
                    </Button>
                  </DialogActions>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Grid>
    </DialogModal>
  );
};
