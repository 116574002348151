import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PaymentComponentProps } from "../payment-component";
import { useDispatch, useSelector } from "../../../../store";
import { usePaymentTerminal } from "../hooks/use-payment-terminal";
import { openBanner } from "../../../../slices/banner.slice";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Alert, Box, CircularProgress, useTheme } from "@mui/material";
import { Button, ParagraphSmall, Select } from "@likemagic-tech/sv-magic-library";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { TerminalPaymentSubscription } from "../../../../graphql/subscribtion/TerminalPayment.generated";
import { EntityStateStatus, isStatusLoading } from "../../../../domain/EntityStateStatus";
import { useCancelTerminalPaymentMutation } from "../../../../graphql/mutations/cancel-terminal-payment.generated";
import { Form, Formik } from "formik";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { parsePricePickerAmountToAmountInCents } from "../../../../utils/price";
import { InfoIcon } from "../../../../icons/info.icon";
import {
  selectLastSelectedTerminalId,
  setLastSelectedTerminalId
} from "../../../../slices/recently-used-devices.slice";

export const PaymentTerminalComponent: FC<PaymentComponentProps> = ({
  paymentOption,
  onSuccess,
  magicId,
  onFailed,
  price,
  propertyId,
  folioMetadata
}) => {
  const { t } = useTranslationWrapper();
  const { subscribeAction, unsubscribeAction } = usePaymentTerminal();
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [serviceId, setServiceId] = useState<string | undefined>();
  const [paymentStatus, setPaymentStatus] = useState(EntityStateStatus.IDLE);
  const [cancelAction] = useCancelTerminalPaymentMutation();
  const { terminalPaymentValidations } = useFormValidations();
  const promiseRef = useRef<any>();
  const theme = useTheme();
  const dispatch = useDispatch();
  const lastSelectedSecondScreenId = useSelector(selectLastSelectedTerminalId);

  const handleResponse = useCallback(
    (data?: TerminalPaymentSubscription) => {
      if (data?.TerminalPayment) {
        setServiceId(data?.TerminalPayment?.serviceId ?? undefined);
        setTransactionId(data.TerminalPayment?.transactionId ?? undefined);
        if (data?.TerminalPayment?.success === true) {
          setPaymentStatus(EntityStateStatus.SUCCEEDED);
          unsubscribeAction(promiseRef?.current?.queryCacheKey);
          onSuccess();
        } else if (data?.TerminalPayment?.success === false) {
          setPaymentStatus(EntityStateStatus.FAILED);
          onFailed();
          unsubscribeAction(promiseRef?.current?.queryCacheKey);
          setServiceId("undefined");
          setTransactionId(undefined);
          dispatch(
            openBanner({
              type: "error",
              errorId: data?.TerminalPayment?.errorReason ?? undefined,
              title: t("labels__action_failed_to_perform")
            })
          );
        }
      }
    },
    [onSuccess, t, dispatch, onFailed, unsubscribeAction]
  );

  useEffect(() => {
    return () => {
      if (promiseRef?.current?.queryCacheKey) {
        unsubscribeAction(promiseRef?.current?.queryCacheKey);
        setServiceId(undefined);
        setTransactionId(undefined);
      }
    };
  }, [unsubscribeAction]);

  const listOfTerminalIds =
    paymentOption?.__typename === "TerminalPaymentMethod"
      ? paymentOption?.availableTerminals?.map((item) => ({
          label: item?.name ?? "",
          value: item?.id ?? ""
        })) ?? []
      : [];

  const handleCancelPayment = useCallback(
    async (values: { paymentTerminalId?: string; price: { amount: number; currency: string } }) => {
      if (propertyId && values.paymentTerminalId && serviceId && transactionId) {
        const data = await cancelAction({
          cancelTerminalPaymentRequest: {
            pmsPropertyId: propertyId,
            terminalId: values.paymentTerminalId,
            serviceId,
            transactionId
          }
        }).unwrap();
        unsubscribeAction(promiseRef?.current?.queryCacheKey);
        if (data.CancelTerminalPayment) {
          setPaymentStatus(EntityStateStatus.IDLE);
        }
      }
    },
    [propertyId, serviceId, transactionId, cancelAction, unsubscribeAction]
  );

  const isLoading = isStatusLoading(paymentStatus);
  const isCancelAvailable = useMemo(
    () => !(propertyId && serviceId && transactionId),
    [propertyId, serviceId, transactionId]
  );

  const initialValues = useMemo(() => {
    return {
      paymentTerminalId: lastSelectedSecondScreenId ?? undefined,
      price: {
        ...price,
        amount: price.amount / 100
      }
    };
  }, [lastSelectedSecondScreenId, price]);

  const handleSubmit = useCallback(
    (values: { paymentTerminalId?: string; price: { amount: number; currency: string } }) => {
      setPaymentStatus(EntityStateStatus.LOADING);

      if (propertyId && values.paymentTerminalId) {
        dispatch(setLastSelectedTerminalId(values.paymentTerminalId));
        try {
          promiseRef.current = subscribeAction({
            terminalId: values.paymentTerminalId,
            magicId: magicId,
            pmsPropertyId: propertyId,
            handleResponse,
            price: {
              amount: parsePricePickerAmountToAmountInCents(values.price.amount),
              currency: values.price.currency
            },
            folioMetadata
          });
        } catch (e) {
          console.warn(e);
          onFailed();
          setPaymentStatus(EntityStateStatus.FAILED);
        }
      }
    },
    [propertyId, dispatch, subscribeAction, magicId, handleResponse, folioMetadata, onFailed]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={isLoading ? handleCancelPayment : handleSubmit}
      validationSchema={terminalPaymentValidations}
    >
      {(formik) => (
        <Form id="terminal-payment">
          {isLoading && (
            <Box sx={{ position: "absolute", zIndex: 10, left: 0, right: 0 }}>
              <div style={{ margin: "auto", width: 56 }}>
                <CircularProgress size={88} thickness={5} />
              </div>
            </Box>
          )}
          <Box py={1}>
            <Select
              id="paymentTerminalId"
              name="paymentTerminalId"
              variant="outlined"
              options={listOfTerminalIds}
              value={formik.values.paymentTerminalId}
              disabled={isLoading}
              label={t("labels__terminal_id")}
              onChange={formik.handleChange}
              error={formik.touched.paymentTerminalId ? !!formik.errors.paymentTerminalId : false}
            />
          </Box>
          <Box py={1}>
            <PricePicker
              prefix="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              disabled={isLoading}
            />
          </Box>
          {formik.values.price.amount < 0 && (
            <Alert
              icon={<InfoIcon sx={{ color: theme.palette.info.main }} />}
              sx={{
                alignItems: "center",
                backgroundColor: theme.palette.info.light,
                mb: 2,
                mt: 1
              }}
            >
              <ParagraphSmall>{t("labels_make_unreferenced_negative_payment")}</ParagraphSmall>
            </Alert>
          )}
          <Box textAlign="center" mt={1}>
            {isLoading ? (
              <Button
                sx={{ width: "50%" }}
                type="submit"
                variant="primary"
                disabled={isCancelAvailable}
              >
                {t("buttons__cancel")}
              </Button>
            ) : (
              <Button
                sx={{ width: "50%" }}
                type="submit"
                variant="primary"
                disabled={!formik.values.paymentTerminalId}
              >
                {t("buttons__choose")}
              </Button>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
