import { FC, useEffect, useState } from "react";
import { useApiVersion, useAuth } from "@likemagic-tech/sv-magic-library";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useReservationDetails } from "src/hooks/use-reservation-details";

const URL_V1 = `/api/guest-journey-service/backoffice/reservations/sse-updates?filter=root,derived`;
const URL_V2 = `/api/guest-service/backoffice/reservations/sse-updates`;
const initFetchEventSource = async (
  url: string,
  token: string,
  handleNewEvent: (reservationId: string) => void,
  abort: AbortController
) => {
  await fetchEventSource(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    },
    onopen(res) {
      if (res.ok && res.status === 200) {
        return Promise.resolve();
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.log("Client side error ", res);
      }
      return Promise.resolve();
    },
    onmessage(event) {
      switch (event.event) {
        case "reservation-updated":
          handleNewEvent(event.id);
          break;
        case "keep-alive":
          break;
      }
    },
    onclose() {
      console.log("Connection closed by the server");
    },
    onerror(err) {
      console.log("There was an error from server", err);
    },
    signal: abort.signal,
    openWhenHidden: true
  });
};
export const ReservationEvents: FC<React.PropsWithChildren<{ reservationIds: Array<string> }>> = ({
  reservationIds
}) => {
  const { keycloak } = useAuth();
  const [updatedReservationId, setUpdatedReservationId] = useState<string | undefined>(undefined);

  const { isRESTVersion } = useApiVersion();
  useReservationDetails({
    reservationId: updatedReservationId ?? "",
    skipSpinner: true,
    skipApiCall: !reservationIds.includes(updatedReservationId ?? "")
  });

  useEffect(() => {
    setUpdatedReservationId(undefined);
  }, [updatedReservationId]);

  useEffect(() => {
    if (isRESTVersion) {
      const abortSignal = new AbortController();
      initFetchEventSource(URL_V1, keycloak?.token ?? "", setUpdatedReservationId, abortSignal);

      return () => {
        setUpdatedReservationId(undefined);
        abortSignal.abort();
      };
    } else {
      const abortSignal = new AbortController();
      initFetchEventSource(URL_V2, keycloak?.token ?? "", setUpdatedReservationId, abortSignal);

      return () => {
        setUpdatedReservationId(undefined);
        abortSignal.abort();
      };
    }
  }, [keycloak.token, isRESTVersion]);

  return null;
};
