import { useCallback, useContext } from "react";
import { TenantContext } from "@likemagic-tech/sv-magic-library";
import { QueryCacheKey } from "@reduxjs/toolkit/dist/query/core/apiState";
import { terminalPaymentSubscribeApi } from "../../../../graphql/subscribtion/terminal-payment-subscribe";
import { useDispatch } from "../../../../store";
import { Price } from "../../../../domain/price";
import { TerminalPaymentSubscription } from "../../../../graphql/subscribtion/TerminalPayment.generated";

export const usePaymentTerminal = () => {
  const dispatch = useDispatch();
  const { tenantId, apiKey, keycloak } = useContext(TenantContext);
  const authToken = keycloak.token;

  const subscribeAction = useCallback(
    ({
      magicId,
      terminalId,
      pmsPropertyId,
      folioMetadata,
      price,
      handleResponse
    }: {
      magicId: string;
      price: Price;
      terminalId: string;
      pmsPropertyId: string;
      folioMetadata?: any;
      handleResponse: (response?: TerminalPaymentSubscription) => void;
    }) =>
      dispatch(
        terminalPaymentSubscribeApi.endpoints.SubscribeToTerminalPayment.initiate(
          {
            tenantId,
            apiKey,
            authorization: authToken,
            paymentRequest: {
              magicId,
              amount: price,
              terminalId,
              shopperReference: magicId,
              pmsPropertyId,
              targetFolioMetadata: folioMetadata
            },
            handleResponse,
            // @ts-ignore
            skipSpinner: true
          },
          { forceRefetch: true }
        )
      ),
    [dispatch, tenantId, apiKey, authToken]
  );

  const unsubscribeAction = useCallback(
    (queryCacheKey: QueryCacheKey) => {
      dispatch(
        terminalPaymentSubscribeApi.internalActions.removeQueryResult({
          queryCacheKey: queryCacheKey
        })
      );
    },
    [dispatch]
  );

  return { subscribeAction, unsubscribeAction };
};
