import { Box, TableRow, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {
  Button,
  Chip,
  formatDate,
  formatTime,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { AssignmentIndOutlined, Check, EditOutlined } from "@mui/icons-material";
import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import BedroomParentOutlinedIcon from "@mui/icons-material/BedroomParentOutlined";
import { colorSchemePriority } from "../../features/tasks/task-row";
import { useTaskModal } from "../../features/tasks/use-task-select-id";
import { isSameDay } from "date-fns";
import { grey } from "@mui/material/colors";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { Status } from "../../graphql-tasks/generated/graphql";
import { HousekeepingTaskType, TaskPriority } from "../../features/tasks/domain/task";
import { HousekeepingTableType } from "./housekeeping-task-table";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import { useProperty } from "../../hooks/use-property";
import DOMPurify from "dompurify";

const useStyles = makeStyles()((theme) => ({
  cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  }
}));

interface HousekeepingTaskTableRowProps {
  housekeepingTableType: HousekeepingTableType;
  task: HousekeepingTaskType;
  handleUpdate: (values: HousekeepingTaskType) => Promise<void>;
}

export const HousekeepingTaskTableRow: FC<HousekeepingTaskTableRowProps> = ({
  housekeepingTableType,
  task,
  handleUpdate
}) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const { openEditTask } = useTaskModal({});
  const { palette } = useTheme();
  const { selectedProperty } = useProperty();

  const passedDueDate = useMemo(() => {
    return task.due && !isSameDay(new Date(task.due), new Date());
  }, [task.due]);

  const priorityChipDisplay = (priority: TaskPriority) => {
    const color = colorSchemePriority(priority);
    const accentChipProps = {
      backgroundColor: palette.accent.light,
      color: palette.accent.dark,
      "& .MuiChip-icon": {
        color: palette.accent.dark
      }
    };

    return (
      <Chip
        size="small"
        leftIcon={<EmojiFlagsIcon />}
        sx={priority === TaskPriority.Urgent ? accentChipProps : {}}
        color={color}
        label={t(`labels__additional_filter_${priority}`)}
      />
    );
  };

  const unitName = useMemo(() => {
    return task?.links?.units?.[0]?.name;
  }, [task]);

  const floorName = useMemo(() => {
    const floor = task?.links?.units?.[0]?.floor;
    return floor ? `, ${t("labels__floor")} ${floor}` : "";
  }, [t, task]);

  const handleOnMarkAsDone = useCallback(() => {
    handleUpdate({
      ...task,
      status: Status.Done
    });
  }, [task, handleUpdate]);

  const sanitizedHtmlContent = useMemo(() => {
    return DOMPurify.sanitize(task.description ?? "");
  }, [task]);

  return (
    <TableRow>
      <TableCell />
      <TableCell className={classes.cell}>
        <ParagraphBold>{task.title}</ParagraphBold>
        <ParagraphSmall
          color={grey[600]}
          dangerouslySetInnerHTML={{
            __html: sanitizedHtmlContent
          }}
        ></ParagraphSmall>
      </TableCell>
      {housekeepingTableType === HousekeepingTableType.LEFTOVER && (
        <TableCell className={classes.cell}>
          <Box display="flex" alignItems="center" gap={1}>
            <BedroomParentOutlinedIcon />
            <ParagraphSmall>
              {unitName}
              {floorName}
            </ParagraphSmall>
          </Box>
        </TableCell>
      )}
      <TableCell className={classes.cell}>
        <Box display="flex" alignItems="center" gap={1}>
          <AssignmentIndOutlined />
          <ParagraphSmall>
            {task.assignee?.displayName
              ? task.assignee.displayName
              : task.assigneeGroup?.displayName}
          </ParagraphSmall>
        </Box>
      </TableCell>
      {housekeepingTableType === HousekeepingTableType.ADDITIONAL && (
        <TableCell className={classes.cell}>{priorityChipDisplay(task.priority)}</TableCell>
      )}
      <TableCell className={classes.cell}>
        <ParagraphSmall color={passedDueDate ? "error" : "default"}>
          {passedDueDate && "!"} {formatDate(task.due, selectedProperty?.details.timeZone)}
        </ParagraphSmall>
        <ParagraphSmall color={palette.text.secondary}>
          {formatTime(task.due, selectedProperty?.details.timeZone)}
        </ParagraphSmall>
      </TableCell>
      <TableCell className={classes.cell}>
        <Box display="flex" gap={2} justifyContent="flex-end">
          <Button variant="ghost" color="neutral" onClick={() => openEditTask(String(task.id))}>
            <EditOutlined sx={{ pr: 0.5 }} />
            {t("labels__edit")}
          </Button>
          <Button
            color="success"
            onClick={() => {
              handleOnMarkAsDone();
            }}
          >
            <Check sx={{ pr: 0.5 }} />
            {t("buttons__mark_as_done")}
          </Button>
        </Box>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
