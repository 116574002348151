import { FC, useMemo } from "react";
import { useProperty } from "../../../../hooks/use-property";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { SelectChangeEvent } from "@mui/material";
import { useLocation } from "react-router-dom";
import { PropertySelectorCheckbox } from "./property-selector-checkbox";
import { Select } from "@likemagic-tech/sv-magic-library";

const PropertySelector: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslationWrapper();
  const location = useLocation();
  const {
    allProperties,
    getProperty,
    setSelectedPropertyIds,
    selectedPropertyIds,
    selectedProperty
  } = useProperty();

  const isMultipleSelection = useMemo(
    () => location.pathname === "/messaging",
    [location.pathname]
  );

  const handleChangeProperty = (value: string[] | string): void => {
    if (isMultipleSelection) {
      // @ts-ignore
      const newArray = value.flat();
      const newArrayWithoutDuplicates = [...new Set(newArray)];
      const sortedArray = newArrayWithoutDuplicates.sort();
      setSelectedPropertyIds(sortedArray.join(","));
    } else {
      // @ts-ignore
      setSelectedPropertyIds(value);
    }
  };

  const propertyValue = useMemo(
    () => (isMultipleSelection ? selectedPropertyIds : selectedPropertyIds[0]),
    [isMultipleSelection, selectedPropertyIds]
  );

  const allPropertyIdsButSelected = useMemo(
    () =>
      selectedPropertyIds.length === allProperties.length
        ? []
        : allProperties
            .map((item) => item.propertyId)
            .filter((item) => !selectedPropertyIds.includes(item)),
    [allProperties, selectedPropertyIds]
  );

  return (
    <>
      {allProperties && selectedPropertyIds && (
        // @ts-ignore
        <Select<string[] | string>
          multiple={isMultipleSelection}
          value={propertyValue ?? selectedProperty?.propertyId} //use value if there is not yet property in url
          label={t("labels__property")}
          variant={"outlined"}
          renderValue={(selectedIds: string[] | string) =>
            isMultipleSelection
              ? selectedIds.map((id) => getProperty(id)?.details?.name ?? id).join(", ")
              : getProperty(selectedIds)?.details?.name
          }
          onChange={(e: SelectChangeEvent<Array<string>>) => {
            handleChangeProperty(e.target.value);
          }}
          disabled={allProperties.length === 1}
          options={[]
            .concat(
              isMultipleSelection
                ? {
                    label: t("labels__all_properties"),
                    value: allPropertyIdsButSelected,
                    icon: isMultipleSelection ? (
                      <PropertySelectorCheckbox
                        checked={
                          selectedPropertyIds.sort().join(",") ===
                          allProperties
                            .map((item) => item.propertyId)
                            .sort()
                            .join(",")
                        }
                      />
                    ) : undefined
                  }
                : undefined
            )
            .concat(
              allProperties.map((property) => ({
                label: property?.details?.name,
                value: property?.propertyId,
                icon: isMultipleSelection ? (
                  <PropertySelectorCheckbox
                    checked={selectedPropertyIds?.includes(property.propertyId)}
                  />
                ) : undefined
              }))
            )
            .filter((item) => !!item)}
        />
      )}
    </>
  );
};

export default PropertySelector;
