import { magicApi } from "../magic.api";
import { PageableRequestParams, PageableResponse } from "../../types/pageable";
import { UserProfile } from "src/domain/user-profile";

interface GetProfilesPayload {
  freeTextSearch: string;
}

const userProfileEndpoints = magicApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfiles: builder.query<
      UserProfile[],
      { propertyId: string; pageable: PageableRequestParams; payload: GetProfilesPayload }
    >({
      query: ({ propertyId, pageable, payload }) => ({
        url: `/user-profile/by-property/${propertyId}?page=${pageable.pageNumber}&size=${pageable.pageSize}`,
        headers: { method: "POST" },
        body: payload
      }),
      transformResponse: (response: PageableResponse<UserProfile>): UserProfile[] => {
        return response.content;
      }
    })
  })
});

export const { useGetProfilesQuery, useLazyGetProfilesQuery } = userProfileEndpoints;
