import { Button, Heading2, Paragraph } from "@likemagic-tech/sv-magic-library";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import { ReservationActionsModal } from "../reservation-actions-modal";
import { PreferencesActorSelectionComponent } from "./guest-preferences-actor-selection";
import { AutocompleteOption } from "../../../../../../components/autocomplete-component";
import { GuestPreferencesActor } from "../rows-for-table-guest-overview/rows-for-table-guest-overview";
import { usePerformReservationAction } from "src/hooks/use-reservation-actions";

interface EditGuestPreferencesTextModalProps extends ReservationActionsModal {}

export const EditGuestPreferencesTextModal: FC<EditGuestPreferencesTextModalProps> = ({
  reservation,
  isOpen,
  onClose
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const [selectedActor, setSelectedActor] = useState<AutocompleteOption | null>(null);
  const [userInputHappened, setUserInputHappened] = useState<boolean>(false);

  const [text, setText] = useState<string>();
  const defaultText = useCallback(
    (actor: GuestPreferencesActor) => {
      if (actor === GuestPreferencesActor.PRIMARY_GUEST) {
        return reservation.primaryGuest.guestPreferencesText;
      } else if (actor === GuestPreferencesActor.BOOKER) {
        return reservation.booker.guestPreferencesText;
      }
      return "";
    },
    [reservation]
  );

  const actualSetSelectedActor = useCallback(
    (actor: AutocompleteOption | null) => {
      // goal is to, when the choice is changed
      // * if no user-input happened: nicely change the "default" text
      // * if user-input happened, avoid overwriting it
      setSelectedActor(actor);
      if (!userInputHappened) {
        setText(defaultText(actor?.id as GuestPreferencesActor));
      }
    },
    [setText, setSelectedActor, defaultText, userInputHappened]
  );

  const handleClose = useCallback(() => {
    setSelectedActor(null);
    setText("");
    setUserInputHappened(false);
    onClose();
  }, [onClose, setText, setSelectedActor]);

  const action = AllowedActionEnum.EDIT_PREFERENCES_TEXT;
  const dispatch = useDispatch();

  const [performAction, result] = usePerformReservationAction();

  const handleSubmit = useCallback(() => {
    performAction({
      reservationId: reservation.id,
      action,
      payload: { actor: selectedActor?.id, text }
    });
  }, [performAction, reservation.id, action, text, selectedActor]);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();

      handleClose();
    }
  }, [result, dispatch, t, handleClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }
      }}
    >
      <Grid container p={3} flexDirection="column">
        <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
          <Grid container direction="row">
            <Grid item xs={11}>
              <Heading2 sx={{ color: theme.palette.text.primary }}>
                {t("labels__reservation_action_modal_title_EDIT_PREFERENCES_TEXT")}
              </Heading2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="end">
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Box
            sx={{
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(2)
            }}
          >
            <PreferencesActorSelectionComponent
              selectedActor={selectedActor}
              setSelectedActor={actualSetSelectedActor}
              reservation={reservation}
            />
          </Box>
          {selectedActor && (
            <TextField
              multiline
              value={text}
              sx={{ width: "100%" }}
              InputProps={{
                sx: { height: 270, alignItems: "baseline" }
              }}
              rows={10}
              placeholder={t(
                "labels__reservation_action_modal_textarea_placeholder_EDIT_PREFERENCES_TEXT"
              )}
              disabled={!selectedActor}
              autoFocus
              onFocus={() => {
                if (text && !userInputHappened) {
                  setText(text + "\r\n");
                }
              }}
              onChange={(e) => {
                // note: not triggered by the onFocus change
                setUserInputHappened(true);
                setText(e.target.value);
              }}
            ></TextField>
          )}
        </DialogContent>
        <DialogActions sx={{ flex: "1 1 0", px: "0" }}>
          <Button
            onClick={() => handleSubmit()}
            fullWidth
            disabled={!selectedActor}
            sx={{ marginTop: theme.spacing(5) }}
          >
            <Paragraph
              sx={{
                color: theme.palette.background.paper,
                textTransform: "none"
              }}
            >
              {t("labels__save")}
            </Paragraph>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};
