import type { FC } from "react";
import type { Theme } from "@mui/material";
import type { SxProps } from "@mui/system";
import { LikeMagicLogo } from "./likemagic-logo";
import { styled } from "@mui/material";

export interface LogoProps {
  sx?: SxProps<Theme>;
  isWhite?: boolean;
  height?: string;
  padding?: string;
}

const LOGO_HEIGHT = "64";

export const LogoRoot = styled("svg")({});
const Logo: FC<React.PropsWithChildren<LogoProps>> = (props) => {
  return <LikeMagicLogo {...props} height={props.height || LOGO_HEIGHT} />;
};

export default Logo;
