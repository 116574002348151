import { useDoorProviderConfig, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useCallback, useRef } from "react";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { KeyComponent } from "../../../components/KeyComponent";
import { useFetchAvailableDoors } from "../../../hooks/use-fetch-available-doors";
import { AvailableDoor } from "../../../domain/available-door";
import { getYesNoModalArg } from "../../../utils/modal-util";
import { Unit } from "../../../graphql-tasks/generated/graphql";
import { resetDoorStatus } from "../../../slices/available-doors-state.slice";
import { useOpenDoor } from "../../../hooks/use-open-door";
import { useDispatch } from "../../../store";
import { areRemoteKeysAvailable } from "../../../utils/key-utils";

export const HousekeepingCardUnitKeySlider = ({
  unit,
  propertyId
}: {
  unit?: Unit;
  propertyId: string;
}) => {
  const { t } = useTranslationWrapper();
  const doorConfig = useDoorProviderConfig(propertyId);
  const { filterPrivateKeysByUnitId } = useFetchAvailableDoors(propertyId);
  const doorsData = filterPrivateKeysByUnitId(unit?.pmsUnitId ?? "");
  const keySliderRefs = useRef<{ [doorId: string]: any }>({});
  const { open: openYesNoModal } = useGlobalModal();
  const { openAdminDoor } = useOpenDoor();
  const dispatch = useDispatch();

  const handleOpenDoor = useCallback(
    async (unit: Unit | undefined, doorId: string) => {
      await openAdminDoor({ propertyId: propertyId, doorId });
      setTimeout(() => {
        dispatch(resetDoorStatus(doorId));
        keySliderRefs.current?.[doorId]?.reset();
      }, 5000);
    },
    [dispatch, openAdminDoor, propertyId]
  );

  const handleOpenDoorSlider = useCallback(
    async (door: AvailableDoor) => {
      if (unit?.occupied) {
        const result = await openYesNoModal(
          getYesNoModalArg(t("labels__open_door_occupied"), "", t("labels__yes"), t("labels__no"))
        );
        if (result) {
          handleOpenDoor(unit, door.id);
        } else {
          keySliderRefs.current?.[Number(door.id)]?.reset();
        }
      } else {
        handleOpenDoor(unit, door.id);
      }
    },
    [handleOpenDoor, openYesNoModal, unit, t]
  );

  return (
    <>
      {!!doorsData.length &&
        areRemoteKeysAvailable(doorConfig?.doorProvider) &&
        doorsData?.map((doorData) => {
          return (
            <KeyComponent
              key={doorData.id}
              doorData={doorData}
              doorProvider={doorConfig?.doorProvider}
              keySliderRefs={keySliderRefs}
              onSuccess={handleOpenDoorSlider}
            />
          );
        })}
    </>
  );
};
