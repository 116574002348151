import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetAdditionalServicesAvailabilityQueryVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
}>;

export type GetAdditionalServicesAvailabilityQuery = {
  __typename?: "Query";
  BackofficeGetAdditionalServicesAvailability?: Array<{
    __typename?: "AdditionalServiceAvailabilityDTO";
    pmsServiceId: string;
    available: boolean;
    bookedDates?: Array<any | null> | null;
    bookableDates?: Array<any | null> | null;
    magicServiceCodeEnum?: string | null;
    granularity: Types.Granularity;
    name?: string | null;
    canBookMultiple?: boolean | null;
    basePrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    };
  } | null> | null;
};

export const GetAdditionalServicesAvailabilityDocument = `
    query GetAdditionalServicesAvailability($pmsReservationId: String!) {
  BackofficeGetAdditionalServicesAvailability(pmsReservationId: $pmsReservationId) {
    pmsServiceId
    available
    bookedDates
    bookableDates
    magicServiceCodeEnum
    basePrice {
      currency
      grossPrice
      grossPriceInCents
      netPrice
      netPriceInCents
      taxes {
        amount
        amountInCents
        code
      }
    }
    granularity
    name
    canBookMultiple
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetAdditionalServicesAvailability: build.query<
      GetAdditionalServicesAvailabilityQuery,
      GetAdditionalServicesAvailabilityQueryVariables
    >({
      query: (variables) => ({ document: GetAdditionalServicesAvailabilityDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const {
  useGetAdditionalServicesAvailabilityQuery,
  useLazyGetAdditionalServicesAvailabilityQuery
} = injectedRtkApi;
