import { Priority, UnitToBeCleaned } from "../../domain/UnitToBeCleaned";
import { GetToBeCleanedTodayQuery } from "../queries/GetToBeCleanedToday.generated";
import { Priority as PriorityV2, UnitState } from "../generated/graphql";
import { UnitCondition } from "../../domain/Unit";

type GetPropertyType = Exclude<GetToBeCleanedTodayQuery["GetProperty"], undefined | null>;
type ToBeCleaned = Exclude<GetPropertyType["toBeCleanedToday"], undefined | null>;
type ToBeCleanedItem = ToBeCleaned[number];

export const transformToBeCleaned = (argV2: ToBeCleaned) => argV2.map(transformToBeCleanedItem);

const transformToBeCleanedItem = (argV2: ToBeCleanedItem): UnitToBeCleaned => {
  return {
    attributes: [],
    services:
      argV2?.services?.map((item) => ({
        createdAt: "",
        updatedAt: "",
        price: {
          currency: item?.price?.currency ?? "",
          amount: item?.price?.grossPriceInCents ?? 0
        },
        code: item?.code ?? "",
        name: item?.name ?? "",
        quantity: item?.quantity ?? 0
      })) ?? [],

    id: argV2?.pmsUnitId ?? "",
    name: argV2?.name ?? "",
    priority: transformPriority(argV2?.priority),
    privacyMode: false,
    privacyModeLastChangeTimestamp: "",
    status: {
      occupied: !!argV2?.occupied,
      condition: transformUnitStatus(argV2?.condition)
    },
    unitGroupId: argV2?.pmsUnitGroupIds?.[0] ?? "",
    adults: argV2?.adults ?? 0,
    children: argV2?.children ?? 0,
    arrival: argV2?.arrival,
    contractualCheckInTime: argV2?.contractualCheckInTime,
    departure: argV2?.departure,
    expectedDeparture: argV2?.expectedDeparture,
    floorNumber: argV2?.floorNumber ?? null
  };
};

const transformPriority = (priorityV2?: PriorityV2): Priority => {
  switch (priorityV2) {
    case PriorityV2.High:
      return Priority.HIGH;
    case PriorityV2.Medium:
      return Priority.MEDIUM;
    case PriorityV2.Low:
      return Priority.LOW;
    default:
      return Priority.LOW;
  }
};
const transformUnitStatus = (unitState?: UnitState): UnitCondition => {
  switch (unitState) {
    case UnitState.Clean:
      return UnitCondition.CLEAN;
    case UnitState.CleanToBeInspected:
      return UnitCondition.CLEAN_TO_BE_INSPECTED;
    case UnitState.Dirty:
      return UnitCondition.DIRTY;
    case UnitState.OutOfOrder:
      return UnitCondition.UNKNOWN;
    case UnitState.OutOfService:
      return UnitCondition.UNKNOWN;
    default:
      return UnitCondition.UNKNOWN;
  }
};
