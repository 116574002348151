import { MESSAGE_BASE_URL, messagingApi } from "../messaging.api";
import { createClient, Message } from "./subscribe.utils";
import { StreamMessageDocument } from "./SubscribtionMessage.generated";
import { StreamConversationDocument } from "./StreamConvesation.generated";
import {
  MessagingTab,
  Pageable,
  StreamConversationSubscription,
  StreamMessageSubscription
} from "../generated/graphql";
import { api as getConversationDetailsApi } from "../queries/GetConversationDetails.generated";
import { api as getConversationApi } from "../queries/GetConversations.generated";
import { skipTransformingValue, transformFiltersToGraphqlFilters } from "../../utils/filters";
import { FilterMapInterface } from "../../components/table/filters/table-filters";

interface ConversationSubscribeProps {
  apiKey?: string;
  tenantId: string;
  pageable: Pageable;
  authorization?: string;
  propertyIds?: string[];
  filters: FilterMapInterface;
  tab?: string;
}
interface MessagesSubscribeProps {
  apiKey?: string;
  tenantId: string;
  conversationId: string;
  authorization?: string;
}

export const conversationSubscribeApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    SubscribeToConversation: build.query<Message[], ConversationSubscribeProps>({
      query: (arg) => ({
        document: StreamConversationDocument,
        variables: {
          filter: {
            pmsPropertyIds: arg.propertyIds,
            pageable: arg.pageable,
            filters: transformFiltersToGraphqlFilters(
              Object.keys(arg.filters).map((key) => arg.filters[key]),
              skipTransformingValue
            ),
            ...(arg.tab && {
              tab: arg.tab
            })
          }
        }
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        const client = createClient(MESSAGE_BASE_URL, arg);

        try {
          await cacheDataLoaded;
          const onNext = (value: { data: StreamConversationSubscription }) => {
            if (value.data.StreamConversation) {
              dispatch(
                getConversationApi.util.updateQueryData(
                  "GetConversations",
                  {
                    filter: {
                      pageable: arg.pageable,
                      pmsPropertyIds: arg.propertyIds,
                      filters: transformFiltersToGraphqlFilters(
                        Object.keys(arg.filters).map((key) => arg.filters[key]),
                        skipTransformingValue
                      ),
                      ...(arg.tab && {
                        tab: arg.tab as MessagingTab
                      })
                    }
                  },
                  (draft) => {
                    Object.assign(draft, {
                      GetConversations: value.data.StreamConversation
                    });
                  }
                )
              );
            } else {
              console.warn("Subscription has returned falsy data.", value.data);
            }
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamConversationDocument,
                operationName: "StreamConversation",
                variables: {
                  filter: {
                    pageable: arg.pageable,
                    pmsPropertyIds: arg.propertyIds,
                    filters: transformFiltersToGraphqlFilters(
                      Object.keys(arg.filters).map((key) => arg.filters[key]),
                      skipTransformingValue
                    ),
                    ...(arg.tab && {
                      tab: arg.tab
                    })
                  }
                }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    }),
    SubscribeToMessages: build.query<Message[], MessagesSubscribeProps>({
      query: (arg) => ({
        document: StreamMessageDocument,
        variables: arg
      }),
      providesTags: [],
      async onCacheEntryAdded(arg, api) {
        const { cacheDataLoaded, cacheEntryRemoved, dispatch } = api;
        const client = createClient(MESSAGE_BASE_URL, arg);

        try {
          await cacheDataLoaded;

          const onNext = (value: { data: StreamMessageSubscription }) => {
            if (value.data.StreamMessage) {
              dispatch(
                getConversationDetailsApi.util.updateQueryData(
                  "GetConversationDetails",
                  {
                    filter: {
                      conversationId: arg.conversationId,
                      pageable: {
                        pageNumber: 0,
                        pageSize: 1,
                        sort: "createdAt,asc"
                      }
                    }
                  },
                  (draft) => {
                    const updatedDraft = draft.GetConversations?.content?.map((item) => {
                      if (item.conversationId === value.data.StreamMessage?.[0]?.conversationId) {
                        return {
                          ...item,
                          messages: value.data.StreamMessage
                        };
                      } else {
                        return item;
                      }
                    });

                    Object.assign(draft, {
                      GetConversations: {
                        content: updatedDraft,
                        totalElements: draft.GetConversations.totalElements
                      }
                    });
                  }
                )
              );
            } else {
              console.warn("Subscription has returned falsy data.", value.data);
            }
          };

          new Promise<void>((resolve, reject) => {
            client.subscribe(
              {
                query: StreamMessageDocument,
                operationName: "StreamMessage",
                variables: { conversationId: arg.conversationId }
              },
              {
                next: onNext,
                error: reject,
                complete: resolve
              }
            );
          }).then((value) => {
            console.warn("Ws closed ", value);
          });
        } catch (e) {
          console.error("WS subscription for V2 failed", e);
          client.dispose();
        }
        await cacheEntryRemoved;
        client.dispose();
      }
    })
  }),

  overrideExisting: false
});
