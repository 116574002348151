import type { FC } from "react";
import { Box, Card, CardContent, CardHeader, Grid, useTheme } from "@mui/material";
import { Heading2, ParagraphSmall } from "@likemagic-tech/sv-magic-library";

export interface StatProps {
  textPrimary: string;
  textSecondary: string;
}

export interface QuickStats9Props {
  title: string;
  onClick?: () => void;
  stat1: StatProps;
  stat2: StatProps;
  stat3: StatProps;
}

const Item: FC<React.PropsWithChildren<{ stat: StatProps }>> = ({ stat }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={4}
      sm={4}
      xs={4}
      sx={{
        textAlign: "center"
      }}
    >
      <Heading2 color="textPrimary">{stat.textPrimary}</Heading2>
      <ParagraphSmall color="textSecondary" sx={{ fontWeight: 700, marginTop: theme.spacing(1) }}>
        {stat.textSecondary}
      </ParagraphSmall>
    </Grid>
  );
};
const QuickStats9: FC<React.PropsWithChildren<QuickStats9Props>> = ({
  onClick,
  title,
  stat1,
  stat2,
  stat3
}) => (
  <Box
    sx={{
      backgroundColor: "background.default"
    }}
  >
    <Card onClick={onClick} sx={{ cursor: "pointer" }}>
      <CardHeader title={<ParagraphSmall color="textSecondary">{title}</ParagraphSmall>} />
      <CardContent>
        <Grid alignItems="center" container justifyContent="space-between">
          <Item stat={stat1} />
          <Item stat={stat2} />
          <Item stat={stat3} />
        </Grid>
      </CardContent>
    </Card>
  </Box>
);

export default QuickStats9;
