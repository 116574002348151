import { Grid } from "@mui/material";
import { FC, useMemo } from "react";
import { useProperty } from "src/hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import PersonCard from "./reservation-details-tabs/person-card";
import { getGuestInsightInfoDataToShow } from "./reservation-details-tabs/reservation-details-guest-insight-utils";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";

const ReservationDetailsGuestInsight: FC<
  React.PropsWithChildren<{
    reservation: ReservationDetailsDTO;
  }>
> = ({ reservation }) => {
  const {
    booker,
    primaryGuest,
    travelBuddies,
    propertyId,
    idCheckStatus,
    primaryGuestSameAsBooker
  } = reservation || {};
  const { selectedProperty } = useProperty();
  const { t } = useTranslationWrapper();

  const { disabledCountriesForIdentification } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  const info = useMemo(
    () =>
      getGuestInsightInfoDataToShow(
        booker,
        primaryGuest,
        travelBuddies,
        idCheckStatus,
        primaryGuestSameAsBooker,
        selectedProperty?.details?.timeZone ?? "",
        t,
        disabledCountriesForIdentification
      ),
    [
      booker,
      primaryGuest,
      travelBuddies,
      idCheckStatus,
      primaryGuestSameAsBooker,
      selectedProperty,
      t,
      disabledCountriesForIdentification
    ]
  );

  return (
    <>
      {info?.map((profile) => (
        <Grid container py={2} key={`profile-card-${profile}`}>
          <Grid item xs={12}>
            <PersonCard personInfo={profile} propertyId={propertyId} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ReservationDetailsGuestInsight;
