import React, { FC } from "react";
import { PaymentOption } from "./domain/payment-option";
import { PaymentSendPaymentLinkComponent } from "./payment-component-handlers/payment-send-payment-link-component";
import { PaymentTerminalComponent } from "./payment-component-handlers/payment-terminal-component";
import { Price } from "../../../domain/price";
import { PaymentPreAuthorizationComponent } from "./payment-component-handlers/payment-pre-authorization-component";
import { PaymentCashComponent } from "./payment-component-handlers/payment-cash-component";
import { PaymentTokenizedCardComponent } from "./payment-component-handlers/payment-tokenized-card-component";
import { PaymentMethodDataItemType } from "./payment-component-handlers/use-payment-method-options";

export interface PaymentComponentProps {
  onSuccess: () => void;
  onFailed: () => void;
  magicId: string;
  price: Price;
  propertyId?: string;
  isPreAuthorisation: boolean;
  folioMetadata?: any;
  paymentOption?: PaymentMethodDataItemType | null;
}

interface PaymentComponentAdditionalProps extends PaymentComponentProps {}

const PaymentOptionHandledByComponent = [PaymentOption.PaymentLink, PaymentOption.TerminalPayment];

export const isPaymentOptionHandledByComponent = (paymentOption: PaymentOption) =>
  PaymentOptionHandledByComponent.includes(paymentOption);

export const PaymentComponent: FC<PaymentComponentAdditionalProps> = (props) => {
  switch (props.paymentOption?.type) {
    case PaymentOption.PaymentLink:
      return <PaymentSendPaymentLinkComponent {...props} />;
    case PaymentOption.TerminalPayment:
      return <PaymentTerminalComponent {...props} />;
    case PaymentOption.Cash:
      return <PaymentCashComponent {...props} />;
    case PaymentOption.PreAuthorization:
      return <PaymentPreAuthorizationComponent {...props} />;
    case PaymentOption.StoredPaymentMethod:
      return <PaymentTokenizedCardComponent {...props} />;
    default:
      return <></>;
  }
};
