import React, { FC, useCallback, useMemo } from "react";
import { Alert, Box, FormControlLabel, Grid, useTheme } from "@mui/material";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { Price } from "../../../../domain/price";
import { Button, Checkbox, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Form, Formik } from "formik";
import { PaymentComponentProps } from "../payment-component";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useCardOnfilePaymentMutation } from "src/graphql/mutations/create-card-on-file-payment.generated";
import { parsePricePickerAmountToAmountInCents } from "../../../../utils/price";
import { InfoIcon } from "../../../../icons/info.icon";

export const PaymentTokenizedCardComponent: FC<PaymentComponentProps> = ({
  paymentOption,
  price,
  magicId,
  propertyId,
  folioMetadata,
  onSuccess
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const initialValues = {
    price: {
      ...price,
      amount: price.amount / 100
    },
    surcharges: true
  };

  const cardOnFileId = useMemo(
    () =>
      paymentOption?.__typename === "StoredPaymentMethod" ? paymentOption.config?.id : undefined,
    [paymentOption]
  );

  const pointOfSales = useMemo(
    () =>
      paymentOption?.__typename === "StoredPaymentMethod" ? paymentOption.pointOfSales : undefined,
    [paymentOption]
  );

  const [cardOnFilePaymentAction] = useCardOnfilePaymentMutation();

  const payWithCardOnFile = useCallback(
    (values: { price: Price; surcharges: boolean }) => {
      if (cardOnFileId) {
        cardOnFilePaymentAction({
          cardOnFilePaymentRequest: {
            token: cardOnFileId,
            magicId,
            pmsPropertyId: propertyId ?? "",
            shopperReference: magicId,
            amount: {
              amount: parsePricePickerAmountToAmountInCents(values.price.amount),
              currency: values.price.currency
            },
            pointOfSales: pointOfSales ?? true,
            targetFolioMetadata: folioMetadata,
            applySurcharges: values.surcharges
          }
        })
          .unwrap()
          .then(onSuccess);
      } else {
        console.warn("Field cardOnFileId is not configured properly.");
      }
    },
    [
      onSuccess,
      cardOnFilePaymentAction,
      cardOnFileId,
      magicId,
      propertyId,
      folioMetadata,
      pointOfSales
    ]
  );
  const handleSubmit = (props: { price: Price; surcharges: boolean }) => {
    payWithCardOnFile(props);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Form id="card-on-file-payment">
          <Grid item xs={12} mb={2}>
            <PricePicker
              prefix="price"
              value={formik.values.price}
              onChange={formik.handleChange}
            />
            <Box ml={0.5}>
              <FormControlLabel
                label={t("labels__surcharges")}
                control={
                  <Checkbox
                    id={"surcharges"}
                    name={"surcharges"}
                    checked={formik.values.surcharges}
                    onChange={formik.handleChange}
                  />
                }
              />
            </Box>
          </Grid>
          {formik.values.price.amount < 0 && (
            <Alert
              icon={<InfoIcon sx={{ color: theme.palette.info.main }} />}
              sx={{
                alignItems: "center",
                backgroundColor: theme.palette.info.light,
                mb: 2,
                mt: 1
              }}
            >
              <ParagraphSmall>{t("labels_make_unreferenced_negative_payment")}</ParagraphSmall>
            </Alert>
          )}
          <Grid item xs={12} textAlign="center">
            <Button sx={{ width: "50%" }} type="submit">
              {t("buttons__pay")}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
