import { FC, useMemo } from "react";
import { ChipStatus } from "../../components/chip/ChipStatus";
import { ChipButton } from "../../components/chip/ChipButton";
import { ReportStatus } from "../../domain/report";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

interface BreakfastStatusPreviewProps {
  status: ReportStatus;
  selected?: boolean;
  onClick: () => void;
}

export const BreakfastStatusPreview: FC<React.PropsWithChildren<BreakfastStatusPreviewProps>> = ({
  status,
  selected,
  onClick
}) => {
  const { t } = useTranslationWrapper();

  const labelMap = useMemo(() => {
    return {
      [ReportStatus.BOOKED]: t("labels__performed_service_" + ReportStatus.BOOKED),
      [ReportStatus.OBTAINED]: t("labels__performed_service_" + ReportStatus.OBTAINED)
    };
  }, [t]);

  const map = {
    [ReportStatus.BOOKED]: ChipStatus.CRITICAL,
    [ReportStatus.OBTAINED]: ChipStatus.OK
  };
  return (
    <ChipButton
      status={map[status]}
      outlined={selected !== undefined && !selected}
      label={labelMap[status]}
      fullWidth={true}
      onClick={onClick}
    />
  );
};
