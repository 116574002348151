import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

interface DefaultLoaderProps {
  visible: boolean;
}

export const DefaultLoader: React.FC<React.PropsWithChildren<DefaultLoaderProps>> = ({
  visible
}) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 10000 }} open={visible}>
      <CircularProgress size={100} />
    </Backdrop>
  );
};
