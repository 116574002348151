import { createClient as createClientWS } from "graphql-ws";

export type Channel = "redux" | "general";

export interface Message {
  id: number;
  channel: Channel;
  userName: string;
  text: string;
}

const getWsGraphQLUrl = (baseUrl: string) => {
  const protocol = window.location.protocol === "http:" ? "ws:" : "wss:";
  const host = window.location.host;
  return `${protocol}//${host}${baseUrl}`;
};

export const createClient = (
  baseUrl: string,
  arg: { apiKey?: string; tenantId: string; authorization?: string }
) =>
  createClientWS({
    url: getWsGraphQLUrl(baseUrl),
    keepAlive: 30000,
    connectionParams: {
      "sk-magic-api-key": arg.apiKey,
      "tenant-id": arg.tenantId,
      Authorization: `Bearer ${arg.authorization}`
    }
  });
