import React, { FC, useMemo, useState } from "react";
import { grey } from "@mui/material/colors";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import {
  Checkbox,
  Paragraph,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { ActionsType, AddActionsButtons } from "./add-actions-buttons";
import { isFolioClosed } from "./payment-utils";
import { ChecklistRtl } from "@mui/icons-material";
import { formatDateTime } from "../../../utils/timezoned-date";
import { PricePreview } from "../../../components/price-preview/price-preview";
import { ChargeActionsMenu } from "./action-menus/charge-actions-menu";
import { FolioPreview } from "../../../store/endpoints/reservation-table.endpoints";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { AddChargeModal } from "./modals/add-charge-modal";
import { useOrderItemTransactionTypes } from "../../../hooks/use-order-item-transaction-types";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";

interface FolioItemsDetailsChargesProps {
  folios: FolioPreview[];
  folio: FolioPreview;
  reservationId: string;
  propertyTimeZone: string;
  propertyId: string;
  setShowPaymentOption: (value: boolean) => void;
}

export const FolioItemsDetailsCharges: FC<FolioItemsDetailsChargesProps> = ({
  folio,
  folios,
  reservationId,
  propertyTimeZone,
  propertyId,
  setShowPaymentOption
}) => {
  const { t } = useTranslationWrapper();
  const [selectedCharges, setSelectedCharges] = useState<string[]>([]);
  const [chargesSelectionEnabled, setChargesSelectionEnabled] = useState(false);
  const [openAddChargesModal, setOpenAddChargesModal] = useState(false);
  const isMobile = useIsMobile();

  const { data: orderItemTransactionTypes, isLoading } =
    useOrderItemTransactionTypes({
      pmsPropertyId: propertyId ?? ""
    }) ?? [];

  const handleAddChargeCloseModal = () => {
    setOpenAddChargesModal(false);
  };

  const sortedFolioCharges = useMemo(() => {
    return folio.charges.sort((a, b) => {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    });
  }, [folio.charges]);

  return (
    <>
      <Grid
        item
        md={6}
        xs={12}
        p={2}
        sx={{ [isMobile ? "borderBottom" : "borderRight"]: `1px solid ${grey[300]}` }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <ParagraphBold>{t("labels__charges")}</ParagraphBold>
          <AddActionsButtons
            type={ActionsType.Charge}
            addDisabled={isFolioClosed(folio)}
            showActions={chargesSelectionEnabled}
            folio={folio}
            folios={folios}
            reservationId={reservationId}
            propertyId={propertyId}
            selectedIds={selectedCharges}
            onAddClick={() => {
              setShowPaymentOption(false);
              setOpenAddChargesModal(true);
            }}
            onDoneClick={() => {
              setSelectedCharges([]);
              setChargesSelectionEnabled(false);
            }}
          />
        </Grid>

        <Grid container justifyContent="space-between" alignItems="center">
          <ParagraphSmall color={grey[600]}>{t("labels__charge_date")}</ParagraphSmall>
          <Box display="flex" alignItems="center" justifyContent="center" mr={-1}>
            <ParagraphSmall color={grey[600]} mr={0.25}>
              {t("labels__amount")}
            </ParagraphSmall>
            {!chargesSelectionEnabled && (
              <IconButton
                sx={{ px: 0.75, py: 0 }}
                size="small"
                color="primary"
                onClick={() => setChargesSelectionEnabled(true)}
                disableRipple
                disabled={folio.charges.length < 2}
              >
                <ChecklistRtl />
              </IconButton>
            )}
            {chargesSelectionEnabled && (
              <Checkbox
                sx={{ px: 1, py: 0.25 }}
                id="select-all-charges"
                checked={folio.charges.every((charge) => selectedCharges.includes(charge.pmsId))}
                onChange={(event, checked) => {
                  if (checked) {
                    setSelectedCharges(folio.charges.map((charge) => charge.pmsId));
                  } else {
                    setSelectedCharges([]);
                  }
                }}
                disableRipple
              />
            )}
          </Box>
        </Grid>

        <Divider sx={{ my: 1 }} />

        {sortedFolioCharges.length ? (
          sortedFolioCharges.map((charge, index) => (
            <div key={`${charge.id}-${index}`}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <Paragraph>{charge.name}</Paragraph>
                  {charge.subName && (
                    <ParagraphSmall color={grey[600]}>{charge.subName}</ParagraphSmall>
                  )}
                  {charge.note && <ParagraphSmall color={grey[600]}>{charge.note}</ParagraphSmall>}
                  {charge.reference && (
                    <ParagraphSmall color={grey[600]}>{charge.reference}</ParagraphSmall>
                  )}
                  <ParagraphSmall color={grey[600]}>
                    {formatDateTime(charge.created, propertyTimeZone)}
                  </ParagraphSmall>
                </Grid>
                <Grid
                  item
                  xs={6}
                  textAlign="right"
                  height={20}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <PricePreview price={charge.price} />
                  {!chargesSelectionEnabled && (
                    <ChargeActionsMenu
                      reservationId={reservationId}
                      orderItemPmsIds={[charge.pmsId]}
                      folioId={folio.id}
                      folioMetadata={folio.metadata}
                      isFolioClosed={isFolioClosed(folio)}
                      folios={folios}
                      hasSelect={folio.charges.length > 1}
                      onSelectCharge={() => {
                        setChargesSelectionEnabled(true);
                        setSelectedCharges([...selectedCharges, charge.pmsId]);
                      }}
                      propertyId={propertyId}
                    />
                  )}
                  {chargesSelectionEnabled && (
                    <Checkbox
                      sx={{ pr: 0, pl: 1.5, py: 0.25 }}
                      id={`select-${charge.pmsId}`}
                      checked={selectedCharges.includes(charge.pmsId)}
                      onChange={(_, checked) => {
                        if (checked) {
                          setSelectedCharges([...selectedCharges, charge.pmsId]);
                        } else {
                          setSelectedCharges(selectedCharges.filter((id) => id !== charge.pmsId));
                        }
                      }}
                      disableRipple
                    />
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
            </div>
          ))
        ) : (
          <ParagraphSmall color={grey[600]}>{t("labels__no_items_available")} </ParagraphSmall>
        )}
      </Grid>
      {openAddChargesModal && (
        <AddChargeModal
          onClose={handleAddChargeCloseModal}
          orderItemTransactionTypes={orderItemTransactionTypes}
          isLoading={isLoading}
          reservationId={reservationId}
          folio={folio}
        />
      )}
    </>
  );
};
