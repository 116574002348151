import React, { FC } from "react";
import { Box } from "@mui/material";
import { DoorProvider, KeySlider } from "@likemagic-tech/sv-magic-library";
import { OfflinePinButton } from "./keys/offline-pin-button";
import { AvailableDoor } from "../domain/available-door";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useSelector } from "../store";
import { selectAvailableDoorsStateById } from "../slices/available-doors-state.slice";

interface KeyComponentProps {
  doorData: AvailableDoor;
  onSuccess: (door: AvailableDoor) => Promise<void>;
  keySliderRefs: any;
  doorProvider?: DoorProvider;
}

export const KeyComponent: FC<React.PropsWithChildren<KeyComponentProps>> = ({
  doorData,
  onSuccess,
  doorProvider,
  keySliderRefs
}) => {
  const { t } = useTranslationWrapper();
  const doorState = useSelector((state) => selectAvailableDoorsStateById(state, doorData.id));

  return (
    <Box sx={{ minHeight: 48 }}>
      {doorProvider === DoorProvider.OFFLINE_PIN ||
      doorProvider === DoorProvider.BURGWACHTER ||
      doorProvider === DoorProvider.BURGWACHTER_LEGACY ||
      doorData.pin ? (
        <OfflinePinButton doorData={doorData} />
      ) : (
        <KeySlider
          ref={(el) => (keySliderRefs.current[doorData.id] = el)}
          onSuccess={() => onSuccess(doorData)}
          unlocked={!!doorState?.unlocked}
          error={doorState?.error}
          labels={{
            slideToUnlock: t("labels__slide_to_unlock"),
            unlocked: t("labels__unlocked"),
            error: t("labels__error")
          }}
        />
      )}
    </Box>
  );
};
