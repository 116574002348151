import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { Price } from "../domain/price";

export interface PaymentSliceState {
  status: EntityStateStatus;
  selectedTerminalId?: string;
  paymentDTO: {
    price?: Price;
  };
}

const initialState: PaymentSliceState = {
  status: EntityStateStatus.IDLE,
  paymentDTO: { price: undefined }
};
export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSelectedTerminalId: (state, action: PayloadAction<string>) => {
      state.selectedTerminalId = action.payload;
    },
    setPriceToPay: (state, action: PayloadAction<Price>) => {
      state.paymentDTO.price = action.payload;
    }
  }
});

export const { setSelectedTerminalId, setPriceToPay } = paymentSlice.actions;

const selectSelf: Selector<RootState, PaymentSliceState> = (state: RootState) =>
  state[paymentSlice.name];

export const selectSelectedTerminalId = createSelector(selectSelf, (res) => res.selectedTerminalId);
export const selectPaymentDTO = createSelector(selectSelf, (res) => res.paymentDTO);
