import type { FC } from "react";
import { useMemo } from "react";
import QuickStats9 from "../../widgets/quick-stats/QuickStats9";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { OverviewItemParams } from "../../../pages/dashboard/HostOverview";
import { ReportStatus } from "../../../domain/report";
import { useFetchBreakfast } from "../../../pages/breakfast/use-fetch-breakfast";
import { useSelector } from "../../../store";
import { pickerDateSelector } from "../../../slices/date-picker";
import { useProperty } from "../../../hooks/use-property";

interface OverviewBreakfastCardProps extends OverviewItemParams {}

const OverviewBreakfastCard: FC<React.PropsWithChildren<OverviewBreakfastCardProps>> = ({
  navigate
}) => {
  const { t } = useTranslationWrapper();

  const date = useSelector(pickerDateSelector);
  const { selectedProperty } = useProperty();
  const { breakfastReports } = useFetchBreakfast({
    propertyId: selectedProperty?.propertyId,
    date
  });

  const totalCount = useMemo(() => {
    return breakfastReports.reduce((count, report) => count + report.count, 0);
  }, [breakfastReports]);
  const performedCount = useMemo(() => {
    return breakfastReports
      .filter((report) => report.performed)
      .reduce((count, report) => count + report.count, 0);
  }, [breakfastReports]);
  const pendingCount = useMemo(() => totalCount - performedCount, [totalCount, performedCount]);

  return (
    <>
      <QuickStats9
        title={t("title__breakfast")}
        onClick={() => navigate("/breakfast")}
        stat1={{
          textPrimary: pendingCount.toString(),
          textSecondary: t("labels__performed_service_" + ReportStatus.BOOKED)
        }}
        stat2={{
          textPrimary: performedCount.toString(),
          textSecondary: t("labels__performed_service_" + ReportStatus.OBTAINED)
        }}
        stat3={{
          textPrimary: totalCount.toString(),
          textSecondary: t("labels__total")
        }}
      />
    </>
  );
};

export default OverviewBreakfastCard;
