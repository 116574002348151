import { Player } from "@lottiefiles/react-lottie-player";
import React, { FC } from "react";
import { DoorProvider } from "@likemagic-tech/sv-magic-library";

export const AssignKeyAnimation: FC<React.PropsWithChildren<unknown>> = () => (
  <Player src="/animations/card-tag-hand-animation.json" autoplay={false} loop={false} />
);

export const AssignKeyAnimationRunning: FC<
  React.PropsWithChildren<{ doorProvider?: DoorProvider }>
> = ({ doorProvider }) => {
  const animationSrc = () => {
    switch (doorProvider) {
      case DoorProvider.SALTO:
        return "/animations/card-tag-red-animation.json";
      case DoorProvider.FOUR_SUITES:
        return "/animations/card-tag-reader-four-suites.json";
      case DoorProvider.HAFELE:
        return "/animations/card-tag-reader-hafele.json";
      default:
        return "/animations/card-tag-green-animation.json";
    }
  };

  return <Player src={animationSrc()} autoplay={true} loop={true} />;
};
