import type { FC, ReactNode } from "react";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";

interface RoleBasedGuardProps {
  children: ReactNode;
  roles: string[];
}

const RoleBasedGuard: FC<React.PropsWithChildren<RoleBasedGuardProps>> = (props) => {
  const { t } = useTranslationWrapper();
  const { children, roles } = props;
  const { hasRole } = useAuth();

  if (!roles.some((role) => hasRole(role))) {
    return <>{t("labels__not_accessible_with_your_role")}</>;
  }
  return <>{children}</>;
};

export default RoleBasedGuard;
