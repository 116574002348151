import { createApi, defaultSerializeQueryArgs } from "@reduxjs/toolkit/query/react";
import { CONTENT_TYPE_MULTIPART, magicBaseQuery } from "./base-query";

export type UploadFileResponse = { uuid: string };
type UploadFileArgument = { file: File; taskId?: number };
const TASK_MANAGEMENT_FILES_BASE_URL = "/api/task-management-service/files";
export const taskManagementFilesApi = createApi({
  reducerPath: "task-management-files",
  baseQuery: magicBaseQuery({ baseUrl: TASK_MANAGEMENT_FILES_BASE_URL }),
  tagTypes: ["Files"],
  serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
    const args = { ...queryArgs };
    delete args["skipSpinner" as keyof typeof args];

    return defaultSerializeQueryArgs({
      endpointName,
      queryArgs: args,
      endpointDefinition
    });
  },
  endpoints: (builder) => ({
    getFile: builder.query<string, { uuid: string }>({
      query: (arg) => ({
        headers: { method: "GET" },
        url: `/${arg.uuid}`,
        isBlob: true,
        cache: "no-cache"
      })
    }),
    uploadFile: builder.mutation<UploadFileResponse, UploadFileArgument>({
      query: ({ file, taskId }) => {
        return {
          url: taskId ? `?taskId=${taskId}` : "",
          headers: {
            method: "POST",
            "Content-Type": CONTENT_TYPE_MULTIPART
          },
          body: { file },
          isVoid: false
        };
      },
      invalidatesTags: ["Files"]
    })
  })
});

export const { useGetFileQuery, useLazyGetFileQuery, useUploadFileMutation } =
  taskManagementFilesApi;
