import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Paragraph, ParagraphSmallBold, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { Box, CircularProgress, IconButton, Tooltip, useTheme } from "@mui/material";
import { useProperty } from "../../hooks/use-property";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useSecondScreenId } from "../../features/reservations/manual-checkin/components/manual-checkin-step-registration-card/hooks/use-second-screen-device-id";
import { useStreamReservationOverviewDetails } from "../../features/reservations/manual-checkin/hooks/use-stream-reservation-overview-details";
import { usePerformSecondScreenActionMutation } from "../../graphql/mutations/perform-second-screen-action.generated";
import { SecondScreenEventType } from "../../graphql/generated/graphql";
import { useHandleShareToDeviceResponse } from "./use-handle-response";
import { useDispatch, useSelector } from "../../store";
import {
  selectSharingOnSecondScreenStatus,
  setSecondScreenStatus
} from "../../slices/second-screen.slice";
import {
  EntityStateStatus,
  isStatusIdle,
  isStatusLoading,
  isStatusSuccess
} from "src/domain/EntityStateStatus";
import ActionDropdown from "../action-dropdown/action-dropdown";
import {
  selectLastSelectedSecondScreenId,
  setLastSelectedSecondScreenId
} from "../../slices/recently-used-devices.slice";
import { Airplay, DomainVerification, InfoOutlined } from "@mui/icons-material";

export enum ShareToDeviceType {
  FOLIOS = "FOLIOS",
  REGISTRATION_CARD = "REGISTRATION_CARD"
}

interface ShareToDeviceProps {
  reservationId: string;
  type: string;
  onSelectDevice?: () => void;
  onStopSharing?: () => void;
  onConfirm?: () => void;
}

export const ShareToDevice: FC<ShareToDeviceProps> = ({
  reservationId,
  type,
  onStopSharing,
  onSelectDevice,
  onConfirm
}) => {
  const { t } = useTranslationWrapper();
  const reservationActionsAnchorRef = useRef<any>(null);
  const { selectedProperty } = useProperty();
  const { secondScreenDevices } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const theme = useTheme();
  const { setSecondScreenId, secondScreenId } = useSecondScreenId();
  const { subscribeAction, unsubscribeAction } = useStreamReservationOverviewDetails();
  const [performSecondScreenAction] = usePerformSecondScreenActionMutation();
  const dispatch = useDispatch();
  const secondScreenStatus = useSelector(selectSharingOnSecondScreenStatus);
  const lastSelectedSecondScreenId = useSelector(selectLastSelectedSecondScreenId);

  const handleOnStartSharing = useCallback(() => {
    dispatch(setSecondScreenStatus(EntityStateStatus.SUCCEEDED));
  }, [dispatch]);

  const handleOnConfirm = useCallback(() => {
    dispatch(setSecondScreenStatus(EntityStateStatus.IDLE));
    setSecondScreenId(undefined);
    onConfirm && onConfirm();
  }, [dispatch, onConfirm, setSecondScreenId]);

  const handleDeviceNotAvailable = useCallback(() => {
    dispatch(setSecondScreenStatus(EntityStateStatus.IDLE));
    setSecondScreenId(undefined);
    onStopSharing && onStopSharing();
  }, [dispatch, setSecondScreenId, onStopSharing]);

  const { handleResponse } = useHandleShareToDeviceResponse({
    onStartSharing: handleOnStartSharing,
    onConfirm: handleOnConfirm,
    onDeviceNotAvailable: handleDeviceNotAvailable
  });

  const performCancel = useCallback(async () => {
    if (secondScreenId && reservationId) {
      try {
        await performSecondScreenAction({
          pmsReservationId: reservationId,
          deviceId: secondScreenId,
          eventType: SecondScreenEventType.Canceled
        }).unwrap();
      } catch (e) {
        console.warn(e);
      }
    }
    setSecondScreenId(undefined);
    dispatch(setSecondScreenStatus(EntityStateStatus.IDLE));
    onStopSharing && onStopSharing();
  }, [
    dispatch,
    performSecondScreenAction,
    reservationId,
    secondScreenId,
    setSecondScreenId,
    onStopSharing
  ]);

  useEffect(() => {
    let subscribe: any;
    if (secondScreenId) {
      subscribe = subscribeAction({
        pmsReservationId: reservationId ?? "",
        handleResponse
      });
    }
    return () => {
      if (subscribe) {
        // @ts-ignore
        unsubscribeAction(subscribe?.queryCacheKey);
        performCancel();
      }
    };
  }, [
    handleResponse,
    reservationId,
    subscribeAction,
    unsubscribeAction,
    secondScreenId,
    performCancel
  ]);

  const listOfDeviceIds = useMemo(() => {
    return secondScreenDevices
      ? Object.keys(secondScreenDevices).map((key) => ({
          id: key,
          label: (
            <>
              <Paragraph>{secondScreenDevices[key].name}</Paragraph>
              {lastSelectedSecondScreenId === key ? (
                <Paragraph color={theme.palette.text.secondary} ml={0.5}>
                  {t("labels__recently_used")}
                </Paragraph>
              ) : null}
            </>
          )
        }))
      : [];
  }, [secondScreenDevices, t, lastSelectedSecondScreenId, theme.palette.text.secondary]);

  const cancelOption = useMemo(() => {
    return [
      {
        id: "cancel",
        label: t("labels__cancel")
      }
    ];
  }, [t]);

  const options = useMemo(() => {
    return isStatusLoading(secondScreenStatus) || isStatusSuccess(secondScreenStatus)
      ? cancelOption
      : listOfDeviceIds;
  }, [cancelOption, listOfDeviceIds, secondScreenStatus]);

  const onCancel = useCallback(() => {
    if (secondScreenId && reservationId) {
      performSecondScreenAction({
        pmsReservationId: reservationId,
        deviceId: secondScreenId,
        eventType: SecondScreenEventType.Canceled
      }).unwrap();
      dispatch(setSecondScreenStatus(EntityStateStatus.IDLE));
      setSecondScreenId(undefined);
      onStopSharing && onStopSharing();
    }
  }, [
    dispatch,
    onStopSharing,
    performSecondScreenAction,
    reservationId,
    secondScreenId,
    setSecondScreenId
  ]);

  const onSelect = useCallback(
    async (secondScreenId: string) => {
      try {
        dispatch(setLastSelectedSecondScreenId(secondScreenId));
        await performSecondScreenAction({
          pmsReservationId: reservationId ?? "",
          deviceId: secondScreenId ?? "",
          eventType:
            type === ShareToDeviceType.FOLIOS
              ? SecondScreenEventType.VerifyBill
              : SecondScreenEventType.VerifyAndSign,
          // @ts-ignore
          skipSpinner: true
        }).unwrap();
        setSecondScreenId(secondScreenId);
        onSelectDevice && onSelectDevice();
      } catch (e) {
        dispatch(setSecondScreenStatus(EntityStateStatus.IDLE));
      }
    },
    [setSecondScreenId, onSelectDevice, reservationId, performSecondScreenAction, type, dispatch]
  );

  const handleAction = useCallback(
    (selectedItemId: string) => {
      dispatch(setSecondScreenStatus(EntityStateStatus.LOADING));
      if (secondScreenStatus !== EntityStateStatus.IDLE) {
        onCancel();
      } else {
        onSelect(selectedItemId);
      }
    },
    [dispatch, onSelect, secondScreenStatus, onCancel]
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ActionDropdown options={options} handleOption={handleAction}>
        <Box
          data-stop-propagation
          ref={reservationActionsAnchorRef}
          display="flex"
          alignItems="center"
          color={theme.palette.info.main}
        >
          {isStatusSuccess(secondScreenStatus) && <DomainVerification />}
          {isStatusIdle(secondScreenStatus) && <Airplay />}
          {isStatusLoading(secondScreenStatus) && <CircularProgress size={24} color="info" />}
          <ParagraphSmallBold color="inherit" lineHeight="unset" mr={0.25} ml={0.75}>
            {isStatusSuccess(secondScreenStatus)
              ? `${t(`labels__sharing_${type}_on`)} ${secondScreenId}`
              : t(`labels__share_${type}_to_device`)}
          </ParagraphSmallBold>
        </Box>
      </ActionDropdown>

      {isStatusSuccess(secondScreenStatus) && (
        <Tooltip title={t(`labels__leaving_${type}_stops_sharing`)}>
          <IconButton onClick={() => null} disableRipple sx={{ pl: 0 }}>
            <InfoOutlined fontSize="small" color="info" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
