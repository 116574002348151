import { FC, useContext } from "react";
import { TenantContext, THEMES } from "@likemagic-tech/sv-magic-library";
import { StaykooookLoader } from "./staykooookLoader";
import { DefaultLoader } from "./default-loader";

export const Loader: FC<React.PropsWithChildren<{ visible: boolean }>> = ({ visible }) => {
  const { theme } = useContext(TenantContext);

  return theme === THEMES.STAYKOOOK_THEME ? (
    <StaykooookLoader visible={visible} />
  ) : (
    <DefaultLoader visible={visible} />
  );
};
