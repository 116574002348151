import * as Types from "../generated/graphql";

import { TaskFragmentDoc } from "../fragment/TaskFragment.generated";
import { ActorFragmentDoc } from "../fragment/Actor.generated";
import { tasksApi } from "../tasks.api";
export type GetRelatedToQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  pmsReservationId: Types.Scalars["String"]["input"];
}>;

export type GetRelatedToQuery = {
  __typename?: "Query";
  GetRelatedToTasks: Array<{
    __typename?: "TaskDto";
    id: number;
    title: string;
    description?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    type: Types.TaskType;
    due?: any | null;
    status: Types.Status;
    priority: Types.Priority;
    parentId?: number | null;
    subtasks?: Array<{
      __typename?: "TaskDto";
      id: number;
      title: string;
      parentId?: number | null;
      description?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      type: Types.TaskType;
      due?: any | null;
      status: Types.Status;
      priority: Types.Priority;
      attachments?: Array<{
        __typename?: "Attachment";
        uuid: string;
        contentType: string;
        fileName: string;
        originalFileName: string;
      }> | null;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: Types.ActorType;
        displayName?: string | null;
      } | null;
      assigneeGroup?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: Types.ActorType;
        displayName?: string | null;
      } | null;
      reporter: {
        __typename?: "Actor";
        actorId?: string | null;
        actorType: Types.ActorType;
        displayName?: string | null;
      };
      links?: {
        __typename?: "Links";
        pmsReservationIds?: Array<string> | null;
        pmsDepartingReservationId?: string | null;
        pmsArrivingReservationId?: string | null;
        pmsUnitIds?: Array<string> | null;
        reservations?: Array<{
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
        }> | null;
        departingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
        } | null;
        arrivingReservation?: {
          __typename?: "Reservation";
          displayableReservationId: string;
          pmsReservationId: string;
        } | null;
        units?: Array<{
          __typename?: "Unit";
          pmsUnitId: string;
          name: string;
          floor?: string | null;
        }> | null;
      } | null;
      subtasks?: Array<{
        __typename?: "TaskDto";
        id: number;
        title: string;
        parentId?: number | null;
      }> | null;
      parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
    }> | null;
    attachments?: Array<{
      __typename?: "Attachment";
      uuid: string;
      contentType: string;
      fileName: string;
      originalFileName: string;
    }> | null;
    assignee?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: Types.ActorType;
      displayName?: string | null;
    } | null;
    assigneeGroup?: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: Types.ActorType;
      displayName?: string | null;
    } | null;
    reporter: {
      __typename?: "Actor";
      actorId?: string | null;
      actorType: Types.ActorType;
      displayName?: string | null;
    };
    links?: {
      __typename?: "Links";
      pmsReservationIds?: Array<string> | null;
      pmsDepartingReservationId?: string | null;
      pmsArrivingReservationId?: string | null;
      pmsUnitIds?: Array<string> | null;
      reservations?: Array<{
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
      }> | null;
      departingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
      } | null;
      arrivingReservation?: {
        __typename?: "Reservation";
        displayableReservationId: string;
        pmsReservationId: string;
      } | null;
      units?: Array<{
        __typename?: "Unit";
        pmsUnitId: string;
        name: string;
        floor?: string | null;
      }> | null;
    } | null;
    parent?: { __typename?: "TaskDto"; id: number; title: string } | null;
  }>;
};

export const GetRelatedToDocument = `
    query GetRelatedTo($pmsPropertyId: String!, $pmsReservationId: String!) {
  GetRelatedToTasks(
    pmsPropertyId: $pmsPropertyId
    pmsReservationId: $pmsReservationId
  ) {
    ...Task
    subtasks {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}
${ActorFragmentDoc}`;

const injectedRtkApi = tasksApi.injectEndpoints({
  endpoints: (build) => ({
    GetRelatedTo: build.query<GetRelatedToQuery, GetRelatedToQueryVariables>({
      query: (variables) => ({ document: GetRelatedToDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetRelatedToQuery, useLazyGetRelatedToQuery } = injectedRtkApi;
