import * as Types from "../generated/graphql";

export type PersonFragmentFragment = {
  __typename?: "Person";
  id: number;
  pmsId: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  gender?: Types.Gender | null;
  birthdate?: any | null;
  age?: number | null;
  isMinor?: boolean | null;
  preferredCommunicationChannel?: Types.CommunicationChannel | null;
  termsAndConditionsGeneral: boolean;
  termsAndConditionsOnline: boolean;
  termsAndConditionsMarketingConsent: boolean;
  termsAndConditionsMarketingConsentDoubleOptin: boolean;
  emergencyEvacuationHelpNeeded: boolean;
  enrolledInLoyaltyProgram: boolean;
  identificationDocumentNumber?: string | null;
  identificationDocumentType?: string | null;
  userProfileId?: number | null;
  preferredLanguage?: string | null;
  nationality?: string | null;
  vipCode?: string | null;
  publicTransportationTickets?: Array<{
    __typename?: "PublicTransportationTicket";
    code?: string | null;
    link?: string | null;
    name?: string | null;
    valid?: boolean | null;
    validUntil?: any | null;
    ownerMagicId?: string | null;
  } | null> | null;
  userProfile?: {
    __typename?: "UserProfile";
    isRecurring: boolean;
    userAccountUuid?: string | null;
    preferences: Array<{
      __typename?: "Preference";
      pmsId: string;
      pmsPreferenceCategoryId: string;
      name: string;
      description?: string | null;
      pmsPropertyId?: string | null;
    }>;
    notes: Array<{
      __typename?: "ProfileNote";
      pmsId: string;
      title?: string | null;
      text?: string | null;
      pmsPropertyId?: string | null;
      pmsProfileNoteCategoryId?: string | null;
      category?: { __typename?: "ProfileNoteCategory"; pmsId: string; name?: string | null } | null;
    }>;
  } | null;
  address?: {
    __typename?: "Address";
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    countryCode?: string | null;
    postalCode?: string | null;
    region?: string | null;
  } | null;
  membershipAccounts: Array<{
    __typename?: "MembershipAccount";
    membershipTypeCode?: string | null;
    membershipId?: string | null;
    membershipClass?: Types.MemberShipClassEnum | null;
    pmsId?: string | null;
  }>;
};

export const PersonFragmentFragmentDoc = `
    fragment PersonFragment on Person {
  id
  pmsId
  firstName
  lastName
  phone
  email
  gender
  birthdate
  age
  isMinor
  preferredCommunicationChannel
  publicTransportationTickets {
    code
    link
    name
    valid
    validUntil
    ownerMagicId
  }
  termsAndConditionsGeneral
  termsAndConditionsOnline
  termsAndConditionsMarketingConsent
  termsAndConditionsMarketingConsentDoubleOptin
  emergencyEvacuationHelpNeeded
  enrolledInLoyaltyProgram
  identificationDocumentNumber
  identificationDocumentType
  userProfileId
  userProfile {
    isRecurring
    userAccountUuid
    preferences {
      pmsId
      pmsPreferenceCategoryId
      name
      description
      pmsPropertyId
    }
    notes {
      pmsId
      title
      text
      pmsPropertyId
      pmsProfileNoteCategoryId
      category {
        pmsId
        name
      }
    }
  }
  preferredLanguage
  nationality
  address {
    addressLine1
    addressLine2
    city
    countryCode
    postalCode
    region
  }
  vipCode
  membershipAccounts {
    membershipTypeCode
    membershipId
    membershipClass
    pmsId
  }
}
    `;
