import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PricePreview } from "../../../components/price-preview/price-preview";
import { emptyPrice, Price } from "../../../domain/price";
import { Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { ReservationDetailsDTO } from "../../../store/endpoints/reservation-table.endpoints";
import { sumPrices } from "../../../utils/price";

export const PaymentFolioSelection: FC<{
  setPaymentPrice?: (price: Price) => void;
  reservation: ReservationDetailsDTO;
}> = ({ setPaymentPrice, reservation }) => {
  const { t } = useTranslationWrapper();

  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const isItemChecked = useCallback((itemId: string) => selectedItems[itemId], [selectedItems]);
  const folios = useMemo(() => reservation?.rateBreakDown ?? [], [reservation.rateBreakDown]);
  const isReadOnly = useMemo(() => !setPaymentPrice, [setPaymentPrice]);

  const perFolio = useMemo(
    () =>
      folios.map((folio) => {
        const items = folio.breakDownItems.flatMap((item) => item.items);
        return { items, id: folio.id };
      }),
    [folios]
  );

  const allItemsInFolioSelected = (folioId: string) => {
    return perFolio
      .find((folio) => folio.id === folioId)
      ?.items.every((_, itemIndex) => isItemChecked(`${folioId}-${itemIndex}`));
  };

  const updateItemCheckbox = (itemIndex: string, checked: boolean) => {
    setSelectedItems({ ...selectedItems, [itemIndex]: checked });
  };

  const updateFolioCheckbox = (folioId: string, checked: boolean) => {
    const folio = perFolio.find((folio) => folio.id === folioId);

    if (folio) {
      setSelectedItems({
        ...selectedItems,
        ...folio.items.reduce(
          (acc, _, itemIndex) => ({ ...acc, [`${folioId}-${itemIndex}`]: checked }),
          {}
        )
      });
    }
  };

  const allItemsSelected = useMemo(() => {
    return perFolio.every((folio) =>
      folio.items.every((_, itemIndex) => isItemChecked(`${folio.id}-${itemIndex}`))
    );
  }, [perFolio, isItemChecked]);

  const selectAll = (checked: boolean) => {
    setSelectedItems(
      perFolio.reduce(
        (acc, folio) => ({
          ...acc,
          ...folio.items.reduce((acc, _, itemIndex) => {
            return { ...acc, [`${folio.id}-${itemIndex}`]: checked };
          }, {})
        }),
        {}
      )
    );
  };

  const totalSelectedPayment = useMemo(() => {
    const selectedPrices = perFolio.flatMap((folio) =>
      folio.items
        .filter((_, itemIndex) => isItemChecked(`${folio.id}-${itemIndex}`))
        .map((item) => item.price)
    );

    return sumPrices(selectedPrices) ?? emptyPrice("AUD");
  }, [isItemChecked, perFolio]);

  useEffect(() => {
    if (setPaymentPrice) {
      setPaymentPrice({
        amount: Math.abs(totalSelectedPayment.amount),
        currency: totalSelectedPayment.currency
      });
    }
  }, [totalSelectedPayment, setPaymentPrice, isReadOnly]);

  return (
    <>
      {!isReadOnly && (
        <FormControlLabel
          sx={{ p: 1 }}
          control={
            <Checkbox
              onChange={(_, checked) => {
                selectAll(checked);
              }}
              color="primary"
              checked={allItemsSelected}
            />
          }
          label={t("labels__select_all")}
        />
      )}

      {perFolio.map((folio) => (
        <Accordion key={folio.id}>
          <AccordionSummary
            sx={{ px: 0 }}
            expandIcon={
              <Box mx={1}>
                <ExpandMoreIcon color="primary" />
              </Box>
            }
            aria-controls="last-conversations"
            id="last-conversations"
          >
            <Grid container justifyContent="space-between" alignItems="center">
              {!isReadOnly ? (
                <Grid item pl={2}>
                  <FormControlLabel
                    key={`folio-checkbox-${folio.id}-${allItemsInFolioSelected(folio.id)}`}
                    control={
                      <Checkbox
                        onChange={(_, checked) => {
                          updateFolioCheckbox(folio.id, checked);
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        color="primary"
                        checked={allItemsInFolioSelected(folio.id)}
                      />
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    label={folio.id}
                  />
                </Grid>
              ) : (
                <Box pl={2}>
                  <Paragraph>{folio.id}</Paragraph>
                </Box>
              )}
              <Grid item pl={2}>
                <PricePreview price={sumPrices(folio.items.map((item) => item.price))} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ my: 1 }} />
            {folio.items.map((item, itemIndex) => (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                key={`${folio.id}-${itemIndex}`}
              >
                {!isReadOnly ? (
                  <Grid item>
                    <FormControlLabel
                      key={`item-checkbox-${folio.id}-${allItemsInFolioSelected(
                        folio.id
                      )}-${isItemChecked(itemIndex.toString())}`}
                      control={
                        <Checkbox
                          onChange={(_, checked) => {
                            updateItemCheckbox(`${folio.id}-${itemIndex}`, checked);
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          color="primary"
                          checked={isItemChecked(`${folio.id}-${itemIndex}`)}
                        />
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      label={
                        <ParagraphSmall color="text.secondary">
                          {item.quantity} x {item.name}
                        </ParagraphSmall>
                      }
                    />
                  </Grid>
                ) : (
                  <ParagraphSmall color="text.secondary">
                    {item.quantity} x {item.name}
                  </ParagraphSmall>
                )}
                <Grid item>
                  <ParagraphSmall color="text.secondary">
                    <PricePreview price={item.price} />
                  </ParagraphSmall>
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
