import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const formatDate = (date: Date) => date.toISOString().split("T")[0];

const slice = createSlice({
  name: "datePicker",
  initialState: {
    date: formatDate(new Date())
  },
  reducers: {
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    }
  }
});

export const { setDate } = slice.actions;
export const { reducer } = slice;
export const pickerDateSelector = (state: RootState) => state.datePicker.date;
