import { ParagraphBold, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { Box, Button, Divider, Fade, styled } from "@mui/material";
import PropertySelector from "./sidebar/property-selector/property-selector";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { Apartment, ChevronLeft, ChevronRight } from "@mui/icons-material";

import MuiDrawer from "@mui/material/Drawer";
import { closedMixin, drawerWidth, openedMixin } from "./DashboardLayout";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { selectIsOpenSideNavigation, setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useDispatch, useSelector } from "../../store";
import SidebarSections from "./sidebar/SidebarSections";
import MobileSidebar from "./sidebar/MobileSidebar";
import { useIsMobile } from "src/hooks/use-is-mobile";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const DashboardSidebar = () => {
  const { t } = useTranslationWrapper();
  const { spacing, palette } = useTheme();
  const topSpacing = spacing(8);
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { features } = usePropertyConfig({});

  const setOpenSideNavigation = (openSidebar: boolean) => {
    dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
  };

  return !isMobile ? (
    <Drawer
      variant="permanent"
      anchor="left"
      open={openSideNavigation}
      PaperProps={{
        sx: {
          top: topSpacing,
          width: "100%",
          height: `calc(100% - ${topSpacing})`
        }
      }}
    >
      <Box display="inline-block" m={2} alignItems="center">
        {!openSideNavigation && (
          <Fade in={!openSideNavigation} timeout={1000}>
            <Box
              p={1}
              width={56}
              height={56}
              sx={{ bgcolor: "background.default", borderRadius: 2 }}
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              ml={"auto"}
              mr={"auto"}
            >
              <Apartment
                sx={{
                  color: grey[500],
                  width: 44,
                  height: 44,
                  cursor: "pointer"
                }}
                fontSize="large"
                onClick={() => setOpenSideNavigation(true)}
              />
            </Box>
          </Fade>
        )}
        <Fade in={openSideNavigation}>
          <Box display={openSideNavigation ? "flex" : "none"} width="100%">
            <PropertySelector />
          </Box>
        </Fade>
      </Box>
      <Divider />
      <SidebarSections />
      <Divider />

      <Box mt={1} textAlign="right">
        <Button
          disableRipple
          variant="ternary"
          sx={{
            textTransform: "none",
            "&:hover": { backgroundColor: "transparent" }
          }}
          onClick={() => setOpenSideNavigation(!openSideNavigation)}
        >
          {openSideNavigation ? (
            <>
              <ParagraphBold sx={{ color: palette.secondary.main }}>
                {t("labels__close")}
              </ParagraphBold>
              <ChevronLeft sx={{ color: palette.secondary.main }} />
            </>
          ) : (
            <>
              <ParagraphBold sx={{ color: palette.secondary.main }}>
                {t("labels__open")}
              </ParagraphBold>
              <ChevronRight sx={{ color: palette.secondary.main }} />
            </>
          )}
        </Button>
      </Box>

      {features?.tfeFeatureEnabled && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            bottom: 0
          }}
        >
          {openSideNavigation ? (
            <Fade in timeout={1000}>
              <img src="/likemagic-logo-black.svg" alt="Likemagic logo" />
            </Fade>
          ) : (
            <Box width="100%" px={3} pb={4}>
              <Fade in timeout={1000}>
                <img src="/likemagic-logo-circle.svg" alt="Likemagic logo circle" />
              </Fade>
            </Box>
          )}
        </Box>
      )}
    </Drawer>
  ) : (
    <MobileSidebar />
  );
};

export default DashboardSidebar;
