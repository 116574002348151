import { FC } from "react";
import { Conversation } from "../domain/conversation";
import { Badge, Box, Grid, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import { Chip, ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { formatDate, formatTime } from "src/utils/timezoned-date";
import { useProperty } from "src/hooks/use-property";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { channelIconMap, getMessageText, mapMessageConditionColors } from "../utils/utils";

export const ConversationRow: FC<{
  data: Conversation;
  onSelect: (conversationId: string) => void;
  isSelected: boolean;
}> = ({ data, onSelect, isSelected }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const lastMessage = data?.lastMessage?.[0];
  const ChannelIcon = channelIconMap(lastMessage?.channel?.platform);
  const { selectedProperty } = useProperty();
  const isMobile = useIsMobile();
  const { getProperty } = useProperty();

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={data.conversationId}
      onClick={() => onSelect(data.conversationId)}
      sx={{
        cursor: "pointer",
        backgroundColor: isSelected ? palette.background.default : null,
        "& .MuiTableCell-root": {
          px: 2
        }
      }}
    >
      <TableCell width="1%">
        <Box pl={1}>
          <Badge
            sx={{ "& .MuiBadge-badge": { top: -2, right: -2 } }}
            badgeContent={data.numberOfUnreadMessages}
          >
            <ChannelIcon />
          </Badge>
        </Box>
      </TableCell>
      <TableCell>
        <Grid container direction="row">
          <Grid item>
            <ParagraphBold
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "35vw" : "25vw"
              }}
              pb={0.5}
            >
              {data?.guest?.displayName}
            </ParagraphBold>
            <ParagraphSmall
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "35vw" : "25vw"
              }}
              color={palette.text.secondary}
            >
              {getMessageText(lastMessage)}
            </ParagraphSmall>
          </Grid>
        </Grid>
      </TableCell>
      {!isMobile && (
        <TableCell>
          <ParagraphSmall>
            {data?.pmsPropertyId && getProperty(data?.pmsPropertyId)
              ? getProperty(data?.pmsPropertyId)?.details?.name
              : t("labels__no_property")}
          </ParagraphSmall>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell>
          <ParagraphSmall>{data?.assignee?.displayName ?? t("labels__unassigned")}</ParagraphSmall>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell>
          <Chip
            color={mapMessageConditionColors(data.status)}
            label={t(`labels__conversation_status_${data.status}`)}
          />
        </TableCell>
      )}
      <TableCell>
        <Grid container direction="row">
          <ParagraphSmall>
            {formatDate(data?.lastReceivedMessage, selectedProperty?.details.timeZone)}
          </ParagraphSmall>
          <ParagraphSmall ml={0.5} color="text.secondary">
            {formatTime(data?.lastReceivedMessage, selectedProperty?.details.timeZone)}
          </ParagraphSmall>
        </Grid>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
