import { MaintenanceSlotType } from "src/domain/maintenance-slot-type";
import { UnitCondition } from "src/domain/Unit";
import OutOfService from "src/icons/OutOfService";
import OutOfInventory from "src/icons/OutOfInventory";
import OutOfOrder from "src/icons/OutOfOrder";

export const maintenanceTypeColorScheme = (
  maintenanceType?: MaintenanceSlotType,
  unitStatus?: { condition: UnitCondition; occupied: boolean }
) => {
  if (maintenanceType) {
    switch (maintenanceType) {
      case MaintenanceSlotType.OUT_OF_INVENTORY:
        return "info";
      case MaintenanceSlotType.OUT_OF_ORDER:
        return "error";
      case MaintenanceSlotType.OUT_OF_SERVICE:
        return "warning";
    }
  } else if (unitStatus) {
    switch (true) {
      case unitStatus.condition === UnitCondition.CLEAN && !unitStatus.occupied:
        return "success";
      case unitStatus?.condition === UnitCondition.CLEAN && unitStatus?.occupied:
      case unitStatus.condition === UnitCondition.DIRTY && unitStatus.occupied:
        return "error";
      case unitStatus.condition === UnitCondition.CLEAN_TO_BE_INSPECTED && unitStatus.occupied:
        return "warning";
    }
  } else {
    return "";
  }
};

export const maintenanceTypeIcon = (maintenanceType?: MaintenanceSlotType) => {
  switch (maintenanceType) {
    case MaintenanceSlotType.OUT_OF_INVENTORY:
      return (
        <OutOfInventory
          sx={{ color: `${maintenanceTypeColorScheme(maintenanceType)}.dark`, fontSize: 16 }}
          fontSize="small"
        />
      );
    case MaintenanceSlotType.OUT_OF_ORDER:
      return (
        <OutOfOrder
          sx={{ color: `${maintenanceTypeColorScheme(maintenanceType)}.dark`, fontSize: 16 }}
          fontSize="small"
        />
      );
    case MaintenanceSlotType.OUT_OF_SERVICE:
      return (
        <OutOfService
          sx={{ color: `${maintenanceTypeColorScheme(maintenanceType)}.dark`, fontSize: 16 }}
          fontSize="small"
        />
      );
    default:
      return <></>;
  }
};
