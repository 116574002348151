import React, { FC, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CopyToClipboardProps {
  textToCopy: string;
}

export const CopyToClipboard: FC<React.PropsWithChildren<CopyToClipboardProps>> = ({
  textToCopy
}) => {
  const { t } = useTranslationWrapper();
  const copyToClipboardCallback = useCallback(async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(textToCopy);
    }
  }, [textToCopy]);

  return (
    <Tooltip title={t("labels__copied_to_clipboard")}>
      <IconButton onClick={copyToClipboardCallback}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};
