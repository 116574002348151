import { Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  DoorProvider,
  Paragraph,
  Select,
  useDoorProviderConfig
} from "@likemagic-tech/sv-magic-library";
import { useProperty } from "../../hooks/use-property";
import { AssignKeyHandler } from "./assign-key-handler";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useDispatch, useSelector } from "../../store";
import {
  resetKeyAssignState,
  selectAssignKeyWithAPIStatus,
  selectAssignKeyWithWSStatus
} from "../../slices/assign-key";
import { EntityStateStatus } from "../../domain/EntityStateStatus";
import { ReservationActionsModal } from "../../features/reservations/reservation-details/details-tabs/reservation-details-tabs/reservation-actions-modal";
import { openBanner } from "../../slices/banner.slice";

interface AssignKeyModalProps extends ReservationActionsModal {
  onSuccess?: () => void;
}
enum AssignKeySteps {
  KEYS_INPUT = "KEYS_INPUT",
  KEYS_ASSIGNING = "KEYS_ASSIGNING"
}

export const AssignKeyModal: React.FC<React.PropsWithChildren<AssignKeyModalProps>> = ({
  isOpen,
  reservation,
  onClose,
  onSuccess
}) => {
  const { selectedProperty } = useProperty();
  const dispatch = useDispatch();
  const config = useDoorProviderConfig(selectedProperty?.propertyId ?? "");
  const [isDisabled, setIsDisabled] = useState(false);
  const [hiddenEncoder, setHiddenEncoder] = useState(false);
  const [numberOfKeys, setNumberOfKeys] = useState(1);
  const [currentKeyForAssigning, setCurrentKeyForAssigning] = useState(1);
  const [assignKeyStep, setAssignKeyStep] = useState(AssignKeySteps.KEYS_INPUT);

  //TODO This should be aligned on the BE & library side
  const encoderIds = useMemo(() => {
    if (config?.doorProvider === DoorProvider.SALTO) {
      return config.tagReaders;
    } else if (
      config?.doorProvider === DoorProvider.SALTO_SPACE_MOBILE_KEY ||
      config?.doorProvider === DoorProvider.SALTO_SPACE_ONLINE ||
      config?.doorProvider === DoorProvider.HAFELE ||
      config?.doorProvider === DoorProvider.FOUR_SUITES ||
      config?.doorProvider === DoorProvider.AA_VISIONLINE ||
      config?.doorProvider === DoorProvider.AA_VOSTIO ||
      config?.doorProvider === DoorProvider.GLUTZ ||
      config?.doorProvider === DoorProvider.DORMA_KABA
    ) {
      return config?.encoders;
    }
    return undefined;
  }, [config]);

  const [formSelectEncoderId, setFormSelectEncoderId] = useState<string>(
    encoderIds ? Object.keys(encoderIds)[0] : ""
  );

  const assignWithApiStatus = useSelector(selectAssignKeyWithAPIStatus);
  const assignWithWsStatus = useSelector(selectAssignKeyWithWSStatus);

  const encodeSuccess = useMemo(() => {
    return (
      assignWithApiStatus === EntityStateStatus.SUCCEEDED ||
      assignWithWsStatus === EntityStateStatus.SUCCEEDED
    );
  }, [assignWithApiStatus, assignWithWsStatus]);

  useEffect(() => {
    if (encodeSuccess) {
      onSuccess?.();
    }
  }, [encodeSuccess, onSuccess]);

  useEffect(() => {
    if (isOpen) {
      setHiddenEncoder(false);
    }
  }, [isOpen]);

  const hardwareIdForAssignKey = useMemo(() => {
    return encoderIds && Object.keys(encoderIds)?.length === 1
      ? Object.keys(encoderIds)[0]
      : formSelectEncoderId;
  }, [encoderIds, formSelectEncoderId]);

  const handleChangeEncoderId = (value: string): void => {
    setFormSelectEncoderId(value);
  };
  const { t } = useTranslationWrapper();

  const isFormInputLabelDisabled = (status: boolean) => {
    setIsDisabled(status);
  };

  const onEnrichOnClose = useCallback(() => {
    dispatch(resetKeyAssignState());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onEnrichOnClose}
      PaperProps={{
        sx: {
          maxWidth: "max-content",
          minWidth: (theme) => theme.spacing(30),
          minHeight: (theme) => theme.spacing(30)
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, fontWeight: 700 }}>
        {t("labels__reservation_action_modal_assign_key_button_ENCODE_KEY")}

        <IconButton
          onClick={onEnrichOnClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {assignKeyStep === AssignKeySteps.KEYS_INPUT && (
          <Grid container spacing={2}>
            <Grid item xs={12} mt={1}>
              {!encodeSuccess &&
                !hiddenEncoder &&
                encoderIds &&
                Object.keys(encoderIds).length > 1 && (
                  <Select
                    disabled={isDisabled}
                    value={formSelectEncoderId}
                    label={t("labels__reservation_action_modal_select_encoder_id_ENCODE_KEY")}
                    variant={"outlined"}
                    onChange={(e) => handleChangeEncoderId(e.target.value as string)}
                    options={Object.keys(encoderIds).map((encoderId) => ({
                      label: encoderIds[encoderId].name,
                      value: encoderId
                    }))}
                  />
                )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                type="number"
                fullWidth
                value={numberOfKeys}
                onChange={(e) => setNumberOfKeys(parseInt(e.target.value))}
                label={t("labels__key_amount")}
                InputProps={{
                  inputProps: {
                    min: 1
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={() => setAssignKeyStep(AssignKeySteps.KEYS_ASSIGNING)}
                disabled={numberOfKeys < 1}
              >
                {t("buttons__next")}
              </Button>
            </Grid>
          </Grid>
        )}

        {isOpen && assignKeyStep === AssignKeySteps.KEYS_ASSIGNING && (
          <Grid container key={`current-assigning-no-${currentKeyForAssigning}`} spacing={2}>
            <Grid item xs={12}>
              <Paragraph fontSize={24}>
                {t("labels__keys_assigned")}: {currentKeyForAssigning}/{numberOfKeys}
              </Paragraph>
            </Grid>

            <Grid item xs={12} minHeight={600}>
              <AssignKeyHandler
                reservation={reservation as any}
                tagReaderId={hardwareIdForAssignKey}
                onClose={onEnrichOnClose}
                isFormInputLabelDisabled={isFormInputLabelDisabled}
                automaticallyCall={(encoderIds && Object.keys(encoderIds)?.length === 1) ?? false}
                hideEncoder={setHiddenEncoder}
                onSuccess={() => {
                  const nextKey = currentKeyForAssigning + 1;
                  dispatch(resetKeyAssignState());
                  setCurrentKeyForAssigning(nextKey);
                  if (nextKey > numberOfKeys) {
                    onEnrichOnClose();
                    dispatch(
                      openBanner({
                        type: "success",
                        title: t("labels__keys_assigned_successfully")
                      })
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
