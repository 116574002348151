import { FC } from "react";
import { BatteryLevel as BatteryLevelEnum } from "../../domain/available-door";
import { BatteryFullIcon } from "../../components/icons/BatteryFullIcon";
import { BatteryLowIcon } from "../../components/icons/BatteryLowIcon";
import { BatteryUnknownIcon } from "../../components/icons/BatteryUnknownIcon";
import { BatteryNoneIcon } from "../../components/icons/BatteryNoneIcon";
import { BatteryCriticalIcon } from "../../components/icons/BatteryCriticalIcon";

export const BatteryLevel: FC<
  React.PropsWithChildren<{
    level?: BatteryLevelEnum;
  }>
> = ({ level }) => {
  if (!level) return null;

  switch (level) {
    case BatteryLevelEnum.FRESH:
    case BatteryLevelEnum.GOOD:
      // TODO: use color from theme, when it's updated to also contain this 'dark green'
      return <BatteryFullIcon sx={{ color: "#4CAF50" }} />;
    case BatteryLevelEnum.CRITICAL:
      return <BatteryCriticalIcon sx={{ color: "error.main" }} />;
    case BatteryLevelEnum.LOW:
      return <BatteryLowIcon sx={{ color: "error.main" }} />;
    case BatteryLevelEnum.NONE:
      return <BatteryNoneIcon />;
    case BatteryLevelEnum.UNKNOWN:
    default:
      return <BatteryUnknownIcon />;
  }
};
