import Paper from "@mui/material/Paper";
import { Grid, IconButton } from "@mui/material";
import { EmptyState, Heading2 } from "@likemagic-tech/sv-magic-library";
import { FC, useCallback, useEffect, useState } from "react";
import Plus from "../../../../icons/Plus";
import { PreferencesForProfile } from "../../types/profile";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { GuestPreferencesModal } from "src/features/reservations/reservation-details/details-tabs/reservation-details-tabs/preferences/guest-preferences-modal";
import { useProperty } from "src/hooks/use-property";
import CloseIcon from "@mui/icons-material/Close";
import { useProfileDrawer } from "../use-profile-drawer";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { DisplayPreference } from "src/features/reservations/reservation-details/details-tabs/reservation-details-tabs/rows-for-table-guest-overview/display-preference";
import { useDeletePreferenceFromProfile } from "src/hooks/use-delete-preference-from-profile";

interface ProfileDrawerPreferencesCardProps {
  preferences: PreferencesForProfile | undefined;
  userFullName: string;
}

export const ProfileDrawerPreferencesCard: FC<ProfileDrawerPreferencesCardProps> = ({
  preferences,
  userFullName
}) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const [addPreferencesModalOpened, setAddPreferencesModalOpened] = useState(false);
  const { selectedProperty } = useProperty();
  const [removePreferenceToProfile, resultRemoveToProfile] = useDeletePreferenceFromProfile();
  const { profileGuestIdentityId } = useProfileDrawer();

  const handleRemovePreference = useCallback(
    async (preferenceId: string) => {
      await removePreferenceToProfile({
        preferenceId: preferenceId,
        profileId: Number(profileGuestIdentityId)
      });
    },
    [profileGuestIdentityId, removePreferenceToProfile]
  );

  useEffect(() => {
    if (resultRemoveToProfile.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      resultRemoveToProfile.reset();
    }
  }, [resultRemoveToProfile, dispatch, t]);

  return (
    <Grid item xs={12}>
      <Paper elevation={0}>
        <Grid container gap={3} p={3}>
          <Grid container justifyContent="space-between" alignItems="end">
            <Grid item>
              <Heading2>{t(`labels__profiles_preferences`)}</Heading2>
            </Grid>
            <Grid item>
              <Grid container gap={2}>
                <IconButton onClick={() => setAddPreferencesModalOpened(true)}>
                  <Plus />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {preferences && preferences.length > 0 ? (
            <Grid container gap={1}>
              {preferences?.map((preference) => (
                <DisplayPreference
                  propertyId={selectedProperty?.propertyId ?? ""}
                  preference={preference.pmsId}
                  icon={<CloseIcon />}
                  onIconClick={() => handleRemovePreference(preference.pmsId)}
                  key={preference.pmsId}
                />
              ))}
            </Grid>
          ) : (
            <EmptyState title={t("labels__profile_no_preferences")} />
          )}
        </Grid>
      </Paper>
      {addPreferencesModalOpened && (
        <GuestPreferencesModal
          isOpen={addPreferencesModalOpened}
          propertyId={selectedProperty?.propertyId ?? ""}
          onClose={() => setAddPreferencesModalOpened(false)}
          userFullName={userFullName}
        />
      )}
    </Grid>
  );
};
