import { Alert } from "@mui/material";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import WarningRoundedIcon from "@mui/icons-material/Warning";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useMemo } from "react";

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning"
}

export interface NotificationProps {
  type: "error" | "success" | "info" | "warning";
  title: string;
  errorId?: string;
  onClick?: () => void;
  onClose?: () => void;
}

const iconStyle = () => ({
  width: 32,
  height: 32,
  padding: 4
});

const useStyles = makeStyles()(() => ({
  icon: {
    ...iconStyle()
  }
}));

export const ActionNotification: React.FC<React.PropsWithChildren<NotificationProps>> = (props) => {
  const { type, title, onClick, onClose } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslationWrapper();

  const iconMapping = {
    [NotificationType.ERROR]: <WarningRoundedIcon className={classes.icon} />,
    [NotificationType.SUCCESS]: <CheckCircleIcon className={classes.icon} />
  };

  const errorFromErrorId = useMemo(() => {
    const translateError = t(props.errorId ?? "");
    return translateError === (props.errorId ?? "") ? undefined : translateError;
  }, [props.errorId, t]);

  return (
    <>
      <Alert
        variant="filled"
        severity={type}
        iconMapping={iconMapping}
        sx={{
          cursor: "pointer",
          backgroundColor: theme.palette[type].main,
          alignItems: "center",
          borderRadius: theme.spacing(2)
        }}
        onClick={onClick}
        onClose={onClose}
      >
        <Paragraph sx={{ color: theme.palette.background.paper }}>
          {errorFromErrorId ?? title ?? t("errors_default")}
        </Paragraph>
      </Alert>
    </>
  );
};
