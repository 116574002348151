import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Box } from "@mui/material";
import { DialogModal } from "./dialog";
import { DialogTitleModal } from "./dialog-title";

interface ModalWithPropActionsProps {
  isOpen: boolean;
  onCancel: () => void;
  title: string;
  content: ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const ModalWithPropActions: FC<PropsWithChildren<ModalWithPropActionsProps>> = ({
  isOpen,
  onCancel,
  children,
  title,
  content,
  maxWidth
}) => {
  return (
    <DialogModal isOpen={isOpen} handleClose={onCancel} maxWidth={maxWidth}>
      <Box p={3} width="100%" display={"flex"} gap={1} flexDirection={"column"}>
        <DialogTitleModal title={title} onClose={onCancel} />
        <Box sx={{ padding: 0, pt: 1 }}>{content}</Box>

        {children}
      </Box>
    </DialogModal>
  );
};
