import { FC } from "react";
import { MoneyIcon } from "../../../components/icons/MoneyIcon";
import { CheckboxIcon } from "../../../components/icons/CheckboxIcon";
import { Chip } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import WalletIcon from "@mui/icons-material/Wallet";

export enum PaymentStatus {
  PAID = "PAID",
  OPEN = "OPEN",
  BALANCE = "BALANCE"
}

interface PaymentStatusFlagProps {
  status: PaymentStatus;
}

export const PaymentStatusFlag: FC<PaymentStatusFlagProps> = ({ status }) => {
  const { t } = useTranslationWrapper();

  const {
    color = "default",
    icon,
    label
  } = (() => {
    switch (status) {
      case PaymentStatus.PAID:
        return {
          color: "success",
          icon: <CheckboxIcon />,
          label: t(`labels__${status.toLowerCase()}`)
        };
      case PaymentStatus.OPEN:
        return {
          color: "warning",
          icon: <MoneyIcon />,
          label: t(`labels__${status.toLowerCase()}`)
        };
      case PaymentStatus.BALANCE:
        return {
          color: "info",
          icon: <WalletIcon />,
          label: t(`labels__${status.toLowerCase()}`)
        };
    }
  })();

  return <Chip color={color} label={label} leftIcon={icon} size="small" />;
};
