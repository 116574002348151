import { FC } from "react";
import { Grid } from "@mui/material";

export const ProfileDrawerCards: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Grid container pt={3} gap={3}>
      {children}
    </Grid>
  );
};
