import { Property } from "../../domain/property";

import { GetAllPropertiesQuery } from "../queries/GetProperty.generated";

export const transformPropertyV2ToCommonProperty = (
  arg: GetAllPropertiesQuery | undefined
): Property[] =>
  arg?.GetPropertiesByCity?.grouped
    ? arg?.GetPropertiesByCity?.grouped
        .flatMap((item) => item?.value)
        .map((item) => {
          return {
            propertyId: item?.pmsId ?? "",
            originalPropertyId: item?.pmsId ?? "",
            details: {
              id: item?.pmsId ?? "",
              name: item?.name ?? "",
              companyName: "",
              currencyCode: item?.defaultCurrency ?? "",
              description: "",
              timeZone: item?.timezone ?? ""
            },
            language: "en"
          };
        })
    : [];
