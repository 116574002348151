import { Chip, Heading1, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid } from "@mui/material";
import React, { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { HelmetWrapper } from "../features/helmet-wrapper/helmet-wrapper";

interface PageTitleProps {
  subTitle: string;
  title: string;
  beta?: boolean;
}

const PageTitle: FC<React.PropsWithChildren<PageTitleProps>> = (props) => {
  const { subTitle, title, beta } = props;
  const { t } = useTranslationWrapper();
  return (
    <>
      <HelmetWrapper title={title} />
      <Grid item>
        <ParagraphSmall color="textSecondary" gutterBottom={true}>
          {subTitle}
        </ParagraphSmall>
        <Grid container alignItems="center">
          <Grid item>
            <Heading1 color="textPrimary">{title}</Heading1>
          </Grid>
          {beta && (
            <Grid item mx={2}>
              <Chip color="primary" label={t("labels__beta")} size="small" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PageTitle;
