import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type GetEmployeesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetEmployeesQuery = {
  __typename?: "Query";
  GetEmployees: Array<{
    __typename?: "Employee";
    id: string;
    keycloakUUID?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  }>;
};

export const GetEmployeesDocument = `
    query GetEmployees {
  GetEmployees {
    id
    keycloakUUID
    firstName
    lastName
  }
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    GetEmployees: build.query<GetEmployeesQuery, GetEmployeesQueryVariables | void>({
      query: (variables) => ({ document: GetEmployeesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetEmployeesQuery, useLazyGetEmployeesQuery } = injectedRtkApi;
