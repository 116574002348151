import React, { useContext, useEffect, useMemo, useState } from "react";
import { initI18n } from "../i18n-config";
import enJSON from "../en.json";
import deJSON from "../de.json";
import esJSON from "../es.json";
import itJSON from "../it.json";
import ptJSON from "../pt.json";
import rsJSON from "../rs.json";
import trJSON from "../tr.json";
import uaJSON from "../ua.json";
import enMewsJSON from "../enMews.json";
import deMewsJSON from "../deMews.json";
import enOHIPJSON from "../enOHIP.json";
import enErrorsJSON from "../errors/enErrors.json";
import deErrorsJSON from "../errors/deErrors.json";
import { PmsType, TenantContext } from "@likemagic-tech/sv-magic-library";

export const InitI18n: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  const { pms } = useContext(TenantContext);

  const enFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return enJSON;
      case PmsType.MEWS:
        return { ...enJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...enJSON, ...enOHIPJSON };
      default:
        return enJSON;
    }
  }, [pms]);

  const deFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return deJSON;
      case PmsType.MEWS:
        return { ...deJSON, ...deMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return deJSON;
      default:
        return enJSON;
    }
  }, [pms]);

  const esFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return esJSON;
      case PmsType.MEWS:
        return { ...esJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...esJSON, ...enOHIPJSON };
      default:
        return esJSON;
    }
  }, [pms]);

  const itFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return itJSON;
      case PmsType.MEWS:
        return { ...itJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...itJSON, ...enOHIPJSON };
      default:
        return itJSON;
    }
  }, [pms]);

  const ptFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return ptJSON;
      case PmsType.MEWS:
        return { ...ptJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...ptJSON, ...enOHIPJSON };
      default:
        return ptJSON;
    }
  }, [pms]);

  const rsFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return rsJSON;
      case PmsType.MEWS:
        return { ...rsJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...rsJSON, ...enOHIPJSON };
      default:
        return rsJSON;
    }
  }, [pms]);

  const trFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return trJSON;
      case PmsType.MEWS:
        return { ...trJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...trJSON, ...enOHIPJSON };
      default:
        return trJSON;
    }
  }, [pms]);

  const uaFile = useMemo(() => {
    switch (pms) {
      case PmsType.APALEO:
        return uaJSON;
      case PmsType.MEWS:
        return { ...uaJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...uaJSON, ...enOHIPJSON };
      default:
        return uaJSON;
    }
  }, [pms]);

  useEffect(() => {
    initI18n({
      fallbackLanguage: "en",
      englishFile: { ...enFile, ...enErrorsJSON },
      germanFile: { ...deFile, ...deErrorsJSON },
      spanishFile: { ...esFile, ...enErrorsJSON },
      italianFile: { ...itFile, ...enErrorsJSON },
      portugeseFile: { ...ptFile, ...enErrorsJSON },
      serbianFile: { ...rsFile, ...enErrorsJSON },
      turkishFile: { ...trFile, ...enErrorsJSON },
      ukranianFile: { ...uaFile, ...enErrorsJSON }
    }).then(() => setInitialized(true));
  }, [enFile, deFile, esFile, itFile, ptFile, rsFile, trFile, uaFile]);

  if (!initialized) {
    return null;
  }

  return <>{children}</>;
};
