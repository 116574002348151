export enum RecurringGuestEnum {
  NEW = "New",
  RECURRING = "Recurring"
}

export enum BalanceStatusEnum {
  ZERO = "Zero",
  POSITIVE = "Positive",
  NEGATIVE = "Negative"
}

export enum GuestFlowStatusEnum {
  COMPLETED = "Completed",
  STARTED = "Started",
  NOT_STARTED = "NotStarted"
}

export enum PaymentTypeEnum {
  CC = "CC",
  VCC = "VCC",
  NONE = "NONE",
  CASH = "CASH",
  OTHERS = "OTHERS",
  INVOICE = "INVOICE"
}

export enum ChannelCodeEnum {
  BOOKING = "Booking",
  EXPEDIA = "Expedia",
  AIRBNB = "Airbnb",
  DIRECT = "Direct",
  IBE = "Ibe",
  OTHER = "Other"
}

export enum GuaranteeType {
  PREPAYMENT = "Prepayment",
  CREDIT_CARD = "CreditCard",
  COMPANY = "Company"
}

/*Additional filters */

export enum AdditionalFiltersEnum {
  RESERVATION_CONDITION = "reservationCondition",
  RECURRING_GUEST = "recurringGuest",
  BALANCE_STATUS = "balanceStatus",
  RESERVATION_STATUS = "reservationStatus",
  FLOW_STATE = "flowStatus",
  CHANNEL_CODE = "channelCode",
  PAYMENT_TYPE = "paymentType",
  GUARANTEE_TYPE = "guaranteeType"
}

export enum ManualCheckinStepsEnum {
  REGISTRATION_CARD = "REGISTRATION_CARD",
  SERVICES = "SERVICES",
  RATE_BREAKDOWN = "RATE_BREAKDOWN",
  PAYMENT = "PAYMENT",
  CONFIRMATION = "CONFIRMATION"
}

export enum RefundStatusEnum {
  INITIATED = "INITIATED",
  REFUNDED = "REFUNDED",
  FAILED = "FAILED"
}
