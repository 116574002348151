import { Button, EmptyState, Paragraph } from "@likemagic-tech/sv-magic-library";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FC, useMemo } from "react";
import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import { RowsForTableGuestOverview } from "./reservation-details-tabs/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import {
  labelsList,
  ReservationTabs
} from "./reservation-details-tabs/reservation-details-constants";
import { CardHeader } from "./reservation-details-tabs/card-header/card-header";
import { Notes } from "src/domain/reservation-table-dto";
import { CardComments } from "../../components/card-comments/card-comments";
import { TaskModal } from "src/features/tasks/task-modal/task-modal";
import { CardTasksInfo } from "./reservation-details-tabs/card-tasks/card-tasks-info";
import { useNavigateWithPropertyId } from "src/hooks/use-navigate-with-propertyId";
import { generateTaskRelatedToQueryParams } from "src/features/tasks/task-query-params";
import AddIcon from "@mui/icons-material/Add";
import { useTaskModal } from "src/features/tasks/use-task-select-id";
import { Task } from "../../../tasks/domain/task";
import { Services } from "./reservation-details-tabs/services/services";

export const numberOfComments = (notes: Notes) => {
  return (
    (notes.comment?.length > 0 ? 1 : 0) +
    (notes.bookingComment?.length > 0 ? 1 : 0) +
    (notes.guestComment?.length > 0 ? 1 : 0) +
    (notes.bookerComment?.length > 0 ? 1 : 0) +
    (notes.rateBreakdownComment && notes.rateBreakdownComment?.length > 0 ? 1 : 0) +
    (notes.beddingComment && notes.beddingComment?.length > 0 ? 1 : 0)
  );
};

interface ReservationDetailsOverviewProps {
  reservation: ReservationDetailsDTO;
  tasks: Task[];
  changeTabMoreInfo: (tab: string) => void;
}

export const ReservationDetailsOverview: FC<ReservationDetailsOverviewProps> = ({
  reservation,
  changeTabMoreInfo,
  tasks
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const navigate = useNavigateWithPropertyId();
  const { openNewTaskWithParam } = useTaskModal({});

  const TaskCreationButton = useMemo(() => {
    return (
      <Button
        onClick={() => openNewTaskWithParam(reservation.id, "RESERVATION")}
        variant="secondary"
        startIcon={<AddIcon sx={{ paddingRight: 1 }} fontSize="medium" />}
      >
        {t("buttons__create_task")}
      </Button>
    );
  }, [openNewTaskWithParam, reservation.id, t]);

  return (
    <Grid container rowSpacing={1} spacing={1} marginTop={theme.spacing(2)}>
      <Grid item xs={12}>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <CardHeader
            title={t("labels__reservation__details__overview__guests")}
            viewAllInfo={() => changeTabMoreInfo(ReservationTabs.INSIGHTS)}
          />
          <Grid p={3} paddingTop={0} container>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Paragraph>{t("labels__fullName")}</Paragraph>
                    </TableCell>
                    {labelsList.map((item) => (
                      <TableCell align="left" key={item}>
                        <Paragraph>{t(item)}</Paragraph>
                      </TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RowsForTableGuestOverview reservation={reservation} />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ borderRadius: 2, height: "100%" }} elevation={0}>
          <CardHeader
            title={
              numberOfComments(reservation.notes) > 0
                ? `${t("labels__reservation__details__overview__comments")} (${numberOfComments(
                    reservation.notes
                  )})`
                : t("labels__reservation__details__overview__comments")
            }
            viewAllInfo={() => changeTabMoreInfo(ReservationTabs.COMMENTS)}
          />
          <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
            <CardComments
              notes={reservation?.notes}
              numberOfComments={numberOfComments(reservation.notes)}
            />
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ borderRadius: 2, height: "100%" }} elevation={0}>
          <CardHeader
            title={
              reservation.services.length > 0
                ? `${t("labels__reservation__details__overview__services")} (${
                    reservation.services.length
                  })`
                : t("labels__reservation__details__overview__services")
            }
            viewAllInfo={() => changeTabMoreInfo(ReservationTabs.SERVICES)}
          />
          <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
            {reservation.services.length > 0 ? (
              <Services services={reservation.services} />
            ) : (
              <EmptyState title={t("labels__no__services__in__reservation")} />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <CardHeader
            title={
              tasks.length > 0
                ? `${t("labels__reservation__details__overview__tasks")} (${tasks.length})`
                : t("labels__reservation__details__overview__tasks")
            }
            viewAllInfo={() =>
              navigate("/tasks", generateTaskRelatedToQueryParams(reservation?.displayId ?? ""))
            }
            extraAction={TaskCreationButton}
          />
          <Grid container rowSpacing={1} justifyContent="center" padding={3} paddingTop={0}>
            <CardTasksInfo tasks={tasks.slice(0, 3)} />
          </Grid>
        </Paper>
      </Grid>
      <TaskModal />
    </Grid>
  );
};
