import React, { FC, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  MaintenanceInformationProps,
  MaintenanceModal
} from "../maintenance-modal/maintenance-modal";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { MaintenanceSlotType } from "src/domain/maintenance-slot-type";
import { Notification } from "../notification";
import { formatDate, formatTime } from "src/utils/timezoned-date";
import { useUpdateMaintenanceSlot } from "src/hooks/use-maintenance";
import { MaintenanceSlotArgs } from "src/store/endpoints/unit.endpoints";
import { useProperty } from "src/hooks/use-property";
import { useFetchUnitsToBeCleaned } from "src/hooks/use-fetch-units-to-be-cleaned";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import { grey } from "@mui/material/colors";

interface MaintenanceSlotInfoProps {
  maintenance: MaintenanceInformationProps;
}

export const MaintenanceSlotInfo: FC<React.PropsWithChildren<MaintenanceSlotInfoProps>> = ({
  maintenance
}) => {
  const { description, id, from, to, type, unitIds } = maintenance;
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const { t } = useTranslationWrapper();
  const [updateMaintenance, updateResult] = useUpdateMaintenanceSlot();
  const { selectedProperty } = useProperty();
  const { fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? "",
    skipInitialLoad: true
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      updateResult.reset();
      fetchAction();
      setMaintenanceModalOpen(false);
    }
  }, [dispatch, t, updateResult, fetchAction]);

  const calculateMaintenanceInfo = () => {
    if (to && type) {
      const localizedDate = formatDate(to);
      const localizedTime = formatTime(to);
      return `${t("labels__" + type + "_until")} ${localizedDate} | ${localizedTime}`;
    }
    return "";
  };

  const openMaintenanceModal = () => {
    setMaintenanceModalOpen(true);
  };

  const getColor = useMemo(() => {
    switch (type) {
      case MaintenanceSlotType.OUT_OF_SERVICE:
        return "warning";
      case MaintenanceSlotType.OUT_OF_ORDER:
        return "error";
      case MaintenanceSlotType.OUT_OF_INVENTORY:
        return "info";
      default:
        return "info";
    }
  }, [type]);

  return (
    <>
      <Grid pl={1.5} mt={2} container alignItems="center" spacing={2}>
        <Grid
          sx={{ padding: "0 !important", display: "inline-flex" }}
          gap={3}
          container
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap={"nowrap"}
        >
          <Grid item sx={{ width: "100%" }}>
            <Notification
              Icon={HeartBrokenIcon}
              showIcon={false}
              type={getColor}
              title={calculateMaintenanceInfo()}
              subtitle={description}
            />
          </Grid>
          <Grid item>
            <IconButton onClick={openMaintenanceModal} sx={{ padding: 0, color: grey[600] }}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {!!maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) => updateMaintenance(values)}
          maintenance={{ description, id, from, to, type, unitIds }}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </>
  );
};
