import { FC } from "react";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { LocalizationDatePicker } from "src/components/language-selector/localization-date-picker";
import { Select } from "@likemagic-tech/sv-magic-library";

export interface DateRangeFilterValue {
  id: string;
  type?: DateRangeFilterType | "";
  from?: Date;
  to?: Date;
}

interface DateRangeFilterProps {
  value: DateRangeFilterValue;
  onChange: (props: DateRangeFilterValue, oldType: DateRangeFilterType | "") => void;
  alreadySelectedTypes: Array<DateRangeFilterType>;
}

//Order is important
export enum DateRangeFilterType {
  "ARRIVAL" = "arrival",
  "DEPARTURE" = "departure",
  "CREATED" = "createdAt",
  "STAY" = "stay"
}
const isValidDate = (date: Date | null) => date && !!date.getDate();

export const DateRangeFilter: FC<React.PropsWithChildren<DateRangeFilterProps>> = ({
  value,
  onChange,
  alreadySelectedTypes
}) => {
  const { t } = useTranslationWrapper();
  const onChangeDatePicker = (field: string) => (date: Date | null) => {
    if (isValidDate(date)) {
      onChange({ ...value, [field]: date }, value.type ?? "");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={12} xs={12}>
        <Select
          value={value.type}
          variant={"outlined"}
          label={t("labels__type")}
          onChange={(e) => {
            onChange(
              {
                ...value,
                type: e.target.value as DateRangeFilterType
              },
              value.type ?? ""
            );
          }}
          options={Object.values(DateRangeFilterType).map((type) => ({
            label: t(`labels__filter_title_${type}`),
            value: type,
            disabled: alreadySelectedTypes.indexOf(type) > -1
          }))}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <LocalizationDatePicker>
          <DatePicker
            label={t("labels__from")}
            maxDate={value.to}
            disabled={!value.type}
            showToolbar={false}
            value={value.from || null}
            onChange={onChangeDatePicker("from")}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationDatePicker>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <LocalizationDatePicker>
          <DatePicker
            minDate={value.from}
            disabled={!value.type}
            label={t("labels__to")}
            showToolbar={false}
            value={value.to || null}
            onChange={onChangeDatePicker("to")}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationDatePicker>
      </Grid>
    </Grid>
  );
};
