import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, DialogContent, Divider, Grid } from "@mui/material";
import { PaymentSelection } from "../../../payment/payment-selection";
import { Button, Heading4 } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { PaymentOption } from "../../../payment/domain/payment-option";
import { PaymentComponent } from "../../../payment/payment-component";
import { useDispatch, useSelector } from "../../../../../store";
import { selectPaymentDTO } from "../../../../../slices/payment.slice";
import { ManualWizardStepItem } from "../../../components/manual-wizard/manual-wizard-step-item";
import { useManualCheckinSteps } from "../../../../../hooks/use-manual-checkin-steps";
import { formatPriceToString } from "../../../../../utils/price";
import { ManualWizardStepItemNavigation } from "../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { useProperty } from "../../../../../hooks/use-property";
import { usePaymentMethodOptions } from "../../../payment/payment-component-handlers/use-payment-method-options";
import { DialogModal } from "../../../../../components/submit-modal/dialog";
import { DialogTitleModal } from "../../../../../components/submit-modal/dialog-title";
import { TotalPricePreview } from "../../../payment/total-price-preview";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { openBanner } from "../../../../../slices/banner.slice";

export const ManualCheckinStepPayment: FC<ManualCheckinStepProps> = ({ reservationDetails }) => {
  const { t } = useTranslationWrapper();
  const [openModal, setOpenModal] = useState(false);
  const { nextStep, previousStep } = useManualCheckinSteps();

  const { price: paymentPrice } = useSelector(selectPaymentDTO);
  const { selectedProperty } = useProperty();
  const dispatch = useDispatch();

  const { options, getPaymentOptionDetails, defaultSelectedHashId } = usePaymentMethodOptions({
    propertyId: selectedProperty?.propertyId,
    magicId: reservationDetails?.magicId
  });

  const [paymentOptionHashCode, setPaymentOptionHashCode] = useState<string | undefined>();

  //defaultSelectedHashId is calculated when we get data from GetPaymetnMethods
  useEffect(() => {
    if (defaultSelectedHashId) {
      setPaymentOptionHashCode(defaultSelectedHashId);
    }
  }, [defaultSelectedHashId]);

  const onSuccess = useCallback(() => {
    nextStep();
    setOpenModal(false);
    dispatch(
      openBanner({
        type: "success",
        title: t("labels__payment_successfully_performed")
      })
    );
  }, [nextStep, dispatch, t]);

  const onFailed = useCallback(() => {
    setPaymentOptionHashCode(undefined);
    setOpenModal(false);
  }, []);

  const handleNextStep = () => {
    setOpenModal(true);
  };

  const clearSelection = () => {
    setOpenModal(false);
  };
  const paymentOption = getPaymentOptionDetails(paymentOptionHashCode);

  if (!paymentPrice) {
    console.warn("This should not happen!");
    return <>{t("errors_default")}</>;
  }

  return (
    <>
      <Heading4 m={3}>{t("subtitle__payment")}</Heading4>
      <ManualWizardStepItem>
        <Box width="100%">
          <PaymentSelection
            options={
              options.filter(
                (item) => item?.type && ![PaymentOption.PreAuthorization].includes(item.type)
              ) ?? []
            }
            value={paymentOptionHashCode ?? defaultSelectedHashId}
            setValue={setPaymentOptionHashCode}
          />

          {paymentOptionHashCode &&
            openModal &&
            paymentOption?.type &&
            reservationDetails?.magicId && (
              <DialogModal isOpen={true} handleClose={clearSelection}>
                <Box p={3} width="100%">
                  <DialogTitleModal
                    title={t(`modals__title_${paymentOption.type}`)}
                    onClose={clearSelection}
                  />
                  <DialogContent sx={{ padding: 0, pt: 1 }}>
                    <PaymentComponent
                      magicId={reservationDetails?.magicId}
                      paymentOption={paymentOption}
                      onSuccess={onSuccess}
                      onFailed={onFailed}
                      price={paymentPrice}
                      propertyId={selectedProperty?.propertyId}
                      isPreAuthorisation={false}
                    />
                  </DialogContent>
                </Box>
              </DialogModal>
            )}
          <Divider sx={{ my: 2 }} />

          <TotalPricePreview price={paymentPrice} label={t("labels__total_amount")} />
        </Box>
      </ManualWizardStepItem>
      <ManualWizardStepItemNavigation
        onNextStep={handleNextStep}
        onPreviousStep={previousStep}
        nextLabel={t("buttons__charge", { price: formatPriceToString(paymentPrice) })}
        disableNext={!options}
      >
        <Grid item minWidth={140}>
          <Button variant="secondary" fullWidth onClick={nextStep}>
            {t("buttons__skip_payment")}
          </Button>
        </Grid>
      </ManualWizardStepItemNavigation>
    </>
  );
};
